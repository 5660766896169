import { RESET, Action } from '@hubblai/hubbl-ui/store/types.js';
import * as types from './types';

export type OrganizationsState = {
  currentOrganizationId?: string,
}

const INITIAL_STATE: OrganizationsState = {
  currentOrganizationId: undefined
}

const reducer = (state = { ...INITIAL_STATE }, { type, payload }: Action) => {
  switch (type) {
    case types.SET_CURRENT_ORGANIZATION: {
      return {
        ...state,
        currentOrganizationId: payload.id,
      }
    }

    case RESET:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};


export default reducer;
