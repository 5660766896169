import { Button, Spinner } from "@hubblai/hubbl-ui/components/index.js";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useApps, useIsFetchingAll } from "~/store/apps/hooks";

const AppsPage: React.FC = () => {
  const navigate = useNavigate();
  const apps = useApps();
  const isFetching = useIsFetchingAll();

  const onClickCreateNewApp = async () => {
    navigate('/apps/new');
  }

  useEffect(() => {
    if (apps.length > 0) {
      navigate(`/apps/${apps[0].id}`);
    }
  }, [apps, navigate])

  return (
    <div className="flex flex-1 justify-center items-center">
      {isFetching && <Spinner />}
      {!isFetching && apps.length === 0 &&
        <div>
          <div>You have no apps. Sucks.</div>
          <Button title='Create new App' icon='add-app' onClick={onClickCreateNewApp} />
        </div>
      }
    </div>
  )
}

export default AppsPage;
