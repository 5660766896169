import React from 'react';
import createStore from '@hubblai/hubbl-ui/store/index.js';
import { mount } from '@hubblai/hubbl-ui/main.jsx';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import * as Sentry from "@sentry/react";

import '@hubblai/hubbl-ui/styles/global.css';

import './index.css';

import "primereact/resources/themes/soho-light/theme.css";
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import AuthRoutes from './routes';
import reducers from '~/store/reducers';

import config from './config';

const store = createStore(reducers);

if (process.env.NODE_ENV !== 'local') {
  Sentry.init({
    dsn: config.SENTRY.DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", /^https:\/\/api.gethubbl.ai/, /^https:\/\/api.gethubbl.xyz/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

mount({
  store,
  authRoutes: AuthRoutes,
  primeConfig: {
    ripple: true,
  }
});
