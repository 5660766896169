import { Model, ModelFactory } from "@hubblai/hubbl-ui/store/models.js";

export const clone = <T>(type: Model<T>, o: any) => {
  const c = structuredClone(o);
  return ModelFactory.create<T>(type, c);
}

export const toMap = (data: any, propertyName: string = 'id') => {
  return data.reduce((acc: any, item: any) => {
    acc[item[propertyName]] = item;
    return acc;
  }, {})
}
