import { Modal, ButtonItem, Button, Message } from '@hubblai/hubbl-ui/components/index.js';
import AdvancedInput, { Language, LanguageValue } from '../AdvancedInput';
import { isString } from '@tiptap/react';
import { useRef, useState } from 'react';
import API, { getDisplayError } from '@hubblai/hubbl-ui/lib/api.js';
import config from '~/config';
import { AdvancedInputItemRef } from '../types';

type Props = {
  isOpened: boolean,
  onPromptSelected: (prompt: string) => void,
  onClose: () => void,
}

const DEFAULT_INPUT = `
Tips (remove before clicking Generate):
--------------------------------

[Describe the context or application for the prompt. This includes the general topic or domain (e.g., customer service, education, creative writing).]
[Specify goal of the prompt. What do you want to achieve with this prompt? (e.g., gather feedback, generate ideas, provide explanations).]
[Who the intended audience is for the prompt. This includes details such as age group, professional background, or any other relevant demographic information.]
[Identify any specific constraints or requirements. This might include word limits, tone (formal/informal), specific keywords or phrases to include, or any particular formatting needs.]
`;

const GeneratePromptModal: React.FC<Props> = ({ isOpened, onClose, onPromptSelected }) => {
  const advancedInputRef = useRef<AdvancedInputItemRef>(null);
  const [input, setInput] = useState(DEFAULT_INPUT);
  const [output, setOutput] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);

  const onClickSelect = () => {
    if (output.length > 0) {
      onPromptSelected(output);
    }
    onClose();
  }

  const onClickGenerate = async () => {
    if (isGenerating) {
      return;
    }
    setErrorMessage('');
    setIsGenerating(true);
    try {
      const result = await API.quickinvoke(config.AGENTS.PROMPT_GENERATE_AGENT_ID, input);
      console.log(result);
      let content = '';
      if (result?.reply?.user?.content) {
        content = result?.reply?.user?.content;
      } else {
        content = `Error generating response: ${JSON.stringify(result)}`;
      }

      advancedInputRef.current?.forceContent(content);
      setOutput(content);
    }
    catch (err) {
      setErrorMessage(getDisplayError(err));
    }
    setIsGenerating(false);
  }

  const buttons: ButtonItem[] = [{
    title: "Close",
    action: 'close',
    variant: 'info',
    position: 'left',
    outline: true,
  }, {
    isDisabled: output.length === 0,
    title: "Select Prompt",
    variant: 'success',
    onClick: onClickSelect,
  }];

  const onChangeInput = (language: Language, value: LanguageValue) => {
    if (language === 'markdown' && isString(value)) {
      setInput(value);
    }
  }

  return (
    <Modal buttons={buttons} title={`Generate Prompt`} isOpened={isOpened} size='almost-max' usesNavigation={false} onClose={onClose}>
      <AdvancedInput
        label='Prompt Information'
        values={{
          'markdown': input,
        }}
        onChange={onChangeInput}
      />
      <div className='text-center'>
        <Button isLoading={isGenerating} title="Generate Prompt" icon="generate" className='my-3' onClick={onClickGenerate} />
      </div>
      {errorMessage && <Message className='mb-3 w-full' severity="error" text={errorMessage} />}
      {output.length > 0 &&
        <AdvancedInput
          label='Prompt Suggestion'
          ref={advancedInputRef}
          values={{
            'markdown': output,
          }}
          isReadOnly
        />
      }
    </Modal>
  )
}

export default GeneratePromptModal;
