
import { ComponentProps } from '@hubblai/hubbl-ui/components/types.js';
import React from 'react';
import PanelHeader from '../Components/PanelHeader';
import { DropdownMenuItem, confirm } from '@hubblai/hubbl-ui/components/index.js';
import { useAppDispatch } from '@hubblai/hubbl-ui/store/index.js';
import { useCurrentOrganizationId } from '~/store/organizations/hooks';
import { addToast } from '@hubblai/hubbl-ui/store/notifications/actions.js';
import { getDisplayError } from '@hubblai/hubbl-ui/lib/api.js';
import { useFunction } from '~/store/functions/hooks';
import PanelBody from '../Components/PanelBody';
import { deleteFunction } from '~/store/functions/actions';
import { useNavigate } from 'react-router-dom';
import FunctionForm from '~/components/FunctionForm';

type Props = {
  id: string,
} & ComponentProps;

const FunctionEditPanel: React.FC<Props> = ({ id }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const organizationId = useCurrentOrganizationId();
  const fn = useFunction(id);

  const onClickDeleteConfirmed = async () => {
    try {
      await dispatch(deleteFunction(organizationId!, id));
      navigate('/functions');
    }
    catch (err) {
      dispatch(addToast('Oops', `Error removing function: ${getDisplayError(err)}`, 'danger'));
    }
  };

  const buttons: DropdownMenuItem[] = [{
    label: 'Delete',
    onClick: () => {
      confirm({
        message: <div>Are you sure you want to delete this function? </div>,
        header: 'Delete Function',
        icon: 'pi pi-exclamation-triangle',
        accept: onClickDeleteConfirmed,
      })
    },
  }];

  const onFnCreated = () => {}
  const onFnDeleted = () => {}

  return (
    <div className="flex flex-col flex-1 h-full w-full">
      {fn && <PanelHeader buttons={buttons} title={fn?.name} />}
      {fn && organizationId &&
        <PanelBody>
          <FunctionForm organizationId={organizationId} fn={fn} onCreated={onFnCreated} onDeleted={onFnDeleted} contentClassName="bg-white p-0" />
        </PanelBody>
      }
    </div>
  )
}

export default FunctionEditPanel;
