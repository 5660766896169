import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { AppsState } from './reducer';
import { App } from '../models';
import { AppToCanvasNode, CanvasNode } from '~/lib/canvas';
import { deepEqual } from '@hubblai/hubbl-core/lib/object.js';
import { EntityMap } from '../types';
import { store } from '@hubblai/hubbl-ui/store/index.js';

type AppsStoreType = {
  apps: AppsState,
}

const selectAppIds = () => (state: AppsStoreType): string[] => state.apps.appIds;
const selectAppMap = () => (state: AppsStoreType): EntityMap<App> => state.apps.appMap;
const selectApp = (appId: string) => (state: AppsStoreType): App | undefined => state.apps.appMap[appId];

const appAsSelector = (appId: string) => createSelector(selectAppMap(), appMap => appMap[appId]);
const appAsNodeSelector = (appId: string, organizationId: string) => createSelector(
  selectApp(appId),
  (app?: App) => app ? AppToCanvasNode(store, app, organizationId, { showAddNode: true, viewType: 'edit' }) : undefined,
);
const appsAsSelector = () => createSelector(
  [selectAppIds(), selectAppMap()],
  (aIds, appMap) => aIds.map(id => appMap[id])
);

export const useIsFetchingAll = () => useSelector<AppsStoreType, boolean>(state => state.apps.isFetchingAll);
export const useIsFetchingOne = () => useSelector<AppsStoreType, boolean>(state => state.apps.isFetchingOne);
export const useIsUpdating = () => useSelector<AppsStoreType, boolean>(state => state.apps.isUpdating);
export const useApps = () => useSelector<AppsStoreType, App[]>(appsAsSelector());

export const useApp = (appId: string) => useSelector<AppsStoreType, App | undefined>(appAsSelector(appId));

export const useAppAsNode = (appId: string, organizationId: string) => useSelector<AppsStoreType, CanvasNode | undefined>(
  appAsNodeSelector(appId, organizationId),
  deepEqual
);
