import { Outlet, useNavigate, useParams } from "react-router-dom"
import styles from './layout.module.css';
import { useAgents, useIsFetchingAll } from "~/store/agents/hooks";
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { Button } from "@hubblai/hubbl-ui/components/index.js";
import AgentListItem from "~/components/AgentListItem";
import Search from "~/components/Search";
import { useEffect } from "react";
import { useCurrentOrganizationId } from "~/store/organizations/hooks";

const SIDE_PANEL_SIZE = 30;


const AgentsLayout: React.FC = () => {
  const navigate = useNavigate();
  const agents = useAgents();
  const { agentId } = useParams();
  const isFetching = useIsFetchingAll();
  const organizationId = useCurrentOrganizationId();

  useEffect(() => {
    if (organizationId) {
      navigate('/agents' + (agentId ? `/${agentId}` : ''));
    }
  }, [navigate, organizationId, agentId]);

  const onClickNewAgent = () => {
    navigate('/agents/new');
  }

  const onSearchChanged = () => {
    // TODO:P1 actually implement
  }

  return (
    <Splitter className="flex flex-1 overflow-hidden">
      <SplitterPanel size={SIDE_PANEL_SIZE} minSize={10} className="flex flex-col">
        <div className="flex flex-row border-b-2 p-2">
          <Search placeholder="Search agents..." className="flex-1" onChange={onSearchChanged} />
          <Button icon='plus' onClick={onClickNewAgent} className="ml-1" />
        </div>
        {!isFetching && agents.length > 0 && (
          <div className={styles.list}>
            {agents.map(agent => (
              <AgentListItem key={agent.id} agent={agent} isActive={agentId === agent.id} />
            ))}
          </div>
        )}
      </SplitterPanel>
      <SplitterPanel size={100 - SIDE_PANEL_SIZE}>
        <Outlet />
      </SplitterPanel>
    </Splitter>
  )
}

export default AgentsLayout;
