import { getDisplayFunctionStatus } from "@hubblai/hubbl-core/models/Function.js";
import { Icon, Tag } from "@hubblai/hubbl-ui/components/index.js";
import clsx from "clsx";
import { Link } from "react-router-dom";
import styles from './FunctionListItem.module.css';
import { FunctionModel } from "~/store/models";

type Props = {
  fn: FunctionModel,
  isActive?: boolean,
  onClick?: () => void,
  showStatus?: boolean,
  showOrganization?: boolean,
}

const FunctionListItem: React.FC<Props> = ({ fn, isActive, onClick, showStatus, showOrganization }) => {
  return (
    <Link to={onClick ? '' : `/functions/${fn.id}`} onClick={onClick} className={clsx(styles.item, { [styles.active]: isActive })}>
      <div className={styles.title}>
        <div>
          <Icon name='function' />
          <span className="mx-2">{fn.name}</span>
        </div>
        {showStatus && <Tag title={getDisplayFunctionStatus(fn.status)} />}
      </div>
      {fn.organization && showOrganization && <span className="opacity-50 text-sm font-normal mb-1">By {fn.organization.display_name}</span>}
      <div className={styles.subtitle}>{fn.description}</div>
    </Link>
  )
}

export default FunctionListItem;
