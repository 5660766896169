import { BaseModel, ModelFactory } from "@hubblai/hubbl-ui/store/models.js";
import { BaseFunction, FUNCTION_STATUS } from "@hubblai/hubbl-core/models/Function.js";
import { ArgumentValues } from "@hubblai/hubbl-core/lib/code.js";
import { ParameterGroups } from "@hubblai/hubbl-core/lib/parameters.js";
import { clone } from "~/lib/object";
import { BaseOrganization } from "@hubblai/hubbl-core/models/Organization.js";
import { BaseAgent } from "@hubblai/hubbl-core/models/Agent.js";
import { BaseAIPrompt } from "@hubblai/hubbl-core/models/Model.js";
import { BaseApp } from "@hubblai/hubbl-core/models/App.js";

export class BaseAIModel {
  id: number = -1;
  name: string = '';
  display_name: string = '';
  icon?: string;
  description: string = '';
  config_parameters: ParameterGroups = [];
  provider: string = '';
  costs: {
    input: number,
    output: number
  } = {
    input: 0,
    output: 0,
  };
  capabilities: {
    has_tools?: boolean,
    has_memory?: boolean,
    can_custom_model?: boolean,
  } = {};
}

export class AIModel extends BaseAIModel {

  static fromDTO(data: any): AIModel {
    return ModelFactory.create<AIModel>(AIModel, {
      id: data.id,
      name: data.name,
      display_name: data.display_name,
      description: data.description,
      icon: data.icon,
      config_parameters: data.config_parameters,
      provider: data.provider,
      costs: {
        input: data.costs.input,
        output: data.costs.output,
      },
      capabilities: {
        has_tools: data.capabilities.has_tools,
        has_memory: data.capabilities.has_memory,
        can_custom_model: data.capabilities.can_custom_model,
      }
    })
  }
  static fromDTOs(data: any): AIModel[] {
    return data.map((item: any) => AIModel.fromDTO(item));
  }
}

export class AIPrompt extends BaseAIPrompt {
  static fromDTO(data: any): AIPrompt {
    return ModelFactory.create<AIPrompt>(AIPrompt, {
      id: data.id,
      title: data.title,
      prompt: data.prompt,
      description: data.description,
    })
  }
  static fromDTOs(data: any): AIPrompt[] {
    return data.map((item: any) => AIPrompt.fromDTO(item));
  }
}

export class App extends BaseApp {

  agents?: Agent[] | undefined;
  organization?: Organization | undefined;
  view_level: APP_VIEW_LEVEL = APP_VIEW_LEVEL.PRIVATE;
  settings: any;

  static fromDTO(data: any): App {
    return ModelFactory.create<App>(App, {
      id: data.id,
      display_name: data.display_name,
      icon: data.icon,
      description: data.description,
      view_level: data.view_level,
      organization_id: data.organization_id,
      organization: data.organization ? Organization.fromDTO(data.organization) : undefined,
      agents: data.agents ? Agent.fromDTOs(data.agents) : undefined,
      settings: data.settings,
    });
  }
  static fromDTOs(data: any): App {
    return data.map((item: any) => App.fromDTO(item));
  }

  clone() {
    const app = clone<App>(App, this);
    if (this.agents) {
      app.agents = this.agents.map(agent => agent.clone());
    }
    if (this.organization) {
      app.organization = this.organization.clone();
    }
    return app;
  }

}

export class Agent extends BaseAgent {

  kits: Kit[] | undefined;
  organization?: Organization;

  static fromDTO(data: any): Agent {
    return ModelFactory.create<Agent>(Agent, {
      id: data.id,
      model_id: data.model_id,
      display_name: data.display_name,
      icon: data.icon,
      description: data.description,
      created_at: data.created_at,
      created_by: data.created_by,
      kits: data.kits ? Kit.fromDTOs(data.kits) : [],
      organization: data.organization ? Organization.fromDTO(data.organization) : undefined,
      organization_id: data.organization_id,

      // ManagedDTO
      view_level: data.view_level,
      prompt: data.prompt,
      instructions: data.instructions,
      parameters: data.parameters,
      updated_at: data.updated_at,
      model: data.model ? AIModel.fromDTO(data.model) : undefined,
    });
  }
  static fromDTOs(data: any) {
    return data.map((item: any) => Agent.fromDTO(item));
  }

  clone() {
    const agent = clone<Agent>(Agent, this);
    if (this.kits) {
      agent.kits = this.kits.map(kit => kit.clone());
    }
    if (this.organization) {
      agent.organization = this.organization.clone();
    }
    return agent;
  }
}

export class Organization extends BaseOrganization {
  static fromDTO(data: any): Organization {
    return ModelFactory.create<Organization>(Organization, {
      id: data.id,
      display_name: data.display_name,
    });
  }

  clone() {
    return clone<Organization>(Organization, this);
  }
}

export class Code {
  content: string = '';
  dependencies: any = {};

  static fromDTO(data: any): Code {
    return ModelFactory.create<Code>(Code, {
      content: data.content,
      dependencies: data.dependencies
    });
  }

  clone() {
    return clone<Code>(Code, this);
  }
}

export class FunctionModel extends BaseFunction {
  status: FUNCTION_STATUS = FUNCTION_STATUS.DRAFT;
  code: Code | undefined;
  description_code: Code | undefined;
  previous_function_id: string = '';
  next_function_id: string = '';
  organization: Organization | undefined;

  isLatest() {
    return this.status === FUNCTION_STATUS.LATEST;
  }

  isEditable() {
    return this.status === FUNCTION_STATUS.DRAFT || this.status === FUNCTION_STATUS.LATEST;
  }

  isReadOnly() {
    return !this.isEditable();
  }

  static fromDTO(data: any): FunctionModel {
    return ModelFactory.create<FunctionModel>(FunctionModel, {
      id: data.id,
      name: data.name,
      description: data.description,
      config_args: data.config_args,
      status: data.status,
      previous_function_id: data.previous_function_id,
      next_function_id: data.next_function_id,
      code: data.code ? Code.fromDTO(data.code) : undefined,
      description_code: data.description_code ? Code.fromDTO(data.description_code) : undefined,
      organization_id: data.organization_id,
      organization: data.organization ? Organization.fromDTO(data.organization) : undefined,
      config_parameters: data.config_parameters,
    })
  }
  static fromDTOs(data: any): FunctionModel[] {
    return data.map((item: any) => FunctionModel.fromDTO(item));
  }

  clone() {
    const fn = clone<FunctionModel>(FunctionModel, this);
    if (this.code) {
      fn.code = this.code.clone();
    }
    if (this.description_code) {
      fn.description_code = this.description_code.clone();
    }
    if (this.organization) {
      fn.organization = this.organization.clone();
    }
    return fn;
  }
}

export enum APP_VIEW_LEVEL {
  PRIVATE = 1,
  PUBLIC = 2,
}

export enum KIT_VIEW_LEVEL {
  PRIVATE = 1,
  PUBLIC = 2,
}

export class Kit extends BaseModel {

  id: string = '';
  title: string = '';
  description: string = '';
  icon?: string;
  config_parameters: ParameterGroups = [];
  version: string = '';
  view_level: KIT_VIEW_LEVEL = KIT_VIEW_LEVEL.PRIVATE;
  functions?: FunctionModel[];
  instructions: string = '';
  instructions_code: Code | undefined;
  prompt: string = '';
  prompt_code: Code | undefined;
  organization_id: string = '';
  organization: Organization | undefined;
  parameters: ArgumentValues | undefined; // These are populates from AgentKit

  static fromDTO(data: any): Kit {
    return ModelFactory.create<Kit>(Kit, {
      id: data.id,
      title: data.title,
      organization_id: data.organization_id,
      organization: data.organization ? Organization.fromDTO(data) : undefined,
      description: data.description,
      icon: data.icon,
      config_parameters: data.config_parameters,
      parameters: data.parameters,
      version: data.version,
      view_level: data.view_level,
      functions: data.functions ? FunctionModel.fromDTOs(data.functions) : undefined,
      instructions: data.instructions || '',
      instructions_code: data.instructions_code ? Code.fromDTO(data.instructions_code) : undefined,
      prompt: data.prompt || '',
      prompt_code: data.prompt_code ? Code.fromDTO(data.prompt_code) : undefined,
    });
  }

  static fromDTOs(data: any): Kit[] {
    return data.map((item: any) => Kit.fromDTO(item));
  }

  clone() {
    const kit = clone<Kit>(Kit, this);
    if (this.functions) {
      kit.functions = this.functions.map(fn => fn.clone());
    }
    if (this.instructions_code) {
      kit.instructions_code = this.instructions_code.clone();
    }
    if (this.organization) {
      kit.organization = this.organization.clone();
    }
    return kit;
  }
}
