import { useSelector } from 'react-redux';
import { AgentsState } from './reducer';
import { Agent } from '../models';
import { createSelector } from 'reselect';
import { AgentToCanvasNode, CanvasNode } from '~/lib/canvas';
import { deepEqual } from '@hubblai/hubbl-core/lib/object.js';
import { EntityMap } from '../types';
import { store } from '@hubblai/hubbl-ui/store/index.js';

type AgentsStoreType = {
  agents: AgentsState,
}

const emptyArray: string[] = [];

const selectAgentIds = () => (state: AgentsStoreType): string[] => state.agents.agentIds || emptyArray;
const selectAgentMap = () => (state: AgentsStoreType): EntityMap<Agent> => state.agents.agentMap;
const selectAgent = (agentId: string) => (state: AgentsStoreType): Agent | undefined => state.agents.agentMap[agentId];

const agentAsSelector = (agentId: string) => createSelector(selectAgentMap(), agentMap => agentMap[agentId]);
const agentAsNodeSelector = (agentId: string, organizationId: string) => createSelector(
  selectAgent(agentId),
  (agent?: Agent)=> agent ? AgentToCanvasNode(store, agent, organizationId, { showAddNode: true, viewType: 'edit' }) : undefined,
);
const agentsAsSelector = () => createSelector(
  [selectAgentIds(), selectAgentMap()],
  (aIds, agentMap) => aIds.map(id => agentMap[id])
);

export const useIsFetchingAll = () => useSelector<AgentsStoreType, boolean>(state => state.agents.isFetchingAll);
export const useIsFetchingOne = () => useSelector<AgentsStoreType, boolean>(state => state.agents.isFetchingOne);
export const useIsUpdating = () => useSelector<AgentsStoreType, boolean>(state => state.agents.isUpdating);
export const useAgents = () => useSelector<AgentsStoreType, Agent[]>(agentsAsSelector());

export const useAgent = (agentId: string) => useSelector<AgentsStoreType, Agent | undefined>(agentAsSelector(agentId));
export const useAgentAsNode = (agentId: string, organizationId: string) => useSelector<AgentsStoreType, CanvasNode | undefined>(
  agentAsNodeSelector(agentId, organizationId),
  deepEqual,
);
