import { Button, Spinner } from "@hubblai/hubbl-ui/components/index.js";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAgents, useIsFetchingAll } from "~/store/agents/hooks";

const AgentsPage: React.FC = () => {
  const navigate = useNavigate();
  const agents = useAgents();
  const isFetching = useIsFetchingAll();

  const onClickCreateNewAgent = async () => {
    navigate('/agents/new');
  }

  useEffect(() => {
    if (agents.length > 0) {
      navigate(`/agents/${agents[0].id}`);
    }
  }, [agents, navigate])

  return (
    <div className="flex flex-1 justify-center items-center">
      {isFetching && <Spinner />}
      {!isFetching && agents.length === 0 &&
        <div>
          <div>You have no agents. Sucks.</div>
          <Button title='Create new Agent' icon='add-agent' onClick={onClickCreateNewAgent} />
        </div>
      }
    </div>
  )
}

export default AgentsPage;
