
import { Spinner } from '@hubblai/hubbl-ui/components/index.js';
import { ComponentProps } from '@hubblai/hubbl-ui/components/types.js';
import API, { getDisplayError } from '@hubblai/hubbl-ui/lib/api.js';
import { useAppDispatch } from '@hubblai/hubbl-ui/store/index.js';
import { addToast } from '@hubblai/hubbl-ui/store/notifications/actions.js';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FunctionListItem from '~/components/FunctionListItem/FunctionListItem';
import Search from '~/components/Search';
import { FunctionModel } from '~/store/models';
import { TabView, TabPanel } from 'primereact/tabview';
import { useFunctions } from '~/store/functions/hooks';
import { useCurrentOrganizationId } from '~/store/organizations/hooks';
import { useOrganization } from '@hubblai/hubbl-ui/store/auth/hooks.js';
// import { useIsUpdating } from '~/store/kits/hooks';
import { addFunction } from '~/store/kits/actions';
import PanelHeader from '../Components/PanelHeader';
import PanelBody from '../Components/PanelBody';

type Props = {
  kitId: string,
  selectNode: (nodeId?: string) => void,
} & ComponentProps;

const AddFunctionPanel: React.FC<Props> = ({ kitId, selectNode }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [functions, setFunctions] = useState<FunctionModel[]>([]);
  const orgFunctions = useFunctions();
  const organizationId = useCurrentOrganizationId();
  const organization = useOrganization(organizationId || '');
  // const _isUpdating = useIsUpdating();

  // TODO:P1 make search work again

  const fetchFunctions = useCallback(async () => {
    setIsFetching(true);
    try {
      const { data } = await API.get(`/functions`, { query });
      const functions = FunctionModel.fromDTOs(data);
      setFunctions(functions);
    }
    catch (err) {
      const errorMessage = getDisplayError(err);
      dispatch(addToast('Ooops', `Seems like there was a problem fetching functions: ${errorMessage}`));
    }
    setIsFetching(false);
  }, [query, dispatch]);

  useEffect(() => {
    fetchFunctions();
  }, [fetchFunctions]);

  const onClickFunction = async (fn: FunctionModel) => {
    try {
      await dispatch(addFunction(organizationId!, kitId, fn.id));
      // selectNode(getFunctionNodeId(fn.id));
      selectNode(undefined);
      dispatch(addToast('Done!', 'Function was added to the Kit', 'success'));
    }
    catch (err) {
      dispatch(addToast('Ooops', `Seems like there was a problem adding function: ${getDisplayError(err)}`));
    }
  }

  const onSearchChanged = (query: string) => setQuery(query);

  const matchingOrgFunctions = orgFunctions.filter(fn => {
    let result = fn.isLatest();
    if (query.length > 0) {
      result = result && fn.name.includes(query);
    }
    return result;
  });

  const buttons = [{
    label: 'Create New Function',
    onClick: () => navigate('/functions/new'),
  }]

  return (
    <div className="flex flex-col flex-1 h-full w-full">
      <PanelHeader title='Add Function' buttons={buttons}>
        <Search placeholder='Search for function...' onChange={onSearchChanged} />
      </PanelHeader>
      <PanelBody>
        <TabView panelContainerClassName='p-0'>
          <TabPanel header={organization?.display_name}>
            {matchingOrgFunctions.map(fn => <FunctionListItem key={`org-${fn.id}`} fn={fn} onClick={() => onClickFunction(fn)} showStatus={false} />)}
            {matchingOrgFunctions.length === 0 && query.length === 0 && <div>Your organization does not have any published functions.</div>}
            {matchingOrgFunctions.length === 0 && query.length > 0 && <div>No published functions were found matching "{query}".</div>}
          </TabPanel>
          <TabPanel header="Public">
            {functions.map(fn => <FunctionListItem key={fn.id} fn={fn} onClick={() => onClickFunction(fn)} showStatus={false} showOrganization />)}
            {isFetching && <Spinner />}
            {!isFetching && query.length > 0 && functions.length === 0 && <div className='flex-1 justify-center items-center'>No functions were found matching "{query}".</div>}
          </TabPanel>
        </TabView>

      </PanelBody>
    </div>
  )
}

export default AddFunctionPanel;
