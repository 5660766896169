import { DropdownMenu, DropdownMenuItem, Label } from '@hubblai/hubbl-ui/components/index.js';
import { ComponentProps } from '@hubblai/hubbl-ui/components/types.js';
import React from 'react';

type PanelHeaderProps = {
  title: string,
  buttons?: DropdownMenuItem[],
  icon?: string,
} & ComponentProps;

const PanelHeader: React.FC<PanelHeaderProps> = ({ title, buttons, children, icon }) => {
  return (
    <div>
      <div className='flex flex-row justify-between items-center mb-3'>
        <div className='flex flex-row flex-1 items-center'>
          {icon && <img src={icon} className='rounded-md w-16 mr-3' />}
          <Label title={title} size="lg" className='mb-0' />
        </div>

        {buttons && buttons.length > 0 && <DropdownMenu icon={'ellipsisV'} items={buttons} variant='link' outline />}
      </div>
      {children}
    </div>
  )
}

export default PanelHeader;
