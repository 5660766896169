import { Button, Spinner } from "@hubblai/hubbl-ui/components/index.js";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFunctions, useIsFetchingAll } from "~/store/functions/hooks";

const FunctionsPage: React.FC = () => {
  const navigate = useNavigate();
  const functions = useFunctions();
  const isFetching = useIsFetchingAll();

  const onClickCreateNewFunction = async () => {
    navigate('/functions/new');
  }

  useEffect(() => {
    if (functions.length > 0) {
      navigate(`/functions/${functions[0].id}`);
    }
  }, [functions, navigate])

  return (
    <div className="flex flex-1 justify-center items-center">
      {isFetching && <Spinner />}
      {!isFetching && functions.length === 0 &&
        <div>
          <div>You have no functions. Sucks.</div>
          <Button title='Create new Function' icon='add-function' onClick={onClickCreateNewFunction} />
        </div>
      }
    </div>
  )
}

export default FunctionsPage;
