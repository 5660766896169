import update from 'immutability-helper';
import { RESET, Action } from '@hubblai/hubbl-ui/store/types.js';
import * as types from './types';
import { AIModel, AIPrompt } from '../models';

export type ModelsState = {
  isFetchingAll: boolean,
  models: AIModel[],
  providers: string[],
  isFetchingPrompts: boolean,
  prompts: AIPrompt[],
  promptQuery?: string,
}

const INITIAL_STATE: ModelsState = {
  isFetchingAll: false,
  models: [],
  providers: [],
  isFetchingPrompts: false,
  prompts: [],
  promptQuery: undefined,
}

const reducer = (state = { ...INITIAL_STATE }, { type, payload }: Action) => {
  switch (type) {
    case types.FETCH_MODELS_REQUEST.START: {
      return update(state, {
        isFetchingAll: {$set: true},
      })
    }

    case types.FETCH_MODELS_REQUEST.FAILURE: {
      return update(state, {
        isFetchingAll: {$set: false}
      })
    }

    case types.FETCH_MODELS_REQUEST.SUCCESS: {
      const { models, providers } = payload;
      return update(state, {
        isFetchingAll: {$set: false},
        providers: {$set: providers},
        models: {$set: models},
      })
    }

    case types.FETCH_PROMPTS_REQUEST.START: {
      const { query } = payload;
      return update(state, {
        isFetchingPrompts: {$set: true},
        promptQuery:{$set: query},
      })
    }

    case types.FETCH_PROMPTS_REQUEST.FAILURE: {
      return update(state, {
        isFetchingPrompts: {$set: false}
      })
    }

    case types.FETCH_PROMPTS_REQUEST.SUCCESS: {
      const { prompts, query } = payload;
      if (query === state.promptQuery) {
        return update(state, {
          isFetchingPrompts: {$set: false},
          prompts: {$set: prompts},
        })
      }
      return state;
    }

    case RESET:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};


export default reducer;
