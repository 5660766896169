import { Dispatch } from 'redux';
import { perform } from '@hubblai/hubbl-ui/store/actions.js'
import * as types from './types';
import { Agent, App } from '../models';
import { HTTP_METHOD } from '@hubblai/hubbl-ui/lib/api.js';
import { GetStoreState } from '../types';

export const createApp = (organizationId: string, data: any) => async (dispatch: Dispatch) => {
  return perform<App>(
    dispatch,
    types.CREATE_APP_REQUEST,
    {
      method: HTTP_METHOD.POST,
      url: `/organizations/${organizationId}/apps`,
      data,
    },
    App.fromDTO
  );
}

export const updateApp = (organizationId: string, appId: string, data: any) => async (dispatch: Dispatch) => {
  return perform<App>(
    dispatch,
    types.UPDATE_APP_REQUEST,
    {
      method: HTTP_METHOD.PATCH,
      url: `/organizations/${organizationId}/apps/${appId}`,
      data,
    },
    App.fromDTO
  );
}

export const updateAppIcon = (organizationId: string, appId: string, file: File) => async (dispatch: Dispatch) => {
  return perform<App>(
    dispatch,
    types.UPDATE_APP_REQUEST,
    {
      method: HTTP_METHOD.PUT,
      url: `/organizations/${organizationId}/apps/${appId}/icon`,
      file,
    },
    App.fromDTO
  );
}

export const fetchApps = (organizationId: string) => async (dispatch: Dispatch) => {
  return perform<App[]>(
    dispatch,
    types.FETCH_APPS_REQUEST,
    {
      method: HTTP_METHOD.GET,
      url: `/organizations/${organizationId}/apps`,
    },
    App.fromDTOs
  );
}

export const addAgent = (organizationId: string, appId: string, agentId: string) => async (dispatch: Dispatch, getState: GetStoreState) => {
  const app = getState().apps.appMap[appId];
  const existingAgent = app?.agents?.find(agent => agent.id === agentId);
  if (existingAgent) {
    return existingAgent;
  }
  return perform<Agent>(
    dispatch,
    types.APP_ADD_AGENT_REQUEST,
    {
      method: HTTP_METHOD.PUT,
      url: `/organizations/${organizationId}/apps/${appId}/agents/${agentId}`,
      data: {},
    },
    Agent.fromDTO,
    {
      appId,
    }
  );
}

export const removeAgent = (organizationId: string, appId: string, agentId: string) => async (dispatch: Dispatch) => {
  return perform(
    dispatch,
    types.APP_REMOVE_AGENT_REQUEST,
    {
      method: HTTP_METHOD.DELETE,
      url: `/organizations/${organizationId}/apps/${appId}/agents/${agentId}`,
    },
    undefined,
    {
      appId,
      agentId,
    }
  );
}

export const fetchApp = (organizationId: string, appId: string) => async (dispatch: Dispatch) => {
  return perform<App>(
    dispatch,
    types.FETCH_APP_REQUEST,
    {
      method: HTTP_METHOD.GET,
      url: `/organizations/${organizationId}/apps/${appId}`,
    },
    App.fromDTO
  );
}

export const deleteApp = (organizationId: string, appId: string) => async (dispatch: Dispatch) => {
  return perform<Agent>(
    dispatch,
    types.DELETE_APP_REQUEST,
    {
      method: HTTP_METHOD.DELETE,
      url: `/organizations/${organizationId}/apps/${appId}`,
    },
    undefined,
    {
      id: appId,
    }
  );
}
