import { ButtonItem, Checkbox, Input, Label, Modal } from "@hubblai/hubbl-ui/components/index.js";
import { FormErrors } from "@hubblai/hubbl-ui/components/types.js";
import { useState } from "react";
import { SelectButton} from 'primereact/selectbutton';
import { Parameter } from "@hubblai/hubbl-core/lib/parameters.js";
import { ArgumentType } from "@hubblai/hubbl-core/lib/code.js";
import ValidationForm from "../ValidationForm";

type Props = {
  index: number,
  parameter: Parameter,
  onSave: () => void,
  onChange: (key: string, value: any) => void,
  onClose: () => void,
}

const ParameterModal: React.FC<Props> = ({ parameter, index, onClose, onSave, onChange }) => {

  const [errors, setErrors] = useState<FormErrors>({});

  const onNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => onChange('name', e.target.value);
  const onDisplayNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => onChange('display_name', e.target.value);
  const onDescriptionChanged = (e: React.ChangeEvent<HTMLInputElement>) => onChange('description', e.target.value);
  const onDefaultValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => onChange('default_value', e.target.value);

  const onMultilineChanged = (e: any) => onChange('multiline', e.target.value);
  const onSecretChanged = (e: any) => onChange('secret', e.target.value);
  const onMultipleChanged = (e: any) => onChange('multiple', e.target.value);

  const validate = () => {
    const errors: FormErrors = {};
    if (parameter.name.length === 0) {
      errors.name = "Name is required";
    }
    if (parameter.display_name?.length === 0) {
      errors.name = "Display Name is required";
    }
    if (parameter.description?.length === 0) {
      errors.description = "Description is required";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  }

  const onClickSave = () => {
    if (validate()) {
      onSave();
    }
  }

  const onTypeChanged = (e: any) => {
    const newType = e.target.value as ArgumentType;
    if (newType !== 'string') {
      // Probably better to do when i am ceratin about the type changing on Save
      onChange('multiline', false);
      onChange('secret', false);
      // onChange('values', undefined);
      // onChange('validations', (parameter.validation || []).filter(v => v.type !== 'oneOf'));
    }
    onChange('type', newType);
  }

  const buttons: ButtonItem[] = [{
    title: "Cancel",
    action: 'close',
    variant: 'default',
    outline: true,
  }, {
    title: index > -1 ? "Confirm" : "Add",
    variant: 'success',
    onClick: onClickSave,
    outline: true,
  }];

  const types = [{
    label: "Text",
    value: 'string',
  }, {
    label: "Number",
    value: 'number',
  }, {
    label: "Toggle",
    value: 'boolean',
  }, {
    label: "Object",
    value: 'object',
  }];

  return (
    <Modal isOpened={true} usesNavigation={false} onClose={onClose} size="mid-narrow" title={index > -1 ? "Edit Parameter" : "Add Parameter"} buttons={buttons}>
      <div className="mb-3">
        <Label title="Type" />
        <SelectButton value={parameter.type} options={types} onChange={onTypeChanged} />
      </div>

      <Input className="mb-3" value={parameter.name} onChange={onNameChanged} label="Name" error={errors.name} />
      <Input className="mb-3" value={parameter.display_name} onChange={onDisplayNameChanged} label="Display Name" error={errors.display_name} />
      <Input className="mb-3" value={parameter.description} onChange={onDescriptionChanged} label="Description" error={errors.description} multiline />
      {!parameter.multiple && <Input className="mb-3" value={parameter.default_value} onChange={onDefaultValueChanged} label={"Default Value"} error={errors.default_value} />}

      <Checkbox title={"List"} titlePosition="right" sublabel="Multiple values can be provided" value={parameter.multiple || false} onChange={onMultipleChanged} className="mb-3" />

      {parameter.type === 'string' &&
        <>
          <Checkbox title={"Multiline"} titlePosition="right" sublabel="Input for this parameter is long" value={parameter.multiline || false} onChange={onMultilineChanged} className="mb-3" />
          <Checkbox title={"Secret"} titlePosition="right" sublabel="Parameter value is hidden when displayed" value={parameter.secret || false} onChange={onSecretChanged} className="mb-3" />
        </>
      }

      <ValidationForm label={"Requirements"} parameter={parameter} onChange={onChange} />
    </Modal>
  )
}

export default ParameterModal;
