import { Navigate } from "react-router-dom";

const HomePage: React.FC = () => {

  return (
    <div>
      <Navigate to='/apps' replace />
    </div>
  )
}

export default HomePage;
