import { Argument } from "@hubblai/hubbl-core/lib/code.js";
import { Parameter, ParameterValue } from "@hubblai/hubbl-core/lib/parameters.js";
import { Button, Input, Label } from "@hubblai/hubbl-ui/components/index.js";
import { ComponentProps, QuickButton } from "@hubblai/hubbl-ui/components/types.js"
import { useState } from "react";

type Props = {
  argument?: Argument,
  parameter?: Parameter,
  onChange: (key: string, value: any) => void,
} & ComponentProps;

const AllowedValues: React.FC<Props> = ({ argument, parameter, onChange }) => {
  const getValidations = () => {
    if (argument?.validations) {
      return argument?.validations;
    }
    if (parameter?.validations) {
      return parameter?.validations;
    }
    return [];
  }
  const allowedValues = getValidations().find(v => v.type === 'oneOf')?.value || [];

  const [newAllowedValue, setNewAllowedValue] = useState<string | undefined>(undefined);

  const onClickRemoveAllowedValue = (valueId: string) => {
    if (parameter) {
      const values = parameter.values ? [...parameter.values] : [];
      const valuesIndex = values.findIndex(v => v.id === valueId);
      delete values[valuesIndex];
      onChange('values', values);
    }

    const validations = [...getValidations()];

    const validation = validations.find(v => v.type === 'oneOf');
    if (validation) {
      const validationIndex = (validation?.value || []).findIndex((v: any) => v === valueId);
      delete validation.value[validationIndex];
    }

    onChange('validations', validations);
  }

  const onClickAddAllowedValue = () => {
    setNewAllowedValue(value => {
      return value || '';
    });
  }

  const onChangeNewAllowedValue = (e: React.ChangeEvent<HTMLInputElement>) => setNewAllowedValue(e.target.value);

  const onClickConfirmAllowedValue = () => {
    if (newAllowedValue && newAllowedValue.length > 0) {
      const value: ParameterValue = { id: newAllowedValue, name: newAllowedValue };
      if (parameter) {
        const values = parameter.values || [];
        values.push(value);
        onChange('values', values);
      }

      const validations = [...getValidations()];
      const oneOfIndex = validations.findIndex(v => v.type === 'oneOf');
      if (oneOfIndex === -1) {
        validations.push({
          type: 'oneOf',
          value: [value.id],
        });
      } else if (validations[oneOfIndex].value) {
        validations[oneOfIndex].value.push(value.id);
      } else {
        validations[oneOfIndex].value = [value.id];
      }

      onChange('validations', validations);
    }
    setNewAllowedValue(undefined);
  }

  const allowedValuesButtons: QuickButton[] = [{
    label: newAllowedValue === undefined ? 'Add' : 'Confirm',
    onClick: newAllowedValue === undefined ? onClickAddAllowedValue : onClickConfirmAllowedValue,
  }]

  const onKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onClickConfirmAllowedValue();
    }
  }

  return (
    <>
      <Label title="Allowed Values" buttons={allowedValuesButtons} />
      {allowedValues.length === 0 && newAllowedValue === undefined && <div>Any value is allowed.</div>}
      {newAllowedValue !== undefined &&
        <Input value={newAllowedValue} onChange={onChangeNewAllowedValue} placeholder="Type allowed value..." onKeyPress={onKeyPress}/>
      }
      {allowedValues.map((value: string, i: number) =>
        <div key={i} className="flex items-center">{value} <Button size="xs" className="ml-2" onClick={() => onClickRemoveAllowedValue(value)} icon="remove" variant="link" outline /></div>
      )}
    </>
  )
}

export default AllowedValues;
