import React, { useState } from 'react';
import { Input } from '@hubblai/hubbl-ui/components/index.js';
import { ComponentProps, QuickButton } from '@hubblai/hubbl-ui/components/types.js';

type Props = {
  value: string,
  label?: string,
  onChange: (v: string) => void,
  sublabel?: string,
} & ComponentProps;

const JSONEditor: React.FC<Props> = ({ label, value, sublabel, onChange, className }) => {
  const [error, setError] = useState('');

  const validate = (v: string) => {
    try {
      JSON.parse(v);
      setError('');
    }
    catch (_) {
      setError('Invalid JSON');
    }
  }

  const onInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    validate(e.target.value);
    onChange(e.target.value);
  };

  const buttons: QuickButton[] = [{
    label: "Prettify",
    onClick: () => {
      if (error.length === 0) {
        const prettified = JSON.stringify(JSON.parse(value), null, 2);
        onChange(prettified);
      }
    }
  }];

  return (
    <Input label={label} multiline value={value} sublabel={sublabel} onChange={onInputChanged} className={className} inputClassName='font-mono text-sm' error={error} buttons={buttons} />
  )
}

export default JSONEditor;
