import { Argument } from "@hubblai/hubbl-core/lib/code.js";
import { ButtonItem, Input, Label, Modal } from "@hubblai/hubbl-ui/components/index.js";
import { FormErrors } from "@hubblai/hubbl-ui/components/types.js";
import { useState } from "react";
import { SelectButton} from 'primereact/selectbutton';
import ValidationForm from "../ValidationForm";

type Props = {
  index: number,
  arg: Argument,
  onSave: () => void,
  onChange: (key: string, value: any) => void,
  onClose: () => void,
}

const ArgumentModal: React.FC<Props> = ({ arg, index, onClose, onSave, onChange }) => {
  const [errors, setErrors] = useState<FormErrors>({});

  const onNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => onChange('name', e.target.value);
  const onDescriptionChanged = (e: React.ChangeEvent<HTMLInputElement>) => onChange('description', e.target.value);

  const validate = () => {
    const errors: FormErrors = {};
    if (arg.name.length === 0) {
      errors.name = "Name is required";
    }
    if (arg.description.length === 0) {
      errors.description = "Description is required";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  }

  const onClickSave = () => {
    if (validate()) {
      onSave();
    }
  }

  const onTypeChanged = (e: any) => {
    onChange('type', e.target.value);
  }

  const buttons: ButtonItem[] = [{
    title: "Cancel",
    action: 'close',
    variant: 'default',
    outline: true,
  }, {
    title: index > -1 ? "Confirm" : "Add",
    variant: 'success',
    onClick: onClickSave,
    outline: true,
  }];

  const types = [{
    label: "Text",
    value: 'string',
  }, {
    label: "Number",
    value: 'number',
  }, {
    label: "Boolean",
    value: 'boolean',
  }, {
    label: 'Object',
    value: 'object',
  }];

  return (
    <Modal isOpened={true} usesNavigation={false} onClose={onClose} size="mid" title={index > -1 ? "Edit Argument" : "Add Argument"} buttons={buttons}>
      <div className="mb-3">
        <Label title="Type" />
        <SelectButton value={arg.type} options={types} onChange={onTypeChanged} />
      </div>

      <Input className="mb-3" value={arg.name} onChange={onNameChanged} label="Name" error={errors.name} />
      <Input className="mb-3" value={arg.description} onChange={onDescriptionChanged} label="Description" error={errors.description} multiline />

      <ValidationForm label="Requirements" argument={arg} onChange={onChange} />
    </Modal>
  )
}

export default ArgumentModal;
