import { Button, DropdownMenuItem, Input, RadioGroup, Spinner, confirm } from "@hubblai/hubbl-ui/components/index.js";
import { FormErrors } from "@hubblai/hubbl-ui/components/types.js";
import { useCallback, useEffect, useRef, useState } from "react";
import { useIsUpdating } from "~/store/kits/hooks"
import { APP_VIEW_LEVEL } from "~/store/models";
import PanelFooter from "../Components/PanelFooter";
import PanelHeader from "../Components/PanelHeader";
import { useAppDispatch } from "@hubblai/hubbl-ui/store/index.js";
import { addToast } from "@hubblai/hubbl-ui/store/notifications/actions.js";
import { useCurrentOrganizationId } from "~/store/organizations/hooks";
import { getDisplayError } from "@hubblai/hubbl-ui/lib/api.js";
import { useNavigate } from "react-router-dom";
import { deepClone, getDiff, isEmpty } from "@hubblai/hubbl-core/lib/object.js";
import PanelBody from "../Components/PanelBody";
import { useApp } from "~/store/apps/hooks";
import { deleteApp, updateApp, updateAppIcon } from "~/store/apps/actions";
import EmbedModal from "~/components/EmbedModal";
import { TabPanel, TabView } from "primereact/tabview";
import AppSettingsForm from "~/components/AppSettingsForm";
import { ParametersFormRef } from "~/components/ParametersForm";
import ImageChangeModal from "~/components/ImageChangeModal/ImageChangeModal";

type Props = {
  id: string;
}

const VIEW_LEVELS = [
  { label: "Private", value: APP_VIEW_LEVEL.PRIVATE, description: "Only users with a link will be able to use your App" },
  { label: "Public", value: APP_VIEW_LEVEL.PUBLIC, description: "Anybody will be able to use your App" },
];

const AppEditPanel: React.FC<Props> = ({ id }) => {
  const aiSettingsFormRef = useRef<ParametersFormRef>(null);
  const uiSettingsFormRef = useRef<ParametersFormRef>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const app = useApp(id);
  const [errors, setErrors] = useState<FormErrors>({});
  const isUpdating = useIsUpdating();
  const organizationId = useCurrentOrganizationId();
  const [isEmbedModalOpened, setIsEmbedModalOpened] = useState(false);

  const [displayName, setDisplayName] = useState('');
  const [description, setDescription] = useState('');
  const [viewLevel, setViewLevel] = useState(APP_VIEW_LEVEL.PRIVATE);

  const [aiSettings, setAISettings] = useState({});
  const [uiSettings, setUISettings] = useState({});

  const [isImageModalOpened, setIsImageModalOpened] = useState(false);

  useEffect(() => {
    if (app) {
      setDisplayName(app.display_name);
      setDescription(app.description);
      setViewLevel(app.view_level);
      if (app.settings) {
        setAISettings(deepClone(app.settings.ai));
        setUISettings(deepClone(app.settings.ui));
      }
    }
  }, [app]);

  const onChangeDisplayName = (e: React.ChangeEvent<HTMLInputElement>) => setDisplayName(e.target.value);
  const onChangeDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => setDescription(e.target.value);
  const onViewLevelChanged = useCallback((viewLevel: number) => setViewLevel(viewLevel), []);

  const getData = () => {
    return {
      display_name: displayName,
      description,
      view_level: viewLevel,
      settings: {
        ai: {
          ...aiSettings,
          ...(aiSettingsFormRef.current?.getData() || {}),
        },
        ui: {
          ...uiSettings,
          ...(uiSettingsFormRef.current?.getData() || {}),
        },
      }
    };
  }

  const getChangedData = () => {
    return getDiff(app!, getData());
  }

  const validate = () => {
    let errors: FormErrors = {};
    if (displayName.length === 0) {
      errors.display_name = "Name is required";
    }
    if (description.length === 0) {
      errors.description = "Description is required";
    }
    const aiFormErrors = aiSettingsFormRef.current?.validate();
    const uiFormErrors = uiSettingsFormRef.current?.validate();
    errors = { ...errors, ...aiFormErrors, ...uiFormErrors};
    setErrors(errors);
    return Object.keys(errors).length === 0;
  }

  const onClickSave = async () => {
    if (validate()) {
      const data: any = getChangedData();
      try {
        if (!isEmpty(data)) {
          await dispatch(updateApp(organizationId!, app!.id, data));
        }
        dispatch(addToast('Nice!', `Your changes were saved!`, 'success'));
      }
      catch (err) {
        dispatch(addToast('Oops!', `Something went wrong: ${getDisplayError(err)}`));
      }
    }
  }

  const onClickCloseEmbedModal = useCallback(() => {
    setIsEmbedModalOpened(false);
  }, []);

  if (!app) {
    return <Spinner />;
  }

  const onClickDeleteConfirmed = async () => {
    try {
      await dispatch(deleteApp(organizationId!, id));
      navigate('/apps');
    }
    catch (err) {
      dispatch(addToast('Oops', `Error removing app: ${getDisplayError(err)}`, 'danger'));
    }
  };

  const buttons: DropdownMenuItem[] = [{
    label: 'Change Image',
    onClick: () => setIsImageModalOpened(true),
  }, {
    label: '-'
  }, {
    label: 'Remove',
    onClick: () => {
      confirm({
        message: <div>Are you sure you want to remove this app? </div>,
        header: 'Remove App',
        icon: 'pi pi-exclamation-triangle',
        accept: onClickDeleteConfirmed,
      })
    },
  }];

  const onClickTest = () => {
    setIsEmbedModalOpened(true);
  }

  const onAISettingsChanged = (key: string, value: any) => {
    setAISettings(currentSettings => {
      return {
        ...currentSettings,
        [key]: value,
      }
    });
  }

  const onUISettingsChanged = (key: string, value: any) => {
    setUISettings(currentSettings => {
      return {
        ...currentSettings,
        [key]: value,
      }
    });
  }

  const onImageModalClosed = () => setIsImageModalOpened(false);

  const onImageChanged = async (file?: File, imageUrl?: string) => {
    try {
      if (file) {
        await dispatch(updateAppIcon(organizationId!, app!.id, file));
      } else if (imageUrl) {
        await dispatch(updateApp(organizationId!, app!.id, {
          icon: imageUrl,
        }));
      }
      dispatch(addToast('Nice!', `The image was changed!`, 'success'));
    }
    catch (err) {
      dispatch(addToast('Oops!', `Something went wrong: ${getDisplayError(err)}`));
    }
  }

  return (
    <div className="flex flex-col flex-1">
      <PanelHeader buttons={buttons} title={app.display_name} icon={app.icon} />
      <PanelBody>
        <TabView panelContainerClassName='p-0 pt-3'>
          <TabPanel header={"General"}>
            <Input value={displayName} onChange={onChangeDisplayName} label="Name" className="mb-3" error={errors.display_name} />
            <Input value={description} onChange={onChangeDescription} label="Description" multiline className="mb-3" error={errors.description} />
            <RadioGroup label="Privacy" value={viewLevel} items={VIEW_LEVELS} onChange={onViewLevelChanged} />
          </TabPanel>
          <TabPanel header="AI Settings">
            <AppSettingsForm formRef={aiSettingsFormRef} settings={aiSettings} onChange={onAISettingsChanged} type='ai' />
          </TabPanel>
          <TabPanel header="UI Settings">
            <AppSettingsForm formRef={uiSettingsFormRef} settings={uiSettings} onChange={onUISettingsChanged} type='ui' />
          </TabPanel>
        </TabView>

      </PanelBody>
      <PanelFooter>
        <Button variant="info" title="Test" size="sm" icon="test" onClick={onClickTest} isLoading={isUpdating} />
        <Button variant="success" title="Save Changes" size="sm" icon="save" onClick={onClickSave} isLoading={isUpdating} />
      </PanelFooter>
      <EmbedModal appId={app.id} isOpened={isEmbedModalOpened} onClose={onClickCloseEmbedModal} />
      <ImageChangeModal isOpened={isImageModalOpened} onClose={onImageModalClosed} onChange={onImageChanged} />
    </div>
  )
}

export default AppEditPanel;
