import App from './App';
import { Navigate } from 'react-router-dom';
import HomePage from './pages/home';
import FunctionsLayout from './pages/functions/layout';
import FunctionsPage from './pages/functions';
import FunctionNewPage from './pages/functions/new';
import FunctionEditPage from './pages/functions/edit';
import KitsLayout from './pages/kits/layout';
import KitsPage from './pages/kits';
import KitsNewPage from './pages/kits/new';
import KitEditPage from './pages/kits/edit';
import AgentsLayout from './pages/agents/layout';
import AgentsNewPage from './pages/agents/new';
import AgentsPage from './pages/agents';
import AgentEditPage from './pages/agents/edit';
import AppsLayout from './pages/apps/layout';
import AppEditPage from './pages/apps/edit';
import AppsNewPage from './pages/apps/new';
import AppsPage from './pages/apps';

export default [{
  element: <App />,
  children: [
    {
      path: '/functions',
      element: <FunctionsLayout />,
      children: [
        { path: ':functionId', element: <FunctionEditPage /> },
        { path: 'new', element: <FunctionNewPage /> },
        { path: '', element: <FunctionsPage /> }
      ],
    },
    {
      path: '/kits',
      element: <KitsLayout />,
      children: [
        { path: ':kitId', element: <KitEditPage /> },
        { path: 'new', element: <KitsNewPage /> },
        { path: '', element: <KitsPage /> }
      ],
    },
    {
      path: '/agents',
      element: <AgentsLayout />,
      children: [
        { path: ':agentId', element: <AgentEditPage /> },
        { path: 'new', element: <AgentsNewPage /> },
        { path: '', element: <AgentsPage /> }
      ],
    },
    {
      path: '/apps',
      element: <AppsLayout />,
      children: [
        { path: ':appId', element: <AppEditPage /> },
        { path: 'new', element: <AppsNewPage /> },
        { path: '', element: <AppsPage /> }
      ],
    },
    { path: '/', element: <HomePage /> },
    { path: '*', element: <Navigate to="/" replace /> },
  ]
}];
