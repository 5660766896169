import { ParameterGroups } from "@hubblai/hubbl-core/lib/parameters.js";
import ParametersForm, { ParametersFormRef } from "../ParametersForm";
import { LegacyRef } from "react";

type Props = {
  formRef: LegacyRef<ParametersFormRef>,
  settings: any,
  type: 'ui' | 'ai',
  onChange: (key: string, value: any) => void,
}

const AISettings: ParameterGroups = [{
  label: 'Prompts',
  parameters: [
    {
      name: 'use_default_prompt',
      display_name: 'Use Default Prompt',
      description: 'Use default prompt specified for the model to do basic settings. Turn off for completely custom behavior.',
      type: 'boolean',
    },
    {
      name: 'allow_markdown',
      display_name: 'Allow Markdown',
      description: 'Allow Agents to use Markdown formatting in their communication',
      type: 'boolean',
    },
    {
      name: 'allow_localization',
      display_name: 'Allow Localization',
      description: "Allow Agents to respond in user's language, if provided",
      type: 'boolean',
    },
    {
      name: 'allow_emojis',
      display_name: 'Allow Emojis',
      description: 'Allow Agents to use Emojis in their communication',
      type: 'boolean',
    },
    {
      name: 'user_id',
      display_name: 'Session User ID',
      description: 'Default User ID that will be used for embedded session. User has to be member of your organization.',
      type: 'string',
    },
  ],
}, {
  label: 'Introduction',
  parameters: [{
    name: 'init_message',
    display_name: 'Message',
    description: 'Message sent to the default Agent on behalf of a user when App is created',
    type: 'string',
  }, {
    name: 'init_agent_id',
    display_name: 'Agent ID',
    description: 'If specified, introductory message is sent to this agent if exists in the App. Otherwise first agent found is used.',
    type: 'string',
  }]
}, {
  label: 'Interaction',
  parameters: [{
    name: 'use_tags',
    display_name: 'Use Tags',
    description: 'If enabled, Agents are allowed to tag user or other agents in the conversation to draw attention',
    type: 'boolean',
  }]
}];

const UISettings: ParameterGroups = [{
  label: 'Theme',
  parameters: [
    {
      name: 'theme',
      display_name: 'Theme Name',
      description: '',
      type: 'string',
      values: [{
        id: 'default',
        name: 'Default',
      }, {
        id: 'kindbridge',
        name: 'Kindbridge',
      }],
      validations: [{
        type: 'oneOf',
        value: ['default', 'kindbridge']
      }]
    },
    {
      name: 'separate_message_sides',
      display_name: 'Separate message into two columns',
      description: "If enabled, current user's messages are aligned to the right. Otherwise entire conversation is left aligned.",
      type: 'boolean',
    },
    {
      name: 'display_function_calls',
      display_name: 'Display Function Calls',
      description: 'If enabled, every time agent uses a function it is displayed in the chat for all participants to see.',
      type: 'boolean',
    },
    {
      name: 'display_system_messages',
      display_name: 'Display System Messages',
      description: 'If enabled, messages generated by the system as displayed in the chat such as if agent or user has joined or left a conversation.',
      type: 'boolean',
    },
    {
      name: 'show_interrupted_info',
      display_name: 'Show Interrupted Text',
      description: 'If agent was stopped to respond by the user, displays interrupted text at the end of the message.',
      type: 'boolean',
    },
    {
      name: 'show_current_user_group_header',
      display_name: 'Show Current User Header',
      description: "If enabled, current user's header is shown. By default contains avatar and name.",
      type: 'boolean',
    },
    {
      name: 'show_current_user_avatar',
      display_name: 'Show Current User Avatar',
      description: "If enabled, shows current user's avatar",
      type: 'boolean',
    },
    {
      name: 'show_current_user_name',
      display_name: 'Show Current User Name',
      description: "If enabled, shows current user's name",
      type: 'boolean',
    },
    {
      name: 'show_other_user_group_header',
      display_name: "Show Opposite User/Agent Header",
      description: "If enabled, other users' or agents' headers are shown. By default contain avatar and name.",
      type: 'boolean',
    },
    {
      name: 'show_other_user_name',
      display_name: 'Show Opposite User/Agent Name',
      description: "If enabled, other users' or agents' names are shown.",
      type: 'boolean',
    },
    {
      name: 'show_other_user_avatar',
      display_name: 'Show Opposite User/Agent Avatar',
      description: "If enabled, other users' or agents' avatars are shown.",
      type: 'boolean',
    },
    {
      name: 'show_typing',
      display_name: 'Show Typing',
      description: 'If enabled, displays "is typing" text when the agent is generating response',
      type: 'boolean',
    },
  ]
}, {
  label: 'Message Input',
  parameters: [{
    name: 'message_form_placeholder',
    display_name: 'Placeholder',
    description: 'Placeholder text displayed when Input is empty',
    type: 'string',
  },
  {
    name: 'message_form_button_title',
    display_name: 'Submit Button Title',
    description: '',
    type: 'string',
  },
  {
    name: 'message_form_button_icon',
    display_name: 'Submit Button Icon Name',
    description: '',
    type: 'string',
  },
  {
    name: 'message_form_send_keystroke',
    display_name: 'Submit Key Shortcut',
    description: 'Keystroke combination to submit a message',
    type: 'string',
    values: [{
      id: 'Enter',
      name: 'Enter',
    }, {
      id: 'CtrlCmdEnter',
      name: 'CtrlCmdEnter',
    }, {
      id: 'None',
      name: 'None',
    }],
    validations: [{
      type: 'oneOf',
      value: ['Enter', 'CtrlCmdEnter', 'None']
    }],
  }]
}, {
  label: "Tagging",
  parameters: [
    {
      name: 'render_tags',
      display_name: 'Render Tags',
      description: 'Allow rendering Tags. If disabled, tags will appear in their raw format.',
      type: 'boolean',
    },
    {
      name: 'delay_tag_render',
      display_name: 'Delay tag render',
      description: 'If tags are allowed to be rendered',
      type: 'boolean',
    },
    {
      name: 'tag_link_template',
      display_name: 'Tag link template',
      description: 'Link to be used to redirect the user or agent if tagged',
      type: 'string',
    }
  ]
}];

const AppSettingsForm: React.FC<Props> = ({ formRef, settings, type }) => {
  const settingsGroups = type === 'ai' ? AISettings : UISettings;

  return (
    <div>
      <ParametersForm ref={formRef} groups={settingsGroups} values={settings} groupClassName={'mb-5'} labelClassName="border-b-2 pb-3 mb-3" />
    </div>
  )
}

export default AppSettingsForm;
