import update from 'immutability-helper';
import { RESET, Action } from '@hubblai/hubbl-ui/store/types.js';
import * as agentTypes from '../agents/types';
import * as types from './types';
import * as kitTypes from '../kits/types';
import * as appTypes from '../apps/types';
import * as orgTypes from '../organizations/types';
import { Agent, App, FunctionModel, Kit } from '../models';
import { toMap } from '~/lib/object';

export type FunctionsState = {
  isCreating: boolean,
  isUpdating: boolean,
  isFetchingAll: boolean,
  isFetchingOne: boolean,
  functionMap: {
    [id: string]: FunctionModel
  },
  functionIds: string[],
}

const INITIAL_STATE: FunctionsState = {
  isCreating: false,
  isUpdating: false,
  isFetchingAll: false,
  isFetchingOne: false,
  functionMap: {},
  functionIds: [],
}

const reducer = (state = { ...INITIAL_STATE }, { type, payload }: Action) => {
  switch (type) {

    case types.FETCH_FUNCTIONS_REQUEST.START: {
      return update(state, {
        isFetchingAll: {$set: true},
      });
    }
    case types.FETCH_FUNCTIONS_REQUEST.SUCCESS: {
      const { data } = payload;
      const functionIds = data.map((item: any) => item.id);
      const functionMap = toMap(data);
      return update(state, {
        isFetchingAll: {$set: false},
        functionMap: {$merge: functionMap},
        functionIds: {$set: functionIds},
      });
    }
    case types.FETCH_FUNCTIONS_REQUEST.FAILURE: {
      return update(state, {
        isFetchingAll: {$set: false},
      });
    }

    case types.FETCH_FUNCTION_REQUEST.START: {
      return update(state, {
        isFetchingOne: {$set: true},
      });
    }
    case types.FETCH_FUNCTION_REQUEST.SUCCESS: {
      const { data } = payload;
      return update(state, {
        isFetchingOne: {$set: false},
        functionMap: {$merge: {
          [data.id]: data,
        }}
      });
    }

    case appTypes.APP_ADD_AGENT_REQUEST.SUCCESS: {
      const agent = payload.data as Agent;
      if (agent.kits) {
        const functions = agent.kits.reduce((acc: FunctionModel[], kit: Kit) => {
          if (kit.functions) {
            for (const fn of kit.functions) {
              acc.push(fn);
            }
          }
          return acc;
        }, []);
        const map = toMap(functions);
        return update(state, {
          functionMap: {$merge: map},
        });
      }
      return state;
    }
    case appTypes.FETCH_APP_REQUEST.SUCCESS: {
      const app = payload.data as App;
      if (app.agents) {
        const functions = app.agents.reduce((acc: FunctionModel[], agent: Agent) => {
          if (agent.kits) {
            for (const kit of agent.kits) {
              if (kit.functions) {
                for (const fn of kit.functions) {
                  acc.push(fn);
                }
              }
            }
          }
          return acc;
        }, []);
        const map = toMap(functions);
        return update(state, {
          functionMap: {$merge: map},
        });
      }
      return state;
    }

    case types.FETCH_FUNCTION_REQUEST.FAILURE: {
      return update(state, {
        isFetchingOne: {$set: false},
      });
    }

    case types.CREATE_FUNCTION_REQUEST.START: {
      return update(state, {
        isCreating: {$set: true},
      });
    }
    case types.CREATE_FUNCTION_REQUEST.SUCCESS: {
      const { data } = payload;
      return update(state, {
        isCreating: {$set: false},
        functionIds: {$unshift: [data.id]},
        functionMap: {$merge: {
          [data.id]: data,
        }}
      });
    }
    case types.CREATE_FUNCTION_REQUEST.FAILURE: {
      return update(state, {
        isCreating: {$set: false},
      });
    }

    case types.CREATE_DRAFT_FUNCTION_REQUEST.START: {
      const { id } = payload;
      const existingIndex = state.functionIds.findIndex(fId => fId === id);
      if (existingIndex > -1) {
        return update(state, {
          isUpdating: {$set: true},
          functionIds: {$splice: [[existingIndex, 1]]},
        });
      }
      return update(state, {
        isUpdating: {$set: true},

      });
    }
    case types.CREATE_DRAFT_FUNCTION_REQUEST.SUCCESS: {
      const { data } = payload;
      return update(state, {
        isUpdating: {$set: false},
        functionIds: {$unshift: [data.id]},
        functionMap: {$merge: {
          [data.id]: data,
        }}
      });
    }

    case types.UPDATE_FUNCTION_REQUEST.START: {
      return update(state, {
        isUpdating: {$set: true}
      });
    }
    case types.UPDATE_FUNCTION_REQUEST.SUCCESS: {
      const { data } = payload;
      return update(state, {
        isUpdating: {$set: false},
        functionMap: {$merge: {
          [data.id]: data,
        }}
      });
    }
    case types.CREATE_DRAFT_FUNCTION_REQUEST.FAILURE:
    case types.UPDATE_FUNCTION_REQUEST.FAILURE: {
      return update(state, {
        isUpdating: {$set: false}
      });
    }

    case types.DELETE_FUNCTION_REQUEST.START: {
      const { id } = payload;
      const existingIndex = state.functionIds.findIndex(fId => fId === id);
      if (existingIndex > -1) {
        return update(state, {
          functionIds: { $splice: [[existingIndex, 1]] },
        });
      }
      return state;
    }

    case kitTypes.KIT_ADD_FUNCTION_REQUEST.SUCCESS: {
      const { data } = payload;
      return update(state, {
        functionMap: {
          [data.id]: {$set: data}
        }
      });
    }

    case agentTypes.FETCH_AGENT_REQUEST.SUCCESS: {
      const agent = payload.data as Agent;
      if (agent.kits) {
        const functions = agent.kits.reduce((acc: FunctionModel[], kit: Kit) => {
          if (kit.functions) {
            for (const fn of kit.functions) {
              acc.push(fn);
            }
          }
          return acc;
        }, []);
        const map = toMap(functions);
        return update(state, {
          functionMap: {$merge: map},
        });
      }
      return state;
    }

    case kitTypes.FETCH_KIT_REQUEST.SUCCESS: {
      const kit = payload.data as Kit;
      if (kit.functions) {
        const map = toMap(kit.functions);
        return update(state, {
          functionMap: { $merge: map },
        })
      }
      return state;
    }

    case orgTypes.SET_CURRENT_ORGANIZATION:
    case RESET:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};


export default reducer;
