import clsx from "clsx";
import { Kit } from "~/store/models";
import styles from './KitListItem.module.css';
import { Link } from "react-router-dom";

type Props = {
  kit: Kit,
  isActive?: boolean,
  onClick?: () => void,
  showOrganization?: boolean,
}

const KitListItem: React.FC<Props> = ({ kit, isActive, onClick, showOrganization }) => {
  return (
    <Link to={onClick ? '' : `/kits/${kit.id}`} onClick={onClick} className={clsx(styles.item, { [styles.active]: isActive })}>

      <div className={styles.avatar}>
        <img src={kit.icon} />
      </div>
      <div className="flex-1 ml-2">
        <div className={styles.title}>{kit.title}</div>
        {kit.organization && showOrganization && <span className="opacity-50 text-sm font-normal mb-1">By {kit.organization.display_name}</span>}
        <div className={styles.subtitle}>{kit.description}</div>
      </div>
    </Link>
  )
}

export default KitListItem;
