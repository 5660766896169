export const FETCH_KITS_REQUEST = {
  START: "FETCH_KITS_REQUEST.START",
  SUCCESS: "FETCH_KITS_REQUEST.SUCCESS",
  FAILURE: "FETCH_KITS_REQUEST.FAILURE",
}

export const FETCH_KIT_REQUEST = {
  START: "FETCH_KIT_REQUEST.START",
  SUCCESS: "FETCH_KIT_REQUEST.SUCCESS",
  FAILURE: "FETCH_KIT_REQUEST.FAILURE",
}

export const CREATE_KIT_REQUEST = {
  START: "CREATE_KIT_REQUEST.START",
  SUCCESS: "CREATE_KIT_REQUEST.SUCCESS",
  FAILURE: "CREATE_KIT_REQUEST.FAILURE",
}

export const UPDATE_KIT_REQUEST = {
  START: "UPDATE_KIT_REQUEST.START",
  SUCCESS: "UPDATE_KIT_REQUEST.SUCCESS",
  FAILURE: "UPDATE_KIT_REQUEST.FAILURE",
}

export const DELETE_KIT_REQUEST = {
  START: "DELETE_KIT_REQUEST.START",
  SUCCESS: "DELETE_KIT_REQUEST.SUCCESS",
  FAILURE: "DELETE_KIT_REQUEST.FAILURE",
}

export const KIT_ADD_FUNCTION_REQUEST = {
  START: "KIT_ADD_FUNCTION_REQUEST.START",
  SUCCESS: "KIT_ADD_FUNCTION_REQUEST.SUCCESS",
  FAILURE: "KIT_ADD_FUNCTION_REQUEST.FAILURE",
}

export const KIT_REMOVE_FUNCTION_REQUEST = {
  START: "KIT_REMOVE_FUNCTION_REQUEST.START",
  SUCCESS: "KIT_REMOVE_FUNCTION_REQUEST.SUCCESS",
  FAILURE: "KIT_REMOVE_FUNCTION_REQUEST.FAILURE",
}
