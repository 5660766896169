import { MODEL_ID } from "@hubblai/hubbl-core/models/Agent.js";
import { useEffect, useState } from "react";
import { Button, Spinner, Input, Tag, Modal, ButtonItem } from '@hubblai/hubbl-ui/components/index.js';
// import { useDebounce } from "@hubblai/hubbl-ui/hooks/index.js";
import { useAppDispatch } from "@hubblai/hubbl-ui/store/index.js";
import { fetchPrompts } from "~/store/models/actions";
import { useIsFetchingPrompts, usePrompts } from "~/store/models/hooks";

type Props = {
  isOpened: boolean,
  modelId: MODEL_ID,
  buttons?: ButtonItem[],
  onPromptSelected: (prompt: string) => void,
  onClose: () => void,
}

const PromptsModal: React.FC<Props> = ({ onPromptSelected, isOpened, buttons, modelId, onClose }) => {
  const dispatch = useAppDispatch();
  const [query, setQuery] = useState('');
  // const debouncedQuery = useDebounce(query, 500);
  const prompts = usePrompts();
  const isFetchingPrompts = useIsFetchingPrompts();
  const [expandedPrompts, setExpandedPrompts] = useState<Set<number>>(new Set());

  // TODO:P1 chancing query dispatches anoyher fetch

  useEffect(() => {
    dispatch(fetchPrompts(modelId));
  }, [dispatch, modelId]);

  const onQueryChanged = (e: React.ChangeEvent<HTMLInputElement>) => setQuery(e.target.value);

  const onClickTogglePrompt = (e: React.MouseEvent<HTMLDivElement>, id: number) => {
    e.preventDefault();
    const expanded = new Set([...expandedPrompts]);
    if (expanded.has(id)) {
      expanded.delete(id);
    } else {
      expanded.add(id);
    }
    setExpandedPrompts(expanded);
  }

  return (
    <Modal buttons={buttons} title={`Persona Suggestions`} isOpened={isOpened} size='almost-max' usesNavigation={false} onClose={onClose}>
      <Input className="flex-1 mb-3" value={query} onChange={onQueryChanged} placeholder="Search for personas..." />
      {isFetchingPrompts && <Spinner />}
      {!isFetchingPrompts && prompts.length === 0 && <div>No matching personas were found for this model.</div>}
      {prompts.length > 0 && prompts.map(prompt =>
        <div className="rounded-md border-2 py-3 px-3 hover:border-gray-600 cursor-pointer mb-3" key={prompt.id}  onClick={(e) => onClickTogglePrompt(e, prompt.id)}>
          <div className="flex flex-1 flex-row justify-between">
            <div>
              <div className="mb-2 font-semibold">{prompt.title}</div>
              <div>{prompt.description}</div>
            </div>
            <Button onClick={() => onPromptSelected(prompt.prompt)} title="Use" variant="success" className="px-4 py-0" rounded />
          </div>
          {expandedPrompts.has(prompt.id) &&
            <div className="border-t-2 mt-3 pt-3 whitespace-pre-line">
              <div className="mb-2"><Tag title="Prompt" variant="info" /></div>
              {prompt.prompt}
            </div>
          }
        </div>
      )}
    </Modal>
  )
}

export default PromptsModal;
