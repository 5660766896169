import { Outlet, useNavigate, useParams } from "react-router-dom"
import styles from './layout.module.css';
import { useFunctions, useIsFetchingAll } from "~/store/functions/hooks";
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { Button } from "@hubblai/hubbl-ui/components/index.js";
import FunctionListItem from "~/components/FunctionListItem/FunctionListItem";
import Search from "~/components/Search";
import { useCurrentOrganizationId } from "~/store/organizations/hooks";
import { useEffect } from "react";

const SIDE_PANEL_SIZE = 30;


const FunctionsLayout: React.FC = () => {
  const navigate = useNavigate();
  const functions = useFunctions();
  const { functionId } = useParams();
  const isFetching = useIsFetchingAll();

  const organizationId = useCurrentOrganizationId();

  useEffect(() => {
    if (organizationId) {
      navigate('/functions' + (functionId ? `/${functionId}` : ''));
    }
  }, [navigate, organizationId, functionId]);

  const onClickNewFunction = () => {
    navigate('/functions/new');
  }

  const onSearchChanged = () => {
    // TODO:P1 actually implement
  }

  return (
    <Splitter className="flex flex-1 overflow-hidden">
      <SplitterPanel size={SIDE_PANEL_SIZE} minSize={10} className="flex flex-col">
        <div className="flex flex-row border-b-2 p-2">
          <Search placeholder="Search functions..." className="flex-1" onChange={onSearchChanged} />
          <Button icon='plus' onClick={onClickNewFunction} className="ml-1" />
        </div>
        {!isFetching && functions.length > 0 && (
          <div className={styles.list}>
            {functions.map(fn => (
              <FunctionListItem key={fn.id} fn={fn} isActive={functionId === fn.id} />
            ))}
          </div>
        )}
      </SplitterPanel>
      <SplitterPanel className="overflow-auto" size={100 - SIDE_PANEL_SIZE}>
        <Outlet />
      </SplitterPanel>
    </Splitter>
  )
}

export default FunctionsLayout;
