import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { deepEqual } from '@hubblai/hubbl-core/lib/object.js';
import { KitsState } from './reducer';
import { Kit } from '../models';
import { CanvasNode, KitToCanvasNode } from '~/lib/canvas';
import { EntityMap } from '../types';
import { store } from '@hubblai/hubbl-ui/store/index.js';

type KitsStoreType = {
  kits: KitsState,
}
const selectKitIds = () => (state: KitsStoreType): string[] => state.kits.kitIds;
const selectKitMap = () => (state: KitsStoreType): EntityMap<Kit> => state.kits.kitMap;
const selectKit = (kitId: string) => (state: KitsStoreType): Kit | undefined => state.kits.kitMap[kitId];

const kitAsSelector = (kitId: string) => createSelector(selectKitMap(), kitMap => kitMap[kitId]);
const kitAsNodeSelector = (kitId: string, organizationId: string) => createSelector(
  selectKit(kitId),
  (kit?: Kit)=> kit ? KitToCanvasNode(store, kit, organizationId, { showAddNode: true, viewType: 'edit' }) : undefined,
);
const kitsAsSelector = () => createSelector(
  [selectKitIds(), selectKitMap()],
  (ids, kitMap) => ids.map(id => kitMap[id])
);

export const useIsFetchingAll = () => useSelector<KitsStoreType, boolean>(state => state.kits.isFetchingAll);
export const useIsFetchingOne = () => useSelector<KitsStoreType, boolean>(state => state.kits.isFetchingOne);
export const useIsUpdating = () => useSelector<KitsStoreType, boolean>(state => state.kits.isUpdating);
export const useKits = () => useSelector<KitsStoreType, Kit[]>(kitsAsSelector());
export const useKit = (kitId: string) => useSelector<KitsStoreType, Kit | undefined>(kitAsSelector(kitId));
export const useKitAsNode = (kitId: string, organizationId: string) => useSelector<KitsStoreType, CanvasNode | undefined>(
  kitAsNodeSelector(kitId, organizationId),
  deepEqual
);
