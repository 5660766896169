import { useAppDispatch } from "@hubblai/hubbl-ui/store/index.js";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchFunction } from "~/store/functions/actions";
import { useFunction } from "~/store/functions/hooks";
import { useCurrentOrganizationId } from "~/store/organizations/hooks";
import { Button, Icon, Tag } from "@hubblai/hubbl-ui/components/index.js";
import styles from './edit.module.css';
import { FunctionModel } from "~/store/models";
import { FUNCTION_STATUS, getDisplayFunctionStatus } from "@hubblai/hubbl-core/models/Function.js";
import FunctionForm from "~/components/FunctionForm";

type Props = {
  fn: FunctionModel,
}

const FunctionHeader: React.FC<Props> = ({ fn }) => {
  const navigate = useNavigate();
  const onClickViewPrevious = () => {
    navigate(`/functions/${fn.previous_function_id}`);
  }
  const onClickViewNext = () => {
    navigate(`/functions/${fn.next_function_id}`);
  }
  return (
    <div className={styles.header}>
      <div className={styles.icon}>
        <Icon name='function' />
      </div>
      <div className="flex flex-col flex-1">
        <div className={styles.title}>
          <div>{fn.name}</div>
          <div className="flex flex-row items-center">
            {fn.previous_function_id && <Button title={fn.status === FUNCTION_STATUS.DRAFT ? 'View Latest' : 'View Previous'} variant="link" size="xs" onClick={onClickViewPrevious} />}
            {fn.next_function_id && <Button title={fn.status === FUNCTION_STATUS.LATEST ? 'View Draft' : 'View Next'} variant="link" size="xs" onClick={onClickViewNext} />}
            <Tag className="ml-1" title={getDisplayFunctionStatus(fn.status)} variant="secondary" />
          </div>
        </div>
        <div className={styles.subtitle}>
          {fn.description || "No description"}
        </div>
      </div>
    </div>
  )
}

const FunctionEditPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();
  const functionId = params.functionId!;
  const organizationId = useCurrentOrganizationId();
  const fn = useFunction(functionId);

  useEffect(() => {
    if (organizationId) {
      dispatch(fetchFunction(organizationId, functionId));
    }
  }, [organizationId, dispatch, functionId])

  const onFunctionDeleted = () => {
    navigate('/functions');
  }

  const onFunctionCreated = (functionId: string) => {
    navigate(`/functions/${functionId}`, { replace: true });
  }

  return (
    <div className="flex flex-col flex-1">
      {fn && <FunctionHeader fn={fn} />}
      {fn && organizationId && <FunctionForm className="overflow-auto" fn={fn} organizationId={organizationId} onDeleted={onFunctionDeleted} onCreated={onFunctionCreated} />}
    </div>
  )
}

export default FunctionEditPage;
