
import { Spinner } from '@hubblai/hubbl-ui/components/index.js';
import { ComponentProps } from '@hubblai/hubbl-ui/components/types.js';
import API, { getDisplayError } from '@hubblai/hubbl-ui/lib/api.js';
import { useAppDispatch } from '@hubblai/hubbl-ui/store/index.js';
import { addToast } from '@hubblai/hubbl-ui/store/notifications/actions.js';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Search from '~/components/Search';
import { Kit } from '~/store/models';
import { TabView, TabPanel } from 'primereact/tabview';
import { useCurrentOrganizationId } from '~/store/organizations/hooks';
import { useOrganization } from '@hubblai/hubbl-ui/store/auth/hooks.js';
import { useKits } from '~/store/kits/hooks';
import PanelHeader from '../Components/PanelHeader';
import PanelBody from '../Components/PanelBody';
import { addKit } from '~/store/agents/actions';
import KitListItem from '~/components/KitListItem';

type Props = {
  agentId: string,
  selectNode: (nodeId?: string) => void,
} & ComponentProps;

const AddKitPanel: React.FC<Props> = ({ agentId, selectNode }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [kits, setKits] = useState<Kit[]>([]);
  const orgKits = useKits();
  const organizationId = useCurrentOrganizationId();
  const organization = useOrganization(organizationId || '');
  // const _isUpdating = useIsUpdating();

  // // TODO:P1 make search work again

  const fetchKits = useCallback(async () => {
    setIsFetching(true);
    try {
      const { data } = await API.get(`/kits`, { query });
      const kits = Kit.fromDTOs(data);
      setKits(kits);
    }
    catch (err) {
      const errorMessage = getDisplayError(err);
      dispatch(addToast('Ooops', `Seems like there was a problem fetching kits: ${errorMessage}`));
    }
    setIsFetching(false);
  }, [query, dispatch]);

  useEffect(() => {
    fetchKits();
  }, [fetchKits]);

  const onClickKit = async (kit: Kit) => {
    try {
      await dispatch(addKit(organizationId!, agentId, kit.id));
      // selectNode(getKitNodeId(kit.id));
      selectNode();
      dispatch(addToast('Done!', 'Kit was added to the Agent', 'success'));
    }
    catch (err) {
      dispatch(addToast('Ooops', `Seems like there was a problem adding kit: ${getDisplayError(err)}`));
    }
  }

  const onSearchChanged = (query: string) => setQuery(query);

  const matchingOrgKits = query.length > 0 ? orgKits.filter(kit => kit.title.includes(query) ) : orgKits;

  const buttons = [{
    label: 'Create New Kits',
    onClick: () => navigate('/kits/new'),
  }]

  return (
    <div className="flex flex-col flex-1 h-full w-full">
      <PanelHeader title='Add Kit' buttons={buttons}>
        <Search placeholder='Search for kits...' onChange={onSearchChanged} />
      </PanelHeader>
      <PanelBody>
        <TabView panelContainerClassName='p-0'>
          <TabPanel header={organization?.display_name}>
            {matchingOrgKits.map(kit => <KitListItem key={`org-${kit.id}`} kit={kit} onClick={() => onClickKit(kit)} />)}
            {matchingOrgKits.length === 0 && query.length === 0 && <div>Your organization does not have any kits.</div>}
            {matchingOrgKits.length === 0 && query.length > 0 && <div>No kits were found matching "{query}".</div>}
          </TabPanel>
          <TabPanel header="Public">
            {kits.map(kit => <KitListItem key={kit.id} kit={kit} onClick={() => onClickKit(kit)} showOrganization />)}
            {isFetching && <Spinner />}
            {!isFetching && query.length > 0 && kits.length === 0 && <div className='flex-1 justify-center items-center'>No kits were found matching "{query}".</div>}
          </TabPanel>
        </TabView>
      </PanelBody>
    </div>
  )
}

export default AddKitPanel;
