import { Input } from '@hubblai/hubbl-ui/components/index.js';
import { ClassName, ComponentProps } from '@hubblai/hubbl-ui/components/types.js';
import { useDebounce } from "@hubblai/hubbl-ui/hooks/index.js";
import React, { useEffect, useState } from 'react';

type Props = {
  placeholder: string,
  onChange: (value: string) => void,
  inputClassName?: ClassName,
} & ComponentProps;

const Search: React.FC<Props> = ({ onChange, placeholder, className, inputClassName }) => {
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 500);

  const onValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => setQuery(e.target.value);

  useEffect(() => {
    // onChange(debouncedQuery);
  }, [debouncedQuery, onChange]);

  return (
    <div className={className}>
      <Input placeholder={placeholder} value={query} onChange={onValueChanged} className={inputClassName} icon='search' />
    </div>
  )
}

export default Search;
