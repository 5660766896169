import { ComponentProps } from '@hubblai/hubbl-ui/components/types.js';
import React from 'react';

type FooterProps = ComponentProps;

const PanelFooter: React.FC<FooterProps> = ({ children }) => {
  return (
    <div className="border-t-2 pt-3 mt-3 bottom-0 flex flex-row justify-between">
      {children}
    </div>
  )
}

export default PanelFooter;
