import { AGENT_VIEW_LEVEL } from "@hubblai/hubbl-core/models/Agent.js";
import { Node } from "reactflow";
import { APP_VIEW_LEVEL, Agent, App, FunctionModel, KIT_VIEW_LEVEL, Kit } from "~/store/models";
import { Store } from "~/store/types";

export type CanvasNodeType = 'kit' | 'add-function' | 'function' | 'add-kit' | 'agent' | 'add-agent' | 'app';

export type CanvasNodeViewType = 'config' | 'edit';

export type CanvasNodeOptions = {
  parentId?: string,
  showAddNode?: boolean,
  viewType?: CanvasNodeViewType,
}

export type CanvasNode = {
  id: string;
  nodeId: string,
  icon?: string;
  title: string;
  subtitle?: string;
  type: CanvasNodeType;
  leftSubtitle?: string,
  rightSubtitle?: string,
  nodes?: CanvasNode[],
  parentId?: string,
  viewType?: CanvasNodeViewType,
  isEditable?: boolean,
}

export const getNodeId = (entityName: string, id: string, parentId?: string) => [entityName, parentId, id].join('_');
export const getAgentNodeId = (agentId: string, parentId?: string) => getNodeId('agent', agentId, parentId);
export const getFunctionNodeId = (functionId: string, parentId?: string) => getNodeId('function', functionId, parentId);
export const getKitNodeId = (kitId: string, parentId?: string) => getNodeId('kit', kitId, parentId);
export const getAppNodeId = (appId: string, parentId?: string) => getNodeId('app', appId, parentId);

export const createNode = (o: CanvasNode, x: number, y: number): Node => {
  return {
    id: o.nodeId,
    draggable: false,
    position: { x, y },
    data: o,
    type: o.type,
  }
}

const toKitDisplayViewLevel = (viewLevel: KIT_VIEW_LEVEL) => {
  switch (viewLevel) {
    case KIT_VIEW_LEVEL.PRIVATE: return 'Private';
    case KIT_VIEW_LEVEL.PUBLIC:
      default:
        return 'Public';
  }
}
const toAppDisplayViewLevel = (viewLevel: APP_VIEW_LEVEL) => {
  switch (viewLevel) {
    case APP_VIEW_LEVEL.PRIVATE: return 'Private';
    case APP_VIEW_LEVEL.PUBLIC:
      default:
        return 'Public';
  }
}
const toAgentDisplayViewLevel = (viewLevel: AGENT_VIEW_LEVEL) => {
  switch (viewLevel) {
    case AGENT_VIEW_LEVEL.PRIVATE: return 'Private';
    case AGENT_VIEW_LEVEL.PUBLIC:
      default:
        return 'Public';
  }
}

export const FunctionToCanvasNode = (_store: Store, fn: FunctionModel, organizationId: string, options: CanvasNodeOptions): CanvasNode => {
  const isEditable = fn.organization_id === organizationId;
  return {
    id: fn.id,
    nodeId: getFunctionNodeId(fn.id, options.parentId),
    title: fn.name,
    subtitle: fn.description,
    type: 'function',
    parentId: options.parentId,
    viewType: options.viewType,
    isEditable,
  }
}

export const KitToCanvasNode = (store: Store, kit: Kit, organizationId: string, options: CanvasNodeOptions): CanvasNode => {
  const state = store.getState();
  const isEditable = kit.organization_id === organizationId;
  const functionIds = kit.functions?.map(fn => fn.id) || [];
  const functions = functionIds.map(id => state.functions.functionMap[id]);
  const nodes: CanvasNode[] = functions.map(fn => FunctionToCanvasNode(store, fn, organizationId, {
    parentId: kit.id,
    viewType: 'config'
  })) || [];

  if (options.showAddNode && isEditable) {
    const nodeId = getNodeId('add-function', kit.id);
    nodes.push({
      id: nodeId,
      nodeId,
      title: 'Add Function',
      type: 'add-function',
      parentId: kit.id,
      viewType: 'config',
    });
  }

  return {
    id: kit.id,
    nodeId: getKitNodeId(kit.id, options.parentId),
    icon: kit.icon,
    title: kit.title,
    subtitle: kit.description,
    type: 'kit',
    leftSubtitle: kit.version,
    rightSubtitle: toKitDisplayViewLevel(kit.view_level),
    nodes,
    parentId: options.parentId,
    viewType: options.viewType,
    isEditable,
  }
}

export const AgentToCanvasNode = (store: Store, agent: Agent, organizationId: string, options: CanvasNodeOptions): CanvasNode => {
  const state = store.getState();
  const isEditable = agent.organization_id === organizationId;
  const kitIds = agent.kits?.map(kit => kit.id) || [];
  const kits = kitIds.map(id => state.kits.kitMap[id]);
  const nodes: CanvasNode[] = kits.map(kit => KitToCanvasNode(store, kit, organizationId, {
    parentId: agent.id,
    viewType: 'config',
    showAddNode: options.showAddNode,
  })) || [];

  if (options.showAddNode && isEditable) {
    const nodeId = getNodeId('add-kit', agent.id);
    nodes.push({
      id: nodeId,
      nodeId,
      title: 'Add Kit',
      type: 'add-kit',
      parentId: agent.id,
      viewType: 'config',
    });
  }

  return {
    id: agent.id,
    nodeId: getAgentNodeId(agent.id, options.parentId),
    icon: agent.icon,
    title: agent.getDisplayName(),
    subtitle: agent.description,
    type: 'agent',
    leftSubtitle: agent.getModelName(),
    rightSubtitle: toAgentDisplayViewLevel(agent.view_level),
    nodes,
    parentId: options.parentId,
    viewType: options.viewType,
    isEditable,
  }
}

export const AppToCanvasNode = (store: Store, app: App, organizationId: string, options: CanvasNodeOptions): CanvasNode => {
  const state = store.getState();
  const isEditable = app.organization_id === organizationId;
  const agentIds = app.agents?.map(agent => agent.id) || [];
  const agents = agentIds.map(id => state.agents.agentMap[id]);
  const nodes: CanvasNode[] = agents.map(agent => AgentToCanvasNode(store, agent, organizationId, {
    parentId: app.id,
    viewType: 'config',
    showAddNode: options.showAddNode,
  })) || [];

  if (options.showAddNode && isEditable) {
    const nodeId = getNodeId('add-agent', app.id);
    nodes.push({
      id: nodeId,
      nodeId: nodeId,
      title: 'Add Agent',
      type: 'add-agent',
      parentId: app.id,
      viewType: 'config',
    });
  }

  return {
    id: app.id,
    nodeId: getAppNodeId(app.id, options.parentId),
    icon: app.icon,
    title: app.display_name,
    subtitle: app.description,
    type: 'app',
    nodes,
    parentId: options.parentId,
    viewType: options.viewType,
    rightSubtitle: toAppDisplayViewLevel(app.view_level),
    isEditable,
  }
}
