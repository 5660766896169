import { useSelector } from 'react-redux';
import { FunctionsState } from './reducer';
import { FunctionModel } from '../models';
import { EntityMap } from '../types';
import { createSelector } from 'reselect';

type FunctionsStoreType = {
  functions: FunctionsState,
}

const selectFunctionIds = () => (state: FunctionsStoreType): string[] => state.functions.functionIds;
const selectFunctionMap = () => (state: FunctionsStoreType): EntityMap<FunctionModel> => state.functions.functionMap;
const functionsAsSelector = () => createSelector(
  [selectFunctionIds(), selectFunctionMap()],
  (ids, functionMap) => ids.map(id => functionMap[id])
);

export const useIsFetchingAll = () => useSelector<FunctionsStoreType, boolean>(state => state.functions.isFetchingAll);
export const useIsFetchingOne = () => useSelector<FunctionsStoreType, boolean>(state => state.functions.isFetchingOne);
export const useIsUpdating = () => useSelector<FunctionsStoreType, boolean>(state => state.functions.isUpdating);
export const useFunctions = () => useSelector<FunctionsStoreType, FunctionModel[]>(functionsAsSelector());
export const useFunction = (functionId: string) => useSelector<FunctionsStoreType, FunctionModel | undefined>(state => state.functions.functionMap[functionId]);
