import update from 'immutability-helper';
import { Action, RESET } from '@hubblai/hubbl-ui/store/types.js';
import * as types from './types';
import * as appTypes from '../apps/types';
import * as orgTypes from '../organizations/types';
import { Agent, App } from '../models';
import { toMap } from '~/lib/object';

export type AgentsState = {
  isCreating: boolean,
  isUpdating: boolean,
  isFetchingAll: boolean,
  isFetchingOne: boolean,
  agentMap: {
    [id: string]: Agent,
  },
  agentIds: string[],
}

const INITIAL_STATE: AgentsState = {
  isCreating: false,
  isUpdating: false,
  isFetchingAll: false,
  isFetchingOne: false,
  agentMap: {},
  agentIds: [],
}

const reducer = (state = { ...INITIAL_STATE }, { type, payload }: Action) => {
  switch (type) {

    case types.FETCH_AGENTS_REQUEST.START: {
      return update(state, {
        isFetchingAll: {$set: true},
      });
    }
    case types.FETCH_AGENTS_REQUEST.SUCCESS: {
      const { data } = payload;
      const agentIds = data.map((item: any) => item.id);
      const agentMap = toMap(data);
      return update(state, {
        isFetchingAll: {$set: false},
        agentMap: {$merge: agentMap},
        agentIds: {$set: agentIds},
      });
    }
    case types.FETCH_AGENTS_REQUEST.FAILURE: {
      return update(state, {
        isFetchingAll: {$set: false},
      });
    }

    case types.FETCH_AGENT_REQUEST.START: {
      return update(state, {
        isFetchingOne: {$set: true},
      });
    }
    case types.FETCH_AGENT_REQUEST.SUCCESS: {
      const { data } = payload;
      return update(state, {
        isFetchingOne: {$set: false},
        agentMap: {$merge: {
          [data.id]: data,
        }}
      });
    }
    case types.FETCH_AGENT_REQUEST.FAILURE: {
      return update(state, {
        isFetchingOne: {$set: false},
      });
    }

    case types.CREATE_AGENT_REQUEST.START: {
      return update(state, {
        isCreating: {$set: true},
      });
    }
    case types.CREATE_AGENT_REQUEST.SUCCESS: {
      const { data } = payload;
      return update(state, {
        isCreating: {$set: false},
        agentIds: {$unshift: [data.id]},
        agentMap: {$merge: {
          [data.id]: data,
        }}
      });
    }
    case types.CREATE_AGENT_REQUEST.FAILURE: {
      return update(state, {
        isCreating: {$set: false},
      });
    }

    case types.AGENT_UPDATE_KIT_REQUEST.START:
    case types.AGENT_REMOVE_KIT_REQUEST.START:
    case types.AGENT_ADD_KIT_REQUEST.START:
    case types.UPDATE_AGENT_REQUEST.START: {
      return update(state, {
        isUpdating: {$set: true}
      });
    }
    case types.UPDATE_AGENT_REQUEST.SUCCESS: {
      const { data } = payload;
      return update(state, {
        isUpdating: {$set: false},
        agentMap: {$merge: {
          [data.id]: data,
        }}
      });
    }
    case types.AGENT_UPDATE_KIT_REQUEST.FAILURE:
    case types.AGENT_REMOVE_KIT_REQUEST.FAILURE:
    case types.AGENT_ADD_KIT_REQUEST.FAILURE:
    case types.UPDATE_AGENT_REQUEST.FAILURE: {
      return update(state, {
        isUpdating: {$set: false}
      });
    }

    case types.AGENT_REMOVE_KIT_REQUEST.SUCCESS: {
      const { agentId, kitId } = payload;
      const agent = state.agentMap[agentId];
      if (agent && agent.kits) {
        const kitIndex = agent.kits.findIndex(kit => kit.id === kitId);
        if (kitIndex > -1) {
          const clonedAgent = agent.clone();
          clonedAgent.kits!.splice(kitIndex, 1);
          return update(state, {
            agentMap: {
              [agentId]: {$set: clonedAgent},
            }
          });
        }
      }
      return state;
    }

    case types.AGENT_UPDATE_KIT_REQUEST.SUCCESS: {
      const { data, agentId, kitId } = payload;
      const agent = state.agentMap[agentId];
      if (agent) {
        const kitIndex = agent.kits?.findIndex(kit => kit.id === kitId) || -1;
        if (kitIndex > -1) {
          return update(state, {
            agentMap: {
              [agentId]: {
                kits: {
                  [kitIndex]: {$set: data},
                }
              }
            }
          })
        }
      }
      return state;
    }

    case types.AGENT_ADD_KIT_REQUEST.SUCCESS: {
      const { data, agentId } = payload;
      const agent = state.agentMap[agentId];
      if (agent) {
        const clonedAgent = agent.clone();
        if (clonedAgent.kits) {
          clonedAgent.kits.push(data);
        } else {
          clonedAgent.kits = [data];
        }
        return update(state, {
          agentMap: {
            [agentId]: {$set: clonedAgent},
          }
        });
      }
      return state;
    }

    case appTypes.APP_ADD_AGENT_REQUEST.SUCCESS: {
      const { data } = payload;
      return update(state, {
        agentMap: {
          [data.id]: {$set: data},
        }
      })
    }
    case appTypes.FETCH_APP_REQUEST.SUCCESS: {
      const app = payload.data as App;
      if (app.agents) {
        const map = toMap(app.agents);
        return update(state, {
          agentMap: {$merge: map},
        });
      }
      return state;
    }

    case types.DELETE_AGENT_REQUEST.START: {
      const { id } = payload;
      const existingIndex = state.agentIds.findIndex(aId => aId === id);
      if (existingIndex > -1) {
        return update(state, {
          agentIds: { $splice: [[existingIndex, 1]] },
        });
      }
      return state;
    }

    case orgTypes.SET_CURRENT_ORGANIZATION:
    case RESET:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};


export default reducer;
