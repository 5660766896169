import clsx from "clsx";
import { Link } from "react-router-dom";
import styles from './AgentListItem.module.css';
import { Agent } from "~/store/models";

type Props = {
  agent: Agent,
  isActive?: boolean,
  onClick?: () => void,
  showStatus?: boolean,
  showOrganization?: boolean,
}

const AgentListItem: React.FC<Props> = ({ agent, isActive, onClick }) => {
  return (
    <Link to={onClick ? '' : `/agents/${agent.id}`} onClick={onClick} className={clsx(styles.item, { [styles.active]: isActive })}>
      <div className={styles.avatar}>
        <img src={agent.icon} />
      </div>
      <div className="flex-1 ml-2">
        <div className={styles.title}>{agent.getDisplayName()}</div>
        <div className={styles.subtitle}>{agent.description}</div>
      </div>
    </Link>
  )
}

export default AgentListItem;
