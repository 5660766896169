import update from 'immutability-helper';
import { RESET , Action } from '@hubblai/hubbl-ui/store/types.js';
import * as types from './types';
import * as agentTypes from '../agents/types';
import * as appTypes from '../apps/types';
import * as orgTypes from '../organizations/types';
import { Agent, App, Kit } from '../models';
import { toMap } from '~/lib/object';

export type KitsState = {
  isCreating: boolean,
  isUpdating: boolean,
  isFetchingAll: boolean,
  isFetchingOne: boolean,
  kitMap: {
    [id: string]: Kit
  },
  kitIds: string[],
}

const INITIAL_STATE: KitsState = {
  isCreating: false,
  isUpdating: false,
  isFetchingAll: false,
  isFetchingOne: false,
  kitMap: {},
  kitIds: [],
}

const reducer = (state = { ...INITIAL_STATE }, { type, payload }: Action) => {
  switch (type) {

    case types.FETCH_KITS_REQUEST.START: {
      return update(state, {
        isFetchingAll: {$set: true},
      });
    }
    case types.FETCH_KITS_REQUEST.SUCCESS: {
      const { data } = payload;
      const kitIds = data.map((item: any) => item.id);
      const kitMap = toMap(data);
      return update(state, {
        isFetchingAll: {$set: false},
        kitMap: {$merge: kitMap},
        kitIds: {$set: kitIds},
      });
    }
    case types.FETCH_KITS_REQUEST.FAILURE: {
      return update(state, {
        isFetchingAll: {$set: false},
      });
    }

    case types.FETCH_KIT_REQUEST.START: {
      return update(state, {
        isFetchingOne: {$set: true},
      });
    }
    case types.FETCH_KIT_REQUEST.SUCCESS: {
      const { data } = payload;
      return update(state, {
        isFetchingOne: {$set: false},
        kitMap: {$merge: {
          [data.id]: data,
        }}
      });
    }
    case types.FETCH_KIT_REQUEST.FAILURE: {
      return update(state, {
        isFetchingOne: {$set: false},
      });
    }

    case types.CREATE_KIT_REQUEST.START: {
      return update(state, {
        isCreating: {$set: true},
      });
    }
    case types.CREATE_KIT_REQUEST.SUCCESS: {
      const { data } = payload;
      return update(state, {
        isCreating: {$set: false},
        kitIds: {$unshift: [data.id]},
        kitMap: {$merge: {
          [data.id]: data,
        }}
      });
    }
    case types.CREATE_KIT_REQUEST.FAILURE: {
      return update(state, {
        isCreating: {$set: false},
      });
    }

    case types.KIT_ADD_FUNCTION_REQUEST.START:
    case types.KIT_REMOVE_FUNCTION_REQUEST.START:
    case types.UPDATE_KIT_REQUEST.START: {
      return update(state, {
        isUpdating: {$set: true}
      });
    }

    case types.KIT_ADD_FUNCTION_REQUEST.SUCCESS: {
      const { data, kitId } = payload;
      const kit = state.kitMap[kitId];
      if (kit) {
        const clonedKit = kit.clone();
        if (clonedKit.functions) {
          clonedKit.functions.push(data);
        } else {
          clonedKit.functions = [data];
        }
        return update(state, {
          kitMap: {
            [kitId]: {$set: clonedKit},
          }
        });
      }
      return state;
    }

    case types.KIT_REMOVE_FUNCTION_REQUEST.SUCCESS: {
      const { kitId, functionId } = payload;
      const kit = state.kitMap[kitId];
      if (kit && kit.functions) {
        const fnIndex = kit.functions.findIndex(fn => fn.id === functionId);
        if (fnIndex > -1) {
          const clonedKit = kit.clone();
          clonedKit.functions!.splice(fnIndex, 1);
          return update(state, {
            kitMap: {
              [kitId]: {$set: clonedKit},
            }
          });
        }
      }
      return state;
    }

    case appTypes.APP_ADD_AGENT_REQUEST.SUCCESS: {
      const agent = payload.data as Agent;
      if (agent.kits) {
        const map = toMap(agent.kits);
        return update(state, {
          kitMap: {$merge: map},
        })
      }
      return state;
    }
    case appTypes.FETCH_APP_REQUEST.SUCCESS: {
      const app = payload.data as App;
      if (app.agents) {

        const kits = app.agents.reduce((acc: Kit[], agent: Agent) => {
          if (agent.kits) {
            for (const kit of agent.kits) {
              acc.push(kit);
            }
          }
          return acc;
        }, []);
        const map = toMap(kits);
        return update(state, {
          kitMap: {$merge: map},
        });
      }
      return state;
    }

    case agentTypes.AGENT_ADD_KIT_REQUEST.SUCCESS: {
      const { data } = payload;
      return update(state, {
        kitMap: {
          [data.id]: {$set: data}
        }
      });
    }
    case agentTypes.FETCH_AGENT_REQUEST.SUCCESS: {
      const agent = payload.data as Agent;
      if (agent.kits) {
        const map = toMap(agent.kits);
        return update(state, {
          kitMap: {$merge: map},
        });
      }
      return state;
    }


    case types.UPDATE_KIT_REQUEST.SUCCESS: {
      const { data } = payload;
      return update(state, {
        isUpdating: {$set: false},
        kitMap: {$merge: {
          [data.id]: data,
        }}
      });
    }

    case types.KIT_ADD_FUNCTION_REQUEST.FAILURE:
    case types.UPDATE_KIT_REQUEST.FAILURE: {
      return update(state, {
        isUpdating: {$set: false}
      });
    }

    case types.DELETE_KIT_REQUEST.START: {
      const { id } = payload;
      const existingIndex = state.kitIds.findIndex(kId => kId === id);
      if (existingIndex > -1) {
        return update(state, {
          kitIds: { $splice: [[existingIndex, 1]] },
        });
      }
      return state;
    }

    case orgTypes.SET_CURRENT_ORGANIZATION:
    case RESET:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};


export default reducer;
