import { Button, DropdownMenuItem, Input, RadioGroup, Spinner, confirm } from "@hubblai/hubbl-ui/components/index.js";
import { FormErrors } from "@hubblai/hubbl-ui/components/types.js";
import { useCallback, useEffect, useState } from "react";
import { useIsUpdating, useKit } from "~/store/kits/hooks"
import { KIT_VIEW_LEVEL } from "~/store/models";
import PanelFooter from "../Components/PanelFooter";
import PanelHeader from "../Components/PanelHeader";
import { useAppDispatch } from "@hubblai/hubbl-ui/store/index.js";
import { deleteKit, updateKit, updateKitIcon } from "~/store/kits/actions";
import { addToast } from "@hubblai/hubbl-ui/store/notifications/actions.js";
import { useCurrentOrganizationId } from "~/store/organizations/hooks";
import { getDisplayError } from "@hubblai/hubbl-ui/lib/api.js";
import { useNavigate } from "react-router-dom";
import { TabPanel, TabView } from "primereact/tabview";
import { Parameter, ParameterGroups } from "@hubblai/hubbl-core/lib/parameters.js";
import ConfigParametersList from "~/components/ConfigParametersList";
import ParameterModal from "~/components/ParameterModal";
import { deepClone, getDiff, isEmpty } from "@hubblai/hubbl-core/lib/object.js";
import PanelBody from "../Components/PanelBody";
import { isString } from "@hubblai/hubbl-core/lib/string.js";
import AdvancedInput, { Language, LanguageValue, SimpleCode, isSimpleCode }  from "~/components/AdvancedInput";
import ImageChangeModal from "~/components/ImageChangeModal/ImageChangeModal";
import { JS, TEMPLATE_NAME } from "@hubblai/hubbl-core/lib/code.js";

type Props = {
  id: string;
}

type ParameterIndex = {
  group: number,
  index: number,
}

const DEFAULT_PARAMETER_GROUPS: ParameterGroups = [{
  parameters: [],
}];

const VIEW_LEVELS = [
  { label: "Private", value: KIT_VIEW_LEVEL.PRIVATE, description: "Only users in your organization will be able to add your Kit to their Agents" },
  { label: "Public", value: KIT_VIEW_LEVEL.PUBLIC, description: "Anybody will be able to add your Kit to their Agents" },
];

const KitEditPanel: React.FC<Props> = ({ id }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const kit = useKit(id);
  const [errors, setErrors] = useState<FormErrors>({});
  const isUpdating = useIsUpdating();
  const organizationId = useCurrentOrganizationId();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [viewLevel, setViewLevel] = useState(KIT_VIEW_LEVEL.PRIVATE);

  const [instructions, setInstructions] = useState('');
  const [instructionsCode, setInstructionsCode] = useState<SimpleCode | undefined>();

  const [prompt, setPrompt] = useState('');
  const [promptCode, setPromptCode] = useState<SimpleCode | undefined>();

  const [configParameters, setConfigParameters] = useState<ParameterGroups>([...DEFAULT_PARAMETER_GROUPS]);
  const [currentParameter, setCurrentParameter] = useState<Parameter | undefined>(undefined);
  const [currentParameterIndex, setCurrentParameterIndex] = useState<ParameterIndex>({ group: 0, index: -1 });

  const [isImageModalOpened, setIsImageModalOpened] = useState(false);

  useEffect(() => {
    if (kit) {
      setTitle(kit.title);
      setDescription(kit.description);
      setViewLevel(kit.view_level);
      setInstructions(kit.instructions);
      if (kit.instructions_code) {
        setInstructionsCode({
          content: kit.instructions_code.content,
          dependencies: kit.instructions_code.dependencies
        });
      }
      setPrompt(kit.prompt);
      if (kit.prompt_code) {
        setInstructionsCode({
          content: kit.prompt_code.content,
          dependencies: kit.prompt_code.dependencies
        });
      }
      if (kit.config_parameters && kit.config_parameters.length > 0) {
        setConfigParameters(kit.config_parameters);
      }
    }
  }, [kit]);

  const onChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value);
  const onChangeDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => setDescription(e.target.value);

  const onViewLevelChanged = useCallback((viewLevel: number) => setViewLevel(viewLevel), []);

  const getData = () => {
    return {
      title,
      description,
      prompt,
      instructions,
      view_level: viewLevel,
      instructions_code: instructionsCode || null,
      prompt_code: promptCode || null,
      config_parameters: configParameters,
    };
  }

  const getChangedData = () => {
    return getDiff(kit!, getData());
  }

  const validate = () => {
    const errors: FormErrors = {};
    if (title.length === 0) {
      errors.title = "Title is required";
    }
    if (description.length === 0) {
      errors.description = "Description is required";
    }
    // if (instructions.length === 0 && instructionsCode.length === 0) {
    //   errors.instructions = "Instructions are required";
    // }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  }

  const onClickSave = async () => {
    if (validate()) {
      const data: any = getChangedData();
      try {
        if (!isEmpty(data)) {
          await dispatch(updateKit(organizationId!, kit!.id, data));
        }
        dispatch(addToast('Nice!', `Your changes were saved!`, 'success'));
      }
      catch (err) {
        dispatch(addToast('Oops!', `Something went wrong: ${getDisplayError(err)}`));
      }
    }
  }

  if (!kit) {
    return <Spinner />;
  }

  const onClickDeleteConfirmed = async () => {
    try {
      await dispatch(deleteKit(organizationId!, id));
      navigate('/kits');
    }
    catch (err) {
      dispatch(addToast('Oops', `Error removing kit: ${getDisplayError(err)}`, 'danger'));
    }
  };

  const buttons: DropdownMenuItem[] = [{
    label: 'Change Image',
    onClick: () => setIsImageModalOpened(true),
  }, {
    label: '-'
  }, {
    label: 'Remove',
    onClick: () => {
      confirm({
        message: <div>Are you sure you want to remove this kit? </div>,
        header: 'Remove Kit',
        icon: 'pi pi-exclamation-triangle',
        accept: onClickDeleteConfirmed,
      })
    },
  }];

  const onPromptChanged = (language: Language, value: LanguageValue) => {
    if (language === 'markdown' && isString(value)) {
      setPrompt(value);
      setPromptCode(undefined);
    } else if (language === 'js' && isSimpleCode(value)) {
      setPromptCode(value);
    }
  }

  // Removing instructions as even though it might be useful for openAI stuff not to have to update assistant, its confusing for users
  // const onInstructionsChanged = (language: Language, value: LanguageValue) => {
  //   if (language === 'markdown' && isString(value)) {
  //     setInstructions(value);
  //     setInstructionsCode(undefined);
  //   } else if (language === 'js' && isSimpleCode(value)) {
  //     setInstructionsCode(value);
  //   }
  // }

  const onParameterModalClosed = () => {
    setCurrentParameter(undefined);
    setCurrentParameterIndex({ group: 0, index: -1 });
  }

  const onClickParameter = (parameter: Parameter, groupIndex: number, index: number) => {
    setCurrentParameter({...parameter});
    setCurrentParameterIndex({ group: groupIndex, index });
  }

  const onClickAddParameter = () => {
    setCurrentParameterIndex({ group: 0, index: -1 });
    setCurrentParameter({
      name: '',
      display_name: '',
      type: 'string',
      description: '',
      validations: [],
    });
  }

  const onParameterSaved = () => {
    setConfigParameters(groups => {
      const newGroups = deepClone(groups);
      const group = newGroups[currentParameterIndex.group];
      if (currentParameter) {
        if (currentParameterIndex.index === -1) {
          group.parameters.push(currentParameter);
        } else {
          group.parameters[currentParameterIndex.index] = {...currentParameter};
        }
      }
      return newGroups;
    });
    setCurrentParameter(undefined);
    setCurrentParameterIndex({ group: 0, index: -1 });
  }

  const onParameterChanged = (key: string, value: any) => {
    setCurrentParameter(currentValue => {
      if (currentValue) {
        return {
          ...currentValue,
          [key]: value,
        }
      }
      return undefined;
    });
  }

  const onClickDeleteParameter = (groupIndex: number, index: number) => {
    setConfigParameters(groups => {
      const newGroups = deepClone(groups) as ParameterGroups;
      const group = newGroups[groupIndex];
      group.parameters = group.parameters.filter((_, i) => i !== index)
      return newGroups;
    });
  }

  const onImageModalClosed = () => setIsImageModalOpened(false);

  const onImageChanged = async (file?: File, imageUrl?: string) => {
    try {
      if (file) {
        await dispatch(updateKitIcon(organizationId!, kit!.id, file));
      } else if (imageUrl) {
        await dispatch(updateKit(organizationId!, kit!.id, {
          icon: imageUrl,
        }));
      }
      dispatch(addToast('Nice!', `The image was changed!`, 'success'));
    }
    catch (err) {
      dispatch(addToast('Oops!', `Something went wrong: ${getDisplayError(err)}`));
    }
  }

  return (
    <div className="flex flex-col flex-1">

      <PanelHeader buttons={buttons} title={kit.title} icon={kit.icon} />

      <PanelBody>
        <TabView panelContainerClassName="px-0">
          <TabPanel header="General">
            <Input value={title} onChange={onChangeTitle} label="Title" className="mb-3" error={errors.title} />
            <Input value={description} onChange={onChangeDescription} label="Description" multiline className="mb-3" error={errors.description} />

            <RadioGroup label="Privacy" value={viewLevel} items={VIEW_LEVELS} onChange={onViewLevelChanged} />
          </TabPanel>
          <TabPanel header="Prompt">
            <AdvancedInput
              label=""
              className="mb-3"
              defaultValues={{
                'js': JS.getTemplate(TEMPLATE_NAME.PROMPT),
              }}
              values={{
                'markdown': prompt,
                'js': promptCode,
              }}
              onChange={onPromptChanged}
              error={errors.prompt}
            />
          </TabPanel>
          {/* <TabPanel header="Instructions">
            <AdvancedInput
              label=""
              className="mb-3"
              defaultValues={{
                'js': JS.getTemplate(TEMPLATE_NAME.INSTRUCTIONS),
              }}
              values={{
                'markdown': instructions,
                'js': instructionsCode,
              }}
              onChange={onInstructionsChanged}
              error={errors.instructions}
            />
          </TabPanel> */}
          <TabPanel header="Parameters">
            <ConfigParametersList groups={configParameters} onClick={onClickParameter} onClickDelete={onClickDeleteParameter} />
            <Button icon='plus' title="Add Parameter" onClick={onClickAddParameter} size="sm" className="w-full" outline />
            <p className="my-3">List of parameters that can be configured when {kit.title} is added to an agent.</p>
            <p className="mb-3">Parameters are then passed down to Functions as a <code className="inline-code">parameters</code> argument.</p>
          </TabPanel>
        </TabView>
      </PanelBody>

      <PanelFooter>
        <div></div>
        <Button variant="success" title="Save Changes" size="sm" icon="save" onClick={onClickSave} isLoading={isUpdating} />
      </PanelFooter>
      {currentParameter && <ParameterModal parameter={currentParameter} index={currentParameterIndex.index} onClose={onParameterModalClosed} onSave={onParameterSaved} onChange={onParameterChanged} />}
      <ImageChangeModal isOpened={isImageModalOpened} onClose={onImageModalClosed} onChange={onImageChanged} />
    </div>
  )
}

export default KitEditPanel;
