import React, { useCallback, useImperativeHandle, useRef } from "react";
import { Input, Label } from "@hubblai/hubbl-ui/components/index.js";
import { ComponentProps, QuickButton } from "@hubblai/hubbl-ui/components/types.js";
import { useState } from "react";
import Code from "../Code/Code";
import { JS } from "@hubblai/hubbl-core/lib/code.js";
import JSONEditor from "../Code/JSONEditor";
import MarkdownInput from "../MarkdownInput";
import { AdvancedInputItemRef } from "../types";

export type Language = 'plain' | 'markdown' | 'js' | 'json';

export type LanguageValue = string | SimpleCode;

export const isSimpleCode = (o: any): o is SimpleCode => {
  return o && o.content;
}

export type SimpleCode = {
  content: string,
  dependencies: any,
}

type Props = {
  id?: string,
  label?: string,
  buttons?: QuickButton[],
  sublabel?: string,
  error?: string,
  defaultValues?: {
    'plain'?: string,
    'markdown'?: string,
    'js'?: string,
    'json'?: string,
  },
  values: {
    'plain'?: string,
    'markdown'?: string,
    'js'?: SimpleCode,
    'json'?: string,
  },
  language?: Language,
  isReadOnly?: boolean,
  onChange?: (language: Language, value: LanguageValue) => void,
} & ComponentProps;

const languageToToggleLabel: {[language: string]: string } = {
  'plain': 'Use Text',
  'markdown': 'Use Text',
  'js': 'Use Code',
  'json': 'Use JSON',
};

const AdvancedInput = React.forwardRef<AdvancedInputItemRef, Props>(({ id, values, isReadOnly, defaultValues, onChange, language, error, label, sublabel, buttons, className }, ref) => {
  const plainInputRef = useRef<HTMLInputElement>();
  const markdownInputRef = useRef<AdvancedInputItemRef | null>(null);
  const languages = Object.keys(values);
  const [languageIndex, setLanguageIndex] = useState(language ? languages.findIndex(l => l === language) : 0);

  const canToggle = languages.length > 1;
  const currentLanguage = languages[languageIndex];
  const value = (values as any)[currentLanguage];
  const defaultValue = defaultValues ? (defaultValues as any)[currentLanguage] || '' : '';

  const onClickLanguageToggle = () => {
    setLanguageIndex(index => {
      if (index < languages.length - 1) {
        return index + 1;
      }
      return 0;
    });
  }

  const labelButtons: QuickButton[] = buttons || [];
  if (canToggle) {
    labelButtons.push({
      label: languageToToggleLabel[languageIndex < languages.length - 1 ? languages[languageIndex + 1] : languages[0]],
      onClick: onClickLanguageToggle,
    });
  }

  const onPlainChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange('plain', e.target.value);
    }
  }

  const onMarkdownChanged = (val: string) => {
    if (onChange) {
      onChange('markdown', val);
    }
  }

  const onCodeChanged = (val: string) => {
    if (onChange) {
      onChange('js', {
        content: val,
        dependencies: JS.parseDependencies(val)
      });
    }
  }

  const onJSONChanged = (val: string) => {
    if (onChange) {
      onChange('json',  val);
    }
  }

  const focus = useCallback(() => {
    plainInputRef?.current?.focus();
    markdownInputRef?.current?.focus();
  }, []);

  const forceContent = useCallback((content: string) => {
    markdownInputRef?.current?.forceContent(content);
  }, []);

  useImperativeHandle(ref, () => {
    return {
      focus,
      forceContent,
    }
  }, [focus, forceContent]);

  return (
    <div className={className}>
      <Label title={label} buttons={labelButtons} />
      {currentLanguage === 'plain' &&
        <Input
          ref={plainInputRef}
          id={id}
          className="mb-3 flex-1"
          value={value || defaultValue}
          onChange={onPlainChange}
          error={error}
          multiline
          isReadOnly={isReadOnly}
        />
      }
      {currentLanguage === 'js' &&
        <Code value={value?.content || defaultValue} onChange={onCodeChanged} dependencies={value?.dependencies || {}} error={error} />
      }
      {currentLanguage === 'markdown' &&
        <MarkdownInput isReadOnly={isReadOnly} ref={markdownInputRef} value={value || defaultValue} onChange={onMarkdownChanged} />
      }
      {currentLanguage === 'json' &&
        <JSONEditor value={value || defaultValue} onChange={onJSONChanged} />
      }
      {sublabel && <small id={`${id}-help`}>{sublabel}</small>}
    </div>
  )
});

export default AdvancedInput;
