import { Argument } from "@hubblai/hubbl-core/lib/code.js";
import ArgumentListItem from "../ArgumentListitem";

type ArgumentsListProps = {
  args: Argument[],
  onClick: (arg: Argument, index: number) => void,
  onClickDelete: (index: number) => void,
}

const ArgumentsList: React.FC<ArgumentsListProps> = ({ args, onClick, onClickDelete }) => {
  return (
    <div>
      {args.map((arg, index) =>
        <ArgumentListItem
          key={arg.name}
          arg={arg}
          onClick={() => onClick(arg, index)}
          onClickDelete={() => onClickDelete(index)}
        />
      )}
    </div>
  )
}

export default ArgumentsList;
