import { Argument, ValidationType } from "@hubblai/hubbl-core/lib/code.js";
import { Parameter } from "@hubblai/hubbl-core/lib/parameters.js";
import { Checkbox, Label, Select, SelectChangeEvent } from "@hubblai/hubbl-ui/components/index.js";
import { ComponentProps } from "@hubblai/hubbl-ui/components/types.js";
import AllowedValues from "./components/AllowedValues";

type CommonProps = {
  label?: string,
  argument?: Argument,
  parameter?: Parameter,
  onChange: (key: string, value: any) => void,
} & ComponentProps;

type ArgumentProps = {
  argument: Argument
} & CommonProps;

type ParameterProps = {
  parameter: Parameter,
} & CommonProps;

const SPECIAL_TYPES = [{
  type: null,
  name: 'None',
}, {
  type: 'url',
  name: 'URL',
}, {
  type: 'email',
  name: 'Email',
}, {
  type: 'oneOf',
  name: 'Allowed List',
}];

const SPECIAL_VALIDATION_TYPES = ['email', 'url', 'oneOf'];

const ValidationForm: React.FC<ArgumentProps | ParameterProps> = ({ className, label, parameter, argument, onChange }) => {

  const getValidations = () => {
    if (argument?.validations) {
      return argument?.validations;
    }
    if (parameter?.validations) {
      return parameter?.validations;
    }
    return [];
  }

  const validations = getValidations();
  const isRequired = validations.findIndex(v => v.type === 'required') > -1;
  const isOneOf = validations.findIndex(v => v.type === 'oneOf') > -1;
  const specialValidationType = validations.find(v => SPECIAL_VALIDATION_TYPES.includes(v.type))?.type;

  const onRequiredChanged = (e: any) => {
    const newValidations = [...validations];
    if (e.target.value) {
      newValidations.push({ type: 'required' });
      onChange('validations', newValidations);
    } else {
      onChange('validations', newValidations.filter(v => v.type !== 'required'));
    }
  }

  const onSpecialChanged = (e: SelectChangeEvent) => {
    const newValidations = [...validations].filter(v => !SPECIAL_VALIDATION_TYPES.includes(v.type));
    const type = e.target.value as ValidationType;
    if (type !== null) {
      newValidations.push({ type });
    }
    if (type === 'oneOf') {
      onChange('values', undefined);
    }
    onChange('validations', newValidations);
  }

  const isString = parameter?.type === 'string' || argument?.type === 'string';

  return (
    <div className={className}>
      <Label title={label} size="lg" />
      <Checkbox title={"Required"} titlePosition="right" sublabel="Required to be provided" value={isRequired} onChange={onRequiredChanged} className="mb-3" />

      {isString &&
        <>
          <Select label="Value Type" value={specialValidationType} placeholder="None" onChange={onSpecialChanged} items={SPECIAL_TYPES} itemFieldValue='type' itemFieldLabel='name' className="mb-3" />
          {isOneOf && <AllowedValues parameter={parameter} argument={argument} onChange={onChange} />}
        </>
      }
    </div>
  )
}

export default ValidationForm;
