export const FETCH_FUNCTIONS_REQUEST = {
  START: "FETCH_FUNCTIONS_REQUEST.START",
  SUCCESS: "FETCH_FUNCTIONS_REQUEST.SUCCESS",
  FAILURE: "FETCH_FUNCTIONS_REQUEST.FAILURE",
}

export const FETCH_FUNCTION_REQUEST = {
  START: "FETCH_FUNCTION_REQUEST.START",
  SUCCESS: "FETCH_FUNCTION_REQUEST.SUCCESS",
  FAILURE: "FETCH_FUNCTION_REQUEST.FAILURE",
}

export const CREATE_FUNCTION_REQUEST = {
  START: "CREATE_FUNCTION_REQUEST.START",
  SUCCESS: "CREATE_FUNCTION_REQUEST.SUCCESS",
  FAILURE: "CREATE_FUNCTION_REQUEST.FAILURE",
}

export const UPDATE_FUNCTION_REQUEST = {
  START: "UPDATE_FUNCTION_REQUEST.START",
  SUCCESS: "UPDATE_FUNCTION_REQUEST.SUCCESS",
  FAILURE: "UPDATE_FUNCTION_REQUEST.FAILURE",
}

export const DELETE_FUNCTION_REQUEST = {
  START: "DELETE_FUNCTION_REQUEST.START",
  SUCCESS: "DELETE_FUNCTION_REQUEST.SUCCESS",
  FAILURE: "DELETE_FUNCTION_REQUEST.FAILURE",
}

export const CREATE_DRAFT_FUNCTION_REQUEST = {
  START: "CREATE_DRAFT_FUNCTION_REQUEST.START",
  SUCCESS: "CREATE_DRAFT_FUNCTION_REQUEST.SUCCESS",
  FAILURE: "CREATE_DRAFT_FUNCTION_REQUEST.FAILURE",
}
