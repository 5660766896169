import { useState } from "react";
import clsx from 'clsx';
import { Panel, useOnSelectionChange,  Node, useStoreApi } from "reactflow";
import KitEditPanel from "./KitEditPanel";
import { Button } from "@hubblai/hubbl-ui/components/index.js";
import AddFunctionPanel from "./AddFunctionPanel";
import FunctionConfigPanel from "./FunctionConfigPanel";
import KitConfigPanel from "./KitConfigPanel";
import AddKitPanel from "./AddKitPanel";
import AgentEditPanel from "./AgentEditPanel";
import { PANEL_SIZE, getPreference, setPreference } from "~/lib/preferences";
import AddAgentPanel from "./AddAgentPanel";
import AgentConfigPanel from "./AgentConfigPanel";
import AppEditPanel from "./AppEditPanel";
// import { useCurrentOrganizationId } from "~/store/organizations/hooks";
import { CanvasNode } from "~/lib/canvas";
import { isNil } from "@hubblai/hubbl-core/lib/object.js";
import FunctionEditPanel from "./FunctionEditPanel";

const SUPPORTED_PANELS: {[name: string]: boolean} = {
  kit: true,
  'add-kit': true,
  agent: true,
  'add-agent': true,
  function: true,
  'add-function': true,
  app: true,
}

const DetailPanel = () => {
  const store = useStoreApi();
  const [selectedNode, setSelectedNode] = useState<Node | undefined>(undefined);
  const [panelSize, setPanelSize] = useState<PANEL_SIZE>(getPreference('panel_size', PANEL_SIZE.MINIMUM));
  const [isEditing, setIsEditing] = useState(false);
  // const _organizationId = useCurrentOrganizationId();

  const selectNode = (node?: Node) => {
    if (node) {
      window.location.href = '#' + node.id;
      setSelectedNode(node);
    } else {
      window.location.href = '#';
      setSelectedNode(undefined);
    }
  }

  useOnSelectionChange({
    onChange: ({ nodes }) => {
      selectNode(nodes[0]);
      setIsEditing(false);
    },
  });

  const onClickClose = () => {
    store.getState().resetSelectedElements();
  }

  const onNodeSelect = (nodeId?: string) => {
    if (nodeId) {
      const nodes = store.getState().getNodes();
      const node = nodes.find(n => n.id === nodeId);
      if (node) {
        selectNode(node);
      }
    } else {
      selectNode(undefined);
    }
  }

  if (!selectedNode || !SUPPORTED_PANELS[selectedNode.type!]) {
    return <></>;
  }

  const data = selectedNode.data as CanvasNode;

  const changePanelSize = (newPanelSize: PANEL_SIZE) => {
    setPanelSize(newPanelSize);
    setPreference('panel_size', newPanelSize);
  }

  const onClickExpand = () => {
    if (panelSize === PANEL_SIZE.MINIMUM) {
      changePanelSize(PANEL_SIZE.EXPANDED);
    } else if (panelSize === PANEL_SIZE.EXPANDED) {
      changePanelSize(PANEL_SIZE.FULL);
    }
  }

  const onClickCollapse = () => {
    if (panelSize === PANEL_SIZE.EXPANDED) {
      changePanelSize(PANEL_SIZE.MINIMUM);
    } else if (panelSize === PANEL_SIZE.FULL) {
      changePanelSize(PANEL_SIZE.EXPANDED);
    }
  }

  const onClickToggleEdit = () => {
    setIsEditing(currentValue => !currentValue);
  }

  const isConfigurable = !isNil(data.parentId);

  return (
    <Panel position="top-right" className={clsx('bg-white shadow-md rounded-md bottom-5 top-5 flex right-0', {
      'w-128': panelSize === PANEL_SIZE.MINIMUM,
      'w-3/4': panelSize === PANEL_SIZE.EXPANDED,
      'left-20 top-0 right-0 bottom-0 absolute': panelSize === PANEL_SIZE.FULL,
    })}>
      <div className="absolute -left-16 top-0 flex flex-col">
        <Button icon='close' onClick={onClickClose} className="bg-white rounded-full px-1 py-2 shadow-md" variant="link" size='icon' tooltip="Close" />
        {data.isEditable && isConfigurable && <Button icon={isEditing ? 'settings' : 'edit'} onClick={onClickToggleEdit} className="bg-white rounded-full px-1 py-2 shadow-md mt-2" variant="link" size='icon' tooltip={isEditing ? 'Configure' : 'Edit'} />}
        {panelSize !== PANEL_SIZE.FULL && <Button icon={'expandH'} onClick={onClickExpand} className="bg-white rounded-full px-1 py-2 shadow-md mt-2" variant="link" size='icon' tooltip="Expand" />}
        {panelSize !== PANEL_SIZE.MINIMUM && <Button icon={'collapseH'} onClick={onClickCollapse} className="bg-white rounded-full px-1 py-2 shadow-md mt-2" variant="link" size='icon' tooltip="Collapse" />}
      </div>
      <div className="overflow-hidden flex flex-1 p-3">

        {data.type === 'app' && (data.viewType === 'edit' || isEditing) && <AppEditPanel id={data.id} />}
        {data.type === 'kit' && (data.viewType === 'edit' || isEditing)  && <KitEditPanel id={data.id} />}
        {data.type === 'kit' && data.viewType === 'config' && !isEditing && <KitConfigPanel agentId={data.parentId!} kitId={data.id} selectNode={onNodeSelect} />}
        {data.type === 'agent' && (data.viewType === 'edit' || isEditing) && <AgentEditPanel id={data.id} />}
        {data.type === 'agent' && data.viewType === 'config' && !isEditing && <AgentConfigPanel appId={data.parentId!} agentId={data.id} selectNode={onNodeSelect} />}
        {data.type === 'function' && (data.viewType === 'edit' || isEditing) && <FunctionEditPanel id={data.id} />}
        {data.type === 'function' && data.viewType === 'config' && !isEditing && <FunctionConfigPanel kitId={data.parentId!} functionId={data.id} selectNode={onNodeSelect} />}

        {data.type === 'add-function' && <AddFunctionPanel kitId={data.parentId!} selectNode={onNodeSelect} />}
        {data.type === 'add-kit' && <AddKitPanel agentId={data.parentId!} selectNode={onNodeSelect} />}
        {data.type === 'add-agent' && <AddAgentPanel appId={data.parentId!} selectNode={onNodeSelect} />}
      </div>
    </Panel>
  );
}

export default DetailPanel;
