import { Button, DropdownMenuItem, Input, Label, RadioGroup, Spinner, confirm, Select, Checkbox, Tag, Icon } from "@hubblai/hubbl-ui/components/index.js";
import { FormErrors, QuickButton } from "@hubblai/hubbl-ui/components/types.js";
import { useCallback, useEffect, useRef, useState } from "react";
import { useIsUpdating, useAgent } from "~/store/agents/hooks"
import PanelFooter from "../Components/PanelFooter";
import PanelHeader from "../Components/PanelHeader";
import { useAppDispatch } from "@hubblai/hubbl-ui/store/index.js";
import { addToast } from "@hubblai/hubbl-ui/store/notifications/actions.js";
import { useCurrentOrganizationId } from "~/store/organizations/hooks";
import { getDisplayError } from "@hubblai/hubbl-ui/lib/api.js";
import { useNavigate } from "react-router-dom";
import { getDiff, isEmpty } from "@hubblai/hubbl-core/lib/object.js";
import PanelBody from "../Components/PanelBody";
import { deleteAgent, updateAgent, updateAgentIcon } from "~/store/agents/actions";
import { TabPanel, TabView } from "primereact/tabview";
import { AGENT_VIEW_LEVEL } from "@hubblai/hubbl-core/models/Agent.js";
import { SelectChangeEvent } from "@hubblai/hubbl-ui/components/Select/Select.js";
import { useModels, useProviderModels, useProviders } from "~/store/models/hooks";
import PromptsModal from "~/components/PromptsModal";
import clsx from "clsx";
import { ArgumentValues } from "@hubblai/hubbl-core/lib/code.js";
import ParametersForm, { ParametersFormRef } from "~/components/ParametersForm";
import AdvancedInput, { Language, LanguageValue } from "~/components/AdvancedInput";
import { isString } from "@hubblai/hubbl-core/lib/string.js";
import { AdvancedInputItemRef } from "~/components/types";
import ImageChangeModal from "~/components/ImageChangeModal/ImageChangeModal";
import GeneratePromptModal from "~/components/GeneratePromptModal";

type Props = {
  id: string;
}

const VIEW_LEVELS = [
  { label: "Private", value: AGENT_VIEW_LEVEL.PRIVATE, description: "Only users with a link will be able to talk to your Agent" },
  { label: "Public", value: AGENT_VIEW_LEVEL.PUBLIC, description: "Anybody will be able to talk to your Agent" },
];

const AgentEditPanel: React.FC<Props> = ({ id }) => {
  const promptRef = useRef<AdvancedInputItemRef | null>(null);
  const parametersFormRef = useRef<ParametersFormRef | null>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const agent = useAgent(id);
  const [errors, setErrors] = useState<FormErrors>({});
  const isUpdating = useIsUpdating();
  const organizationId = useCurrentOrganizationId();
  const models = useModels();
  const providers = useProviders();

  const [displayName, setDisplayName] = useState('');
  const [description, setDescription] = useState('');
  const [viewLevel, setViewLevel] = useState(AGENT_VIEW_LEVEL.PRIVATE);

  const [modelId, setModelId] = useState<number | null>(null);
  const [provider, setProvider] = useState<string>();
  const [useCustomModel, setUseCustomModel] = useState(false);
  const [customModelName, setCustomModelName] = useState('');
  const [prompt, setPrompt] = useState('');
  const [initialParametersValues, setInitialParametersValues] = useState<ArgumentValues>({});

  const [isExpanded, setIsExpanded] = useState(false);
  const [isPromptModalOpened, setIsPromptModalOpened] = useState(false);
  const [isGeneratePromptModalOpened, setIsGeneratePromptModalOpened] = useState(false);
  const [isImageModalOpened, setIsImageModalOpened] = useState(false);

  const providerModels = useProviderModels(provider || '');
  const currentModel = models.find(model => model.id === modelId);

  useEffect(() => {
    if (agent && models) {
      setDisplayName(agent.display_name);
      setDescription(agent.description || '');
      setViewLevel(agent.view_level);
      setPrompt(agent.prompt || '');
      setInitialParametersValues(agent.parameters || '');
      if (agent.model_name) {
        setUseCustomModel(true);
        setCustomModelName(agent.model_name);
      }

      const provider = models.find(model => model.id === agent.model_id)?.provider;
      setProvider(provider);
      setModelId(agent.model_id);
    }
  }, [agent, models]);

  useEffect(() => {
    if (provider && providerModels.length) {
      if (!currentModel || provider !== currentModel.provider) {
        setModelId(providerModels[0].id);
      }
    }
  }, [provider, providerModels, currentModel]);

  const onChangeDisplayName = (e: React.ChangeEvent<HTMLInputElement>) => setDisplayName(e.target.value);
  const onChangeDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => setDescription(e.target.value);
  const onViewLevelChanged = useCallback((viewLevel: number) => setViewLevel(viewLevel), []);

  const onProviderChanged = (e: SelectChangeEvent) => setProvider(e.target.value);
  const onModelIdChanged = (e: SelectChangeEvent) => setModelId(e.target.value);
  const onUseCustomModelChanged = (e: any) => setUseCustomModel(e.target.value);
  const onCustomModelNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => setCustomModelName(e.target.value);

  const onPromptChanged = (language: Language, value: LanguageValue) => {
    if (language === 'markdown' && isString(value)) {
      setPrompt(value);
    }
  }

  const getData = () => {
    return {
      display_name: displayName,
      description,
      view_level: viewLevel,
      model_id: modelId,
      model_name: useCustomModel ? customModelName : null,
      prompt,
      parameters: parametersFormRef?.current?.getData(),
    };
  }

  const getChangedData = () => {
    return getDiff(agent!, getData());
  }

  const validate = () => {
    let errors: FormErrors = {};
    if (displayName.length === 0) {
      errors.title = "Display Name is required";
    }
    if (description.length === 0) {
      errors.description = "Description is required";
    }
    if (modelId === null) {
      errors.model_id = 'You need to select a model';
    }
    if (prompt.length === 0) {
      errors.prompt = 'You need to write your own prompt';
    }
    const parametersErrors = parametersFormRef.current?.validate();
    errors = { ...errors, ...parametersErrors};
    setErrors(errors);
    return Object.keys(errors).length === 0;
  }

  const onClickSave = async () => {
    if (validate()) {
      const data: any = getChangedData();
      try {
        if (!isEmpty(data)) {
          await dispatch(updateAgent(organizationId!, agent!.id, data));
        }
        dispatch(addToast('Nice!', `Your changes were saved!`, 'success'));
      }
      catch (err) {
        dispatch(addToast('Oops!', `Something went wrong: ${getDisplayError(err)}`));
      }
    }
  }

  if (!agent) {
    return <Spinner />;
  }

  const onClickDeleteConfirmed = async () => {
    try {
      await dispatch(deleteAgent(organizationId!, id));
      navigate('/agents');
    }
    catch (err) {
      dispatch(addToast('Oops', `Error removing agent: ${getDisplayError(err)}`, 'danger'));
    }
  };

  const buttons: DropdownMenuItem[] = [{
    label: 'Change Image',
    onClick: () => setIsImageModalOpened(true),
  }, {
    label: '-'
  }, {
    label: 'Remove',
    onClick: () => {
      confirm({
        message: <div>Are you sure you want to remove this agent? </div>,
        header: 'Remove Agent',
        icon: 'pi pi-exclamation-triangle',
        accept: onClickDeleteConfirmed,
      })
    },
  }];

  const currentModelDescription = currentModel ? currentModel.description : "Select the Large Language Model (LLM) to process the messages";

  const promptButtons: QuickButton[] = [{
    icon: 'generate',
    label: 'Generate',
    className: 'mx-2',
    onClick: () => setIsGeneratePromptModalOpened(true),
  }, {
    label: "Suggestions",
    onClick: () => {
      setIsPromptModalOpened(true);
    }
  }];

  const onClickToggleParameters = () => setIsExpanded(now => !now);

  const onPromptSelected = (prompt: string) => {
    setPrompt(prompt);
    setIsPromptModalOpened(false);
    promptRef.current?.forceContent(prompt);
  }

  const onPromptModalClosed = () => {
    setIsPromptModalOpened(false);
  }

  const onImageModalClosed = () => {
    setIsImageModalOpened(false);
  }

  const onGenerateModalClosed = () => setIsGeneratePromptModalOpened(false);

  const onImageChanged = async (file?: File, imageUrl?: string) => {
    try {
      if (file) {
        await dispatch(updateAgentIcon(organizationId!, agent!.id, file));
      } else if (imageUrl) {
        await dispatch(updateAgent(organizationId!, agent!.id, {
          icon: imageUrl,
        }));
      }
      dispatch(addToast('Nice!', `The image was changed!`, 'success'));
    }
    catch (err) {
      dispatch(addToast('Oops!', `Something went wrong: ${getDisplayError(err)}`));
    }
  }

  return (
    <div className="flex flex-col flex-1">
      <PanelHeader buttons={buttons} title={agent.getDisplayName()} icon={agent.icon} />
      <PanelBody>
        <TabView panelContainerClassName="px-0">
          <TabPanel header="General">
            <Input value={displayName} onChange={onChangeDisplayName} label="Display Name" className="mb-3" error={errors.display_name} />
            <Input value={description} onChange={onChangeDescription} label="Description" multiline className="mb-3" error={errors.description} />
            <RadioGroup label="Privacy" value={viewLevel} items={VIEW_LEVELS} onChange={onViewLevelChanged} />
          </TabPanel>
          <TabPanel header="LLM">
            <Select label="Provider" className="mb-3 w-full" items={providers} value={provider} onChange={onProviderChanged} error={errors.provider} />
            {providerModels.length > 0 &&
              <>
                <Select label="Model" className="mb-3 w-full" itemFieldLabel="display_name" itemFieldValue="id" value={modelId} onChange={onModelIdChanged} items={providerModels} sublabel={currentModelDescription} error={errors.model_id} />
                {currentModel &&
                  <>
                    {currentModel.capabilities.can_custom_model && <Checkbox value={useCustomModel} onChange={onUseCustomModelChanged} title="Use Custom Model Name" className="mb-3" sublabel={`Set your custom model name to be used with ${provider} instead of the default one (${currentModel.name})`} titlePosition="right" />}
                    {useCustomModel && <Input className="mb-3" label="Model Name" value={customModelName} onChange={onCustomModelNameChanged} error={errors.model_name} />}
                  </>
                }
              </>
            }

            <AdvancedInput
              ref={promptRef}
              className="mb-3"
              label="Persona"
              buttons={promptButtons}
              defaultValues={{
                'markdown': 'You are a helpful assistant.'
              }}
              values={{
                'markdown': prompt
              }}
              onChange={onPromptChanged}
              sublabel="The system instructions that the model uses"
              error={errors.prompt}
            />

            {currentModel &&
              <>
                <div className="mb-3">
                  <Label title="Pricing" />
                  <div><span className="inline-block w-20">Message</span> <Tag title={`$${currentModel.costs.input}`} /></div>
                  <div><span className="inline-block w-20">Response</span> <Tag title={`$${currentModel.costs.output}`} /></div>
                  <div className="text-xs mt-2 opacity-50">Pricing (per 1M tokens ~= 750k English words):</div>
                </div>
                <div className="mb-3">
                  <Label title="Capabilities" />
                  <div className={currentModel.capabilities.has_memory ? 'text-success' : 'text-danger'}>
                    <label><Icon name={currentModel.capabilities.has_memory ? 'check' : 'cancel'} className="mr-2" />Memory</label>
                  </div>
                  <div className={currentModel.capabilities.has_tools ? 'text-success' : 'text-danger'}>
                    <label><Icon name={currentModel.capabilities.has_tools ? 'check' : 'cancel'} className="mr-2" />Kits</label>
                  </div>
                </div>
              </>
            }
            {currentModel && currentModel.config_parameters.length > 0 && (
              <div>
                <div className="glass-border mb-3"></div>
                <label className="block mb-3 cursor-pointer" onClick={onClickToggleParameters}>Advanced Settings <Icon name={isExpanded ? "arrow-up" : "arrow-down"} /></label>
                <div className={clsx({ "hidden": !isExpanded })}>
                  <ParametersForm ref={parametersFormRef} groups={currentModel.config_parameters} values={initialParametersValues} inputClassName="mb-3" />
                </div>
              </div>
            )}

          </TabPanel>
        </TabView>
      </PanelBody>
      <PanelFooter>
        <div></div>
        <Button variant="success" title="Save Changes" size="sm" icon="save" onClick={onClickSave} isLoading={isUpdating} />
      </PanelFooter>
      {currentModel && <PromptsModal onPromptSelected={onPromptSelected} isOpened={isPromptModalOpened} modelId={currentModel.id} onClose={onPromptModalClosed} />}
      <GeneratePromptModal isOpened={isGeneratePromptModalOpened} onClose={onGenerateModalClosed} onPromptSelected={onPromptSelected} />
      <ImageChangeModal isOpened={isImageModalOpened} onClose={onImageModalClosed} onChange={onImageChanged} />
    </div>
  )
}

export default AgentEditPanel;
