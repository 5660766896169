import { ArgumentValues, Argument } from "@hubblai/hubbl-core/lib/code.js";
import { Checkbox, Input, Label } from "@hubblai/hubbl-ui/components/index.js";
import { ClassName, ComponentProps } from "@hubblai/hubbl-ui/components/types.js";
import JSONEditor from '~/components/Code/JSONEditor.tsx';


type ArgumentsFormProps = {
  args: Argument[],
  values: ArgumentValues,
  componentClassName?: ClassName,
  onChange: (name: string, value: any) => void,
} & ComponentProps;

const ArgumentsForm: React.FC<ArgumentsFormProps> = ({ args, values, onChange, className, componentClassName }) => {

  const onArgChanged = (arg: Argument, value: any) => onChange(arg.name, value);

  return (
    <div className={className}>
      <Label title="Arguments" />
      {args.map(arg => (
        <div key={arg.name}>
          {arg.type === 'boolean' &&
            <Checkbox
              className={componentClassName}
              title={arg.name} sublabel={arg.description}
              titlePosition="right"
              value={values[arg.name] || false}
              onChange={(e: any) => onArgChanged(arg, e.target.value)} />
          }
          {arg.type === 'number' &&
            <Input
              type='number'
              className={componentClassName}
              label={arg.name} sublabel={arg.description}
              value={values[arg.name] || 0}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onArgChanged(arg, parseInt(e.target.value)) }
            />
          }
          {arg.type === 'string' &&
            <Input
              className={componentClassName}
              label={arg.name} sublabel={arg.description}
              value={values[arg.name] || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onArgChanged(arg, e.target.value)} />
          }
          {arg.type === 'object' &&
            <JSONEditor
              label={arg.name}
              sublabel={arg.description}
              className={componentClassName}
              value={values[arg.name] || '{}'}
              onChange={(value: string) => onArgChanged(arg, value)}
            />
          }
        </div>
      ))}
    </div>
  )
}

export default ArgumentsForm;
