import { Dispatch } from 'redux';
import { perform } from '@hubblai/hubbl-ui/store/actions.js'
import * as types from './types';
import { FunctionModel } from '../models';
import { HTTP_METHOD } from '@hubblai/hubbl-ui/lib/api.js';

export const createFunction = (organizationId: string, data: any) => async (dispatch: Dispatch) => {
  return perform<FunctionModel>(
    dispatch,
    types.CREATE_FUNCTION_REQUEST,
    {
      method: HTTP_METHOD.POST,
      url: `/organizations/${organizationId}/functions`,
      data,
    },
    FunctionModel.fromDTO
  );
}

export const createDraftFunction = (organizationId: string, functionId: string, data: any) => async (dispatch: Dispatch) => {
  data.previous_function_id = functionId;
  return perform<FunctionModel>(
    dispatch,
    types.CREATE_DRAFT_FUNCTION_REQUEST,
    {
      method: HTTP_METHOD.POST,
      url: `/organizations/${organizationId}/functions`,
      data,
    },
    FunctionModel.fromDTO,
    {
      id: functionId,
    }
  )
}

export const updateFunction = (organizationId: string, functionId: string, data: any) => async (dispatch: Dispatch) => {
  return perform<FunctionModel>(
    dispatch,
    types.UPDATE_FUNCTION_REQUEST,
    {
      method: HTTP_METHOD.PATCH,
      url: `/organizations/${organizationId}/functions/${functionId}`,
      data,
    },
    FunctionModel.fromDTO
  );
}

export const submitFunctionAction = (organizationId: string, functionId: string, actionName: 'publish' | 'clone') => async (dispatch: Dispatch) => {
  return perform<FunctionModel>(
    dispatch,
    types.UPDATE_FUNCTION_REQUEST,
    {
      method: HTTP_METHOD.POST,
      url: `/organizations/${organizationId}/functions/${functionId}`,
      data: {
        action: actionName,
      }
    },
    FunctionModel.fromDTO,
  );
}

export const fetchFunctions = (organizationId: string) => async (dispatch: Dispatch) => {
  return perform<FunctionModel[]>(
    dispatch,
    types.FETCH_FUNCTIONS_REQUEST,
    {
      method: HTTP_METHOD.GET,
      url: `/organizations/${organizationId}/functions`,
    },
    FunctionModel.fromDTOs
  );
}

export const fetchFunction = (organizationId: string, functionId: string) => async (dispatch: Dispatch) => {
  return perform<FunctionModel>(
    dispatch,
    types.FETCH_FUNCTION_REQUEST,
    {
      method: HTTP_METHOD.GET,
      url: `/organizations/${organizationId}/functions/${functionId}`,
    },
    FunctionModel.fromDTO
  );
}

export const deleteFunction = (organizationId: string, functionId: string) => async (dispatch: Dispatch) => {
  return perform<FunctionModel>(
    dispatch,
    types.DELETE_FUNCTION_REQUEST,
    {
      method: HTTP_METHOD.DELETE,
      url: `/organizations/${organizationId}/functions/${functionId}`,
    },
    undefined,
    {
      id: functionId,
    }
  );
}
