import { Label } from "@hubblai/hubbl-ui/components/index.js";
import { ComponentProps, QuickButton } from "@hubblai/hubbl-ui/components/types.js";

import React, { useCallback, useImperativeHandle, useMemo } from 'react';
import TurndownService from 'turndown';
import { marked } from 'marked';

import styles from './MarkdownInput.module.css';

import { useEditor, EditorContent, EditorEvents } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { AdvancedInputItemRef } from "../types";

const extensions = [
  StarterKit,
];

TurndownService.prototype.escape = function (e: string) {
  return e;
};

const turndown = new TurndownService({
  codeBlockStyle: 'fenced',
  br: '\r\n',
});

type Props = {
  label?: string,
  isReadOnly?: boolean,
  buttons?: QuickButton[],
  value: any,
  onChange: (value: string) => void,
} & ComponentProps;

const MarkdownInput = React.forwardRef<AdvancedInputItemRef, Props>(({ value, isReadOnly, onChange, label, className, buttons }, ref) => {

  const onInput = ({ editor }: EditorEvents['update']) => {
    const markdownText = turndown.turndown(editor.getHTML());
    onChange(markdownText);
  }

  const htmlValue = useMemo(() => {
    return marked.parse(value, { async: false }) as string
  }, [value]);

  const editor = useEditor({
    extensions,
    content: htmlValue,
    onUpdate: onInput,
    parseOptions: {
      preserveWhitespace: 'full',
    }
  });

  const focus = useCallback(() => {
    editor?.commands.focus();
  }, [editor]);

  const forceContent = useCallback((content: string) => {
    editor?.commands.setContent(content);
  }, [editor]);

  useImperativeHandle(ref, () => {
    return {
      focus,
      forceContent,
    }
  }, [focus, forceContent]);

  return (
    <div className={className}>
      <Label title={label} buttons={buttons} />
      <EditorContent editor={editor} className={styles.tiptap} readOnly={isReadOnly} />
    </div>
  )
});

export default MarkdownInput;
