import API from '@hubblai/hubbl-ui/lib/api.js';
import * as types from './types';
import { AIModel, AIPrompt } from '../models';
import { Dispatch } from 'redux';
import { MODEL_ID } from '@hubblai/hubbl-core/models/Agent.js';

export const fetchModels = () => async (dispatch: Dispatch) => {
  dispatch({ type: types.FETCH_MODELS_REQUEST.START });
  try {
    const { data } = await API.get('/models');
    const models = AIModel.fromDTOs(data);
    const providers = models.reduce((acc: string[], model: AIModel) => {
      if (!acc.includes(model.provider)) {
        acc.push(model.provider)
      }
      return acc;
    }, []);
    dispatch({
      type: types.FETCH_MODELS_REQUEST.SUCCESS,
      payload: {
        models,
        providers,
      }
    });
  }
  catch (err) {
    dispatch({ type: types.FETCH_MODELS_REQUEST.FAILURE })
  }
}

export const fetchPrompts = (modelId: MODEL_ID, query?: string) => async (dispatch: Dispatch) => {
  dispatch({ type: types.FETCH_PROMPTS_REQUEST.START, payload: { query } });
  try {
    const { data } = await API.get(`/models/${modelId}/prompts`, { query });
    const prompts = AIPrompt.fromDTOs(data);
    dispatch({
      type: types.FETCH_PROMPTS_REQUEST.SUCCESS,
      payload: {
        prompts,
        query,
      }
    });
  }
  catch (err) {
    dispatch({ type: types.FETCH_PROMPTS_REQUEST.FAILURE })
  }
}
