const defaultConfig = {
  AGENTS: {
    // AVATAR_GENERATE_AGENT_ID: '5ec7ae9d-d1ec-40e2-9050-b359d0da438a', // Image Generator local
    AVATAR_GENERATE_AGENT_ID: '596b55bb-b101-4a19-8af1-09222ab05289', // DALL-E Generator
    PROMPT_GENERATE_AGENT_ID: 'bd673bc8-e3aa-4c69-88b6-09d9ae972128',
  },
  API_BASE_URL: "https://api.gethubbl.xyz",
  EMBED_BASE_URL: 'https://embed.gethubbl.xyz',
  MAX_IMAGE_SIZE: 1024000,
  SENTRY: {
    DSN: "https://bd48427041208dab77e167e3aab5b49f@o4507162264403968.ingest.us.sentry.io/4507274132717568",
  },
  PUSHER: {
    APP_KEY: "b2f3423170e9989aefe5",
    CLUSTER: "us3",
  }
};

const envConfig = {
  local: {
    API_BASE_URL: 'http://localhost:8080',
    EMBED_BASE_URL: 'http://localhost:8500',
  },
  dev: {
    API_BASE_URL: 'https://api.gethubbl.xyz',
    EMBED_BASE_URL: 'https://embed.gethubbl.xyz',
  },
  prod: {

  }
}

const env = process.env.NODE_ENV || 'local';

export const config = {
  ...defaultConfig,
  ...(envConfig as any)[env],
};

export default config;
