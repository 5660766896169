import { Modal, Select, SelectChangeEvent } from "@hubblai/hubbl-ui/components/index.js";
import { useCurrentUserId } from "@hubblai/hubbl-ui/store/auth/hooks.js";
import { useEffect, useState } from "react";
import { getPreference, setPreference } from "~/lib/preferences";
import config from "~/config";

type Props = {
  appId: string,
  isOpened: boolean,
  onClose: () => void,
}

type IFrameProps = {
  url: string,
}

type IFrameCache = { [url: string]: any };

const iframeCache: IFrameCache = {};

const IFrame: React.FC<IFrameProps> = ({ url }) => {
  if (!iframeCache[url]) {
    iframeCache[url] = <iframe src={url} height="100%" width="100%" />;
  }
  return iframeCache[url];
};

const DEFAULT_LANGUAGE_ISO = 'en';

const LANGUAGES = [
  { id: "ar", name: "Arabic" },
  { id: "bn", name: "Bengali" },
  { id: "de", name: "German" },
  { id: "en", name: "English" },
  { id: "es", name: "Spanish" },
  { id: "fr", name: "French" },
  { id: "hi", name: "Hindi" },
  { id: "pt", name: "Portuguese" },
  { id: "ru", name: "Russian" },
  { id: "zh", name: "Chinese" },
]

const EmbedModal: React.FC<Props> = ({ isOpened, onClose, appId }) => {
  const [url, setUrl] = useState('');
  const [languageISO, setLanguageISO] = useState(getPreference('test_drive_language', DEFAULT_LANGUAGE_ISO));
  const userId = useCurrentUserId();

  useEffect(() => {
    setUrl(`${config.EMBED_BASE_URL}?appid=${appId}&lang=${languageISO}&uid=${userId}`);
  }, [appId, userId, languageISO]);

  const onLanguageChange = (e: SelectChangeEvent) => {
    const lang = e.target.value;
    setPreference('test_drive_language', lang);
    setLanguageISO(lang);
  }

  return (
    <Modal isOpened={isOpened} usesNavigation={false} onClose={onClose} size="mid-narrow" title={
      <div className="flex items-center justify-between">
        Test Drive
        <Select items={LANGUAGES} itemFieldLabel="name" itemFieldValue="id" value={languageISO} onChange={onLanguageChange} />
      </div>
    }>
      <IFrame url={url} />
    </Modal>
  )
}

export default EmbedModal;
