import { DropdownMenu, DropdownMenuItem, Select, SelectChangeEvent, confirm } from '@hubblai/hubbl-ui/components/index.js';
import React, { useEffect } from 'react';
import clsx from 'clsx';

import styles from './TopBar.module.css';
import { useUser } from '@hubblai/hubbl-ui/store/auth/hooks.js';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, actions } from '@hubblai/hubbl-ui/store/index.js';
import { useCurrentOrganizationId } from '~/store/organizations/hooks';
import { setCurrentOrganization } from '~/store/organizations/actions';
import { getPreference, removePreference, setPreference, wipe } from '~/lib/preferences';

const TopBar: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useUser();
  const currentOrganizationId = useCurrentOrganizationId();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const onClickConfirmLogout = () => {
    wipe();
    actions.resetAll(dispatch);
    navigate('/');
  }

  useEffect(() => {
    if (user && user.organizations && user.organizations.length && !currentOrganizationId) {
      let preferredOrganizationId = getPreference('organization_id');
      if (preferredOrganizationId) {
        const index = user.organizations.findIndex(o => o.id === preferredOrganizationId);
        if (index === -1) {
          removePreference('organization_id');
          preferredOrganizationId = undefined;
        }
      }
      dispatch(setCurrentOrganization(preferredOrganizationId || user.organizations[0].id));
    }
  }, [user, currentOrganizationId, dispatch]);

  const onClickLogout = () => {
    confirm({
      message: <div>Are you sure you want to log out?</div>,
      header: 'Log out',
      icon: 'pi pi-exclamation-triangle',
      accept: onClickConfirmLogout,
    });
  }

  const menuItems: DropdownMenuItem[] = [{
    icon: 'leave',
    label: 'Logout',
    onClick: onClickLogout,
  }];

  const menu = [{
    icon: 'app',
    label: 'Apps',
    to: '/apps',
  }, {
    icon: 'agent',
    label: 'Agents',
    to: '/agents',
  }, {
    icon: 'kit',
    label: 'Kits',
    to: '/kits',
  }, {
    icon: 'function',
    label: 'Functions',
    to: '/functions',
  }];

  const onChangeOrganizationId = (e: SelectChangeEvent) => {
    dispatch(setCurrentOrganization(e.target.value));
    setPreference('organization_id', e.target.value);
  }

  const topPath = pathname.split('/')[1];

  return (
    <div className={styles.Root}>
      <div className='flex-1'>
        {user.organizations && <Select items={user.organizations} itemFieldValue='id' itemFieldLabel='display_name' value={currentOrganizationId} onChange={onChangeOrganizationId} dropdownClassName='w-60' />}
      </div>
      <div className={styles.Links}>
        {menu.map((item: any) => (
          <Link key={item.to} to={item.to} className={clsx(styles.Link, { [styles.active]: topPath === item.to.substring(1) })}>
            <i className='icon icon-pear mr-2' />
            <span>{item.label}</span>
          </Link>
        ))}
      </div>
      <div className='flex-1 text-right'>
        <DropdownMenu items={menuItems} buttonClassName={styles.AvatarButton} dropdownClassName='w-60'>
          <img src={user?.getAvatar()} alt={user?.getDisplayName()} />
        </DropdownMenu>
      </div>
    </div>
  );
}

export default TopBar;
