import { isNil } from "@hubblai/hubbl-core/lib/object.js";

let storageType: 'local' | 'session';
const PREFERENCES_KEY = 'HP';

const DEFAULT_LANGUAGE = 'en';

const getStorage = (): Storage => {
  return storageType === 'local' ? localStorage : sessionStorage;
}

export type Preference = 'panel_size' | 'organization_id' | 'test_drive_language';

export enum PANEL_SIZE {
  MINIMUM = 0,
  EXPANDED = 1,
  FULL = 2,
}

export type Preferences = {
  panelSize: PANEL_SIZE,
  testDriveLanguage: string,
}

const DEFAULT_PREFERENCES: Preferences = {
  panelSize: PANEL_SIZE.MINIMUM,
  testDriveLanguage: DEFAULT_LANGUAGE,
}

const getPreferences = () => {
  const preferencesRaw = getStorage().getItem(PREFERENCES_KEY);
  return preferencesRaw ? JSON.parse(preferencesRaw) : {...DEFAULT_PREFERENCES};
}

const updatePreferences = (name: Preference, value: any) => {
  const preferences = getPreferences();
  preferences[name] = value;
  getStorage().setItem(PREFERENCES_KEY, JSON.stringify(preferences));
}

export const setPreference = (name: Preference, value: any) => {
  updatePreferences(name, value);
}

export const getPreference = (name: Preference, defaultValue?: any) => {
  const preferences = getPreferences();
  return isNil(preferences[name]) ? defaultValue : preferences[name];
}

export const removePreference = (name: Preference) => {
  setPreference(name, undefined);
}

export const wipe = () =>{
  getStorage().clear();
}
