import { ArgumentValues, Argument, validateArgs } from "@hubblai/hubbl-core/lib/code.js";
import { ButtonItem, Input, Label, Modal, Button, Message } from "@hubblai/hubbl-ui/components/index.js";
import { FormErrors } from "@hubblai/hubbl-ui/components/types.js";
import API from "@hubblai/hubbl-ui/lib/api.js";
import { isString, rows } from "@hubblai/hubbl-core/lib/string.js";
import { useEffect, useState } from "react";
import { FunctionModel } from "~/store/models";
import { useCurrentOrganizationId } from "~/store/organizations/hooks";
import ArgumentsForm from "../ArgumentsForm";
import AdvancedInput, { Language, LanguageValue } from "~/components/AdvancedInput";
import { getInitialGroupValues } from "@hubblai/hubbl-core/lib/parameters.js";

type Props = {
  fn: FunctionModel,
  isOpened: boolean,
  hasCodeChanged: boolean,
  args: Argument[],
  onClose: () => void,
}

const FunctionTestModal: React.FC<Props> = ({ isOpened, onClose, hasCodeChanged, fn, args }) => {
  const [_errors, setErrors] = useState<FormErrors>({});
  const organizationId = useCurrentOrganizationId();
  const [isContextExpanded, setIsContextExpanded] = useState(false);

  const [result, setResult] = useState('');
  const [isTesting, setIsTesting] = useState(false);
  const [parameters, setParameters] = useState('{}');

  const [userId, setUserId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [chatId, setChatId] = useState('');

  const [argValues, setArgValues] = useState<ArgumentValues>({});

  useEffect(() => {
    if (fn) {
      setResult('');
      setArgValues({});
      if (fn.config_parameters && fn.config_parameters.length > 0) {
        const params = getInitialGroupValues(fn.config_parameters);
        setParameters(JSON.stringify(params, null, 2));
      }
    }
  }, [fn])

  const validate = () => {
    const errors: FormErrors = {};
    const argErrors = validateArgs(args, argValues);
    Object.assign(errors, argErrors);
    setErrors(errors);
    return Object.keys(errors).length === 0;
  }

  const processResult = (data: any, err?: any) => {
    if (err) {
      setResult(JSON.stringify(result, null, 2));
      return;
    }

    if (typeof data === 'object') {
      setResult(JSON.stringify(data, null, 2));
    } else if (data) {
      setResult(data.toString());
    } else {
      setResult('No output received');
    }
  }

  const onClickTest = async () => {
    if (validate()) {
      setIsTesting(true);
      const context = {
        chat_id: chatId,
        user: {
          id: userId,
          first_name: firstName,
          last_name: lastName,
        }
      };
      try {
        const { data } = await API.post(`/organizations/${organizationId}/functions/${fn.id}/test`, {
          context,
          parameters: JSON.parse(parameters),
          args: argValues,
        });
        processResult(data);
      }
      catch (err) {
        console.error(err);
        processResult(undefined, err);
      }
      setIsTesting(false);
    }
  }

  const buttons: ButtonItem[] = [{
    title: "Cancel",
    action: 'close',
    variant: 'default',
    outline: true,
    position: 'left',
  }, {
    title: "Run Test",
    variant: 'success',
    onClick: onClickTest,
    outline: true,
    isLoading: isTesting,
  }];

  const onChangeUserId = (e: React.ChangeEvent<HTMLInputElement>) => setUserId(e.target.value);
  const onChangeFirstName = (e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value);
  const onChangeLastName = (e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value);
  const onChangeChatId = (e: React.ChangeEvent<HTMLInputElement>) => setChatId(e.target.value);

  const onParametersChanged = (language: Language, value: LanguageValue) => {
    if (language === 'json' && isString(value)) {
      setParameters(value)
    }
  };

  const onArgumentChanged = (name: string, value: any) => {
    setArgValues(values => {
      return {
        ...values,
        [name]: value,
      }
    });
  }

  const onToggleIsContextExpanded = () => {
    setIsContextExpanded(currentValue => !currentValue);
  }

  return (
    <Modal isOpened={isOpened} usesNavigation={false} onClose={onClose} size="almost-max" title={"Test Function"} buttons={buttons}>
      {hasCodeChanged && <Message className="mb-3" text="Function code has been changed without saving. If you run a test, it will be tested against the last saved version." />}
      <AdvancedInput
        label="Parameters"
        className="mb-3"
        values={{
          'json': parameters,
        }}
        onChange={onParametersChanged}
      />
      <hr className="mb-3" />

      <ArgumentsForm args={args} values={argValues} onChange={onArgumentChanged} className="mb-3" componentClassName="mb-3" />
      <hr className="mb-3" />

      <div>
        <div className="flex flex-row">
          <Label title="Context" size="lg" />
          <Button icon={isContextExpanded ? 'arrow-up' : 'arrow-down'} size="sm" variant="link" onClick={onToggleIsContextExpanded} />
        </div>
        {isContextExpanded &&
          <div>
            <Input label="Chat ID" value={chatId} onChange={onChangeChatId} className="mb-3" />
            <Label title="User" />
            <div className="flex flex-row justify-between">
              <Input label="ID" value={userId} onChange={onChangeUserId} className="flex-1" />
              <Input label="First Name" value={firstName} onChange={onChangeFirstName} className="flex-1 mx-2" />
              <Input label="Last Name" value={lastName} onChange={onChangeLastName} className="flex-1" />
            </div>
          </div>
        }
      </div>

      <hr className="mb-3" />

      <Input multiline label="Output" inputClassName='font-mono text-sm' value={result} rows={rows(result, 10)} isReadOnly />

    </Modal>
  )
}

export default FunctionTestModal;
