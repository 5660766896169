import { Button, Spinner } from "@hubblai/hubbl-ui/components/index.js";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useKits, useIsFetchingAll } from "~/store/kits/hooks";

const KitsPage: React.FC = () => {
  const navigate = useNavigate();
  const kits = useKits();
  const isFetching = useIsFetchingAll();

  const onClickCreateNewKit = async () => {
    navigate('/kits/new');
  }

  useEffect(() => {
    if (kits.length > 0) {
      navigate(`/kits/${kits[0].id}`);
    }
  }, [kits, navigate])

  return (
    <div className="flex flex-1 justify-center items-center">
      {isFetching && <Spinner />}
      {!isFetching && kits.length === 0 &&
        <div>
          <div>You have no kits. Sucks.</div>
          <Button title='Create new Kit' icon='add-function' onClick={onClickCreateNewKit} />
        </div>
      }
    </div>
  )
}

export default KitsPage;
