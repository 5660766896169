import { Argument } from "@hubblai/hubbl-core/lib/code.js";
import { Button, Tag } from "@hubblai/hubbl-ui/components/index.js";

type ArgumentListItemProps = {
  arg: Argument,
  onClick: () => void,
  onClickDelete: () => void,
}

const ArgumentListItem: React.FC<ArgumentListItemProps> = ({ arg, onClick, onClickDelete }) => {
  const isRequired = arg.validations ? arg.validations.findIndex(v => v.type === 'required') > -1 : false;
  return (
    <div className="bg-white rounded-sm px-3 py-2 border flex flex-row justify-between items-center">
      <div className="flex flex-row items-center">
        <Tag title={arg.type} />
        <span className="mx-3">{arg.name}</span>
        {isRequired && <span className="opacity-50">[required]</span>}
      </div>
      <div>
        <Button title="Edit" onClick={onClick} size="xs" className="mx-2" variant="info" icon='edit' />
        <Button title="Delete" onClick={onClickDelete} size="xs" variant="danger" icon='remove' />
      </div>
    </div>
  )
}

export default ArgumentListItem;
