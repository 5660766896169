import React from 'react';
import BaseNode from './BaseNode';
import { CanvasNode } from '~/lib/canvas';

type Props = {
  data: CanvasNode,
  selected: boolean,
};

const KitNode: React.FC<Props> = (props) => {
  return (
    <BaseNode {...props} />
  )
}

export default KitNode;
