import { Label } from "@hubblai/hubbl-ui/components/index.js";
import ConfigParameterListItem from "../ConfigParameterListItem";
import { Parameter, ParameterGroups } from "@hubblai/hubbl-core/lib/parameters.js";
import { ComponentProps } from "@hubblai/hubbl-ui/components/types.js";

type Props = {
  groups: ParameterGroups,
  onClick: (parameter: Parameter, groupIndex: number, index: number) => void,
  onClickDelete: (groupIndex: number, index: number) => void,
} & ComponentProps;

const ConfigParametersList: React.FC<Props> = ({ className, groups, onClick, onClickDelete }) => {
  return (
    <div className={className}>
      {groups.map((group, gi) =>
        <div key={`group-${gi}`}>
          {group.label && <Label title={group.label} />}
          {group.parameters.map((parameter, pi) =>
            <ConfigParameterListItem
              key={parameter.name}
              parameter={parameter}
              onClick={() => onClick(parameter, gi, pi)}
              onClickDelete={() => onClickDelete(gi, pi)}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default ConfigParametersList;
