import { Outlet, useNavigate, useParams } from "react-router-dom"
import { useApps, useIsFetchingAll } from "~/store/apps/hooks";
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { Button } from "@hubblai/hubbl-ui/components/index.js";
import AppListItem from "~/components/AppListItem";
import Search from "~/components/Search";
import { useCurrentOrganizationId } from "~/store/organizations/hooks";
import { useEffect } from "react";

const SIDE_PANEL_SIZE = 30;


const AppsLayout: React.FC = () => {
  const navigate = useNavigate();
  const apps = useApps();
  const { appId } = useParams();
  const isFetching = useIsFetchingAll();
  const organizationId = useCurrentOrganizationId();

  const onClickNewApp = () => {
    navigate('/apps/new');
  }

  useEffect(() => {
    if (organizationId) {
      navigate('/apps' + (appId ? `/${appId}` : ''));
    }
  }, [navigate, organizationId, appId])

  const onSearchChanged = () => {
    // TODO:P1 actually implement
  }

  return (
    <Splitter className="flex flex-1 overflow-hidden">
      <SplitterPanel size={SIDE_PANEL_SIZE} minSize={10} className="flex flex-col">
        <div className="flex flex-row border-b-2 p-2">
          <Search placeholder="Search apps..." className="flex-1" onChange={onSearchChanged} />
          <Button icon='plus' onClick={onClickNewApp} className="ml-1" />
        </div>
        {!isFetching && apps.length > 0 && (
          <div className="flex flex-col p-1 overflow-auto">
            {apps.map(app => (
              <AppListItem key={app.id} app={app} isActive={appId === app.id} />
            ))}
          </div>
        )}
      </SplitterPanel>
      <SplitterPanel size={100 - SIDE_PANEL_SIZE}>
        <Outlet />
      </SplitterPanel>
    </Splitter>
  )
}

export default AppsLayout;
