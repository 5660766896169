import { Dispatch } from 'redux';
import { perform } from '@hubblai/hubbl-ui/store/actions.js'
import * as types from './types';
import { FunctionModel, Kit } from '../models';
import { HTTP_METHOD } from '@hubblai/hubbl-ui/lib/api.js';
import { GetStoreState } from '../types';

export const createKit = (organizationId: string, data: any) => async (dispatch: Dispatch) => {
  return perform<Kit>(
    dispatch,
    types.CREATE_KIT_REQUEST,
    {
      method: HTTP_METHOD.POST,
      url: `/organizations/${organizationId}/kits`,
      data,
    },
    Kit.fromDTO
  );
}

export const addFunction = (organizationId: string, kitId: string, functionId: string) => async (dispatch: Dispatch, getState: GetStoreState) => {
  const kit = getState().kits.kitMap[kitId];
  const existingFn = kit?.functions?.find(fn => fn.id === functionId);
  if (existingFn) {
    return existingFn;
  }
  return perform<Kit>(
    dispatch,
    types.KIT_ADD_FUNCTION_REQUEST,
    {
      method: HTTP_METHOD.PUT,
      url: `/organizations/${organizationId}/kits/${kitId}/functions/${functionId}`,
      data: {},
    },
    FunctionModel.fromDTO,
    {
      kitId,
    }
  );
}

export const removeFunction = (organizationId: string, kitId: string, functionId: string) => async (dispatch: Dispatch) => {
  return perform<Kit>(
    dispatch,
    types.KIT_REMOVE_FUNCTION_REQUEST,
    {
      method: HTTP_METHOD.DELETE,
      url: `/organizations/${organizationId}/kits/${kitId}/functions/${functionId}`,
    },
    undefined,
    {
      kitId,
      functionId,
    }
  );
}

export const updateKit = (organizationId: string, kitId: string, data: any) => async (dispatch: Dispatch) => {
  return perform<Kit>(
    dispatch,
    types.UPDATE_KIT_REQUEST,
    {
      method: HTTP_METHOD.PATCH,
      url: `/organizations/${organizationId}/kits/${kitId}`,
      data,
    },
    Kit.fromDTO
  );
}

export const updateKitIcon = (organizationId: string, kitId: string, file: File) => async (dispatch: Dispatch) => {
  return perform<Kit>(
    dispatch,
    types.UPDATE_KIT_REQUEST,
    {
      method: HTTP_METHOD.PUT,
      url: `/organizations/${organizationId}/kits/${kitId}/icon`,
      file,
    },
    Kit.fromDTO
  );
}

export const fetchKits = (organizationId: string) => async (dispatch: Dispatch) => {
  return perform<Kit[]>(
    dispatch,
    types.FETCH_KITS_REQUEST,
    {
      method: HTTP_METHOD.GET,
      url: `/organizations/${organizationId}/kits`,
    },
    Kit.fromDTOs
  );
}

export const fetchKit = (organizationId: string, kitId: string) => async (dispatch: Dispatch) => {
  return perform<Kit>(
    dispatch,
    types.FETCH_KIT_REQUEST,
    {
      method: HTTP_METHOD.GET,
      url: `/organizations/${organizationId}/kits/${kitId}`,
    },
    Kit.fromDTO
  );
}

export const deleteKit = (organizationId: string, kitId: string) => async (dispatch: Dispatch) => {
  return perform<Kit>(
    dispatch,
    types.DELETE_KIT_REQUEST,
    {
      method: HTTP_METHOD.DELETE,
      url: `/organizations/${organizationId}/kits/${kitId}`,
    },
    undefined,
    {
      id: kitId,
    }
  );
}
