import React, { useMemo } from 'react';
import { useAppDispatch } from "@hubblai/hubbl-ui/store/index.js";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useCurrentOrganizationId } from "~/store/organizations/hooks";
import Canvas from '~/components/Canvas/Canvas';
import { fetchApp } from '~/store/apps/actions';
import { useAppAsNode } from '~/store/apps/hooks';

const AppEditPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams<{ appId: string }>();
  const appId = useMemo(() => params.appId, [params]);
  const organizationId = useCurrentOrganizationId();
  const app = useAppAsNode(appId!, organizationId!);

  useEffect(() => {
    if (organizationId) {
      dispatch(fetchApp(organizationId, appId!));
    }
  }, [organizationId, dispatch, appId])

  return (
    <div className="flex flex-col flex-1 w-full h-full">
      {app && <Canvas root={app} />}
    </div>
  )
}

export default AppEditPage;
