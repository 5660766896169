export const FETCH_APPS_REQUEST = {
  START: "FETCH_APPS_REQUEST.START",
  SUCCESS: "FETCH_APPS_REQUEST.SUCCESS",
  FAILURE: "FETCH_APPS_REQUEST.FAILURE",
}

export const FETCH_APP_REQUEST = {
  START: "FETCH_APP_REQUEST.START",
  SUCCESS: "FETCH_APP_REQUEST.SUCCESS",
  FAILURE: "FETCH_APP_REQUEST.FAILURE",
}

export const CREATE_APP_REQUEST = {
  START: "CREATE_APP_REQUEST.START",
  SUCCESS: "CREATE_APP_REQUEST.SUCCESS",
  FAILURE: "CREATE_APP_REQUEST.FAILURE",
}

export const UPDATE_APP_REQUEST = {
  START: "UPDATE_APP_REQUEST.START",
  SUCCESS: "UPDATE_APP_REQUEST.SUCCESS",
  FAILURE: "UPDATE_APP_REQUEST.FAILURE",
}

export const DELETE_APP_REQUEST = {
  START: "DELETE_APP_REQUEST.START",
  SUCCESS: "DELETE_APP_REQUEST.SUCCESS",
  FAILURE: "DELETE_APP_REQUEST.FAILURE",
}

export const APP_REMOVE_AGENT_REQUEST = {
  START: "APP_REMOVE_AGENT_REQUEST.START",
  SUCCESS: "APP_REMOVE_AGENT_REQUEST.SUCCESS",
  FAILURE: "APP_REMOVE_AGENT_REQUEST.FAILURE",
}

export const APP_ADD_AGENT_REQUEST = {
  START: "APP_ADD_AGENT_REQUEST.START",
  SUCCESS: "APP_ADD_AGENT_REQUEST.SUCCESS",
  FAILURE: "APP_ADD_AGENT_REQUEST.FAILURE",
}
