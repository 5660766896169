import { isEmpty } from '@hubblai/hubbl-core/lib/string.js';
import { Tag } from '@hubblai/hubbl-ui/components/index.js';
import { Variant } from '@hubblai/hubbl-ui/components/types.js';
import clsx from 'clsx';
import React from 'react';
import { Handle, Position } from 'reactflow';
import { CanvasNode, CanvasNodeType } from '~/lib/canvas';

type Props = {
  data: CanvasNode,
  selected: boolean,
};

const typeToVariant: {[name: string]: Variant } = {
  'kit': 'info',
  'function': 'success',
  'agent': 'primary',
  'app': 'danger'
}

const getSelectedCssClass = (type: CanvasNodeType) => {
  switch (type) {
    case 'agent':
      return 'border-purple-500';
    case 'kit':
      return 'border-blue-500';
    case 'app':
      return 'border-red-500';
    case 'function':
      return 'border-green-500';
    default:
      return '';
  }
}

const getHandleCssClass = (type: CanvasNodeType) => {
  switch (type) {
    case 'agent':
      return 'bg-purple-500';
    case 'kit':
      return 'bg-blue-500';
    case 'app':
      return 'bg-red-500';
    case 'function':
      return 'bg-green-500';
    default:
      return 'bg-gray-300';
  }
}

const BaseNode: React.FC<Props> = ({ data, selected }) => {
  const selectedCssClass = getSelectedCssClass(data.type);
  const handleCssClass = getHandleCssClass(data.type)
  return (
    <div className={clsx("canvas-node flex flex-col px-4 pt-4 pb-3 shadow-md rounded-md bg-white border-2 border-transparent relative w-128", { [selectedCssClass]: selected })}>
      <Tag title={data.type.toUpperCase()} className='absolute -top-3' variant={typeToVariant[data.type]} />
      {!data.isEditable && <Tag title={'Locked'} className='absolute -top-3 right-3' variant='info' />}
      <div className="flex flex-1">
        {data.icon &&
          <div className="flex mr-4">
            <img src={data.icon} className="w-20 h-20 rounded-md" />
          </div>
        }
        <div className='flex flex-col flex-1'>
          <label className='font-semibold text-lg mb-2'>{data.title}</label>
          <div className="text-gray-500 line-clamp-3">{data.subtitle}</div>
        </div>
      </div>

      {(data.leftSubtitle || data.rightSubtitle) && (
        <div className='mt-3 flex flex-row items-center justify-between text-sm opacity-50'>
          <span></span>
          <span>{data.leftSubtitle ? `${data.leftSubtitle} | `: ''}{data.rightSubtitle}</span>
        </div>
      )}

      {!isEmpty(data.parentId) && <Handle type="target" position={Position.Top} className={clsx(handleCssClass, "rounded-md")} style={{ width: '16px', height: '16px', top: -8 }}/>}
      {data.nodes && data.nodes.length > 0 && <Handle type="source" position={Position.Bottom} className={clsx(handleCssClass, "rounded-md")} style={{ width: '16px', height: '16px', bottom: -10 }}/>}
    </div>
  )
}

export default BaseNode;
