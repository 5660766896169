export const FETCH_AGENTS_REQUEST = {
  START: "FETCH_AGENTS_REQUEST.START",
  SUCCESS: "FETCH_AGENTS_REQUEST.SUCCESS",
  FAILURE: "FETCH_AGENTS_REQUEST.FAILURE",
}

export const FETCH_AGENT_REQUEST = {
  START: "FETCH_AGENT_REQUEST.START",
  SUCCESS: "FETCH_AGENT_REQUEST.SUCCESS",
  FAILURE: "FETCH_AGENT_REQUEST.FAILURE",
}

export const CREATE_AGENT_REQUEST = {
  START: "CREATE_AGENT_REQUEST.START",
  SUCCESS: "CREATE_AGENT_REQUEST.SUCCESS",
  FAILURE: "CREATE_AGENT_REQUEST.FAILURE",
}

export const UPDATE_AGENT_REQUEST = {
  START: "UPDATE_AGENT_REQUEST.START",
  SUCCESS: "UPDATE_AGENT_REQUEST.SUCCESS",
  FAILURE: "UPDATE_AGENT_REQUEST.FAILURE",
}

export const DELETE_AGENT_REQUEST = {
  START: "DELETE_AGENT_REQUEST.START",
  SUCCESS: "DELETE_AGENT_REQUEST.SUCCESS",
  FAILURE: "DELETE_AGENT_REQUEST.FAILURE",
}

export const AGENT_REMOVE_KIT_REQUEST = {
  START: "AGENT_REMOVE_KIT_REQUEST.START",
  SUCCESS: "AGENT_REMOVE_KIT_REQUEST.SUCCESS",
  FAILURE: "AGENT_REMOVE_KIT_REQUEST.FAILURE",
}

export const AGENT_ADD_KIT_REQUEST = {
  START: "AGENT_ADD_KIT_REQUEST.START",
  SUCCESS: "AGENT_ADD_KIT_REQUEST.SUCCESS",
  FAILURE: "AGENT_ADD_KIT_REQUEST.FAILURE",
}

export const AGENT_UPDATE_KIT_REQUEST = {
  START: "AGENT_UPDATE_KIT_REQUEST.START",
  SUCCESS: "AGENT_UPDATE_KIT_REQUEST.SUCCESS",
  FAILURE: "AGENT_UPDATE_KIT_REQUEST.FAILURE",
}
