import { useSelector } from 'react-redux';
import { ModelsState } from './reducer';
import { AIModel, AIPrompt } from '../models';

type ModelsStoreType = {
  models: ModelsState,
}

export const useIsFetchingAll = () => useSelector<ModelsStoreType, boolean>(state => state.models.isFetchingAll);
export const useModels = () => useSelector<ModelsStoreType, AIModel[]>(state => state.models.models);
export const useProviders = () => useSelector<ModelsStoreType, string[]>(state => state.models.providers);
export const useProviderModels = (provider: string) => useSelector<ModelsStoreType, AIModel[]>(state => state.models.models.filter(model => model.provider === provider));

export const useIsFetchingPrompts = () => useSelector<ModelsStoreType, boolean>(state => state.models.isFetchingPrompts);
export const usePrompts = () => useSelector<ModelsStoreType, AIPrompt[]>(state => state.models.prompts);
