
import { ComponentProps } from '@hubblai/hubbl-ui/components/types.js';
import React from 'react';
import PanelHeader from '../Components/PanelHeader';
import { confirm } from '@hubblai/hubbl-ui/components/index.js';
import { useAppDispatch } from '@hubblai/hubbl-ui/store/index.js';
import { removeFunction } from '~/store/kits/actions';
import { useCurrentOrganizationId } from '~/store/organizations/hooks';
import { addToast } from '@hubblai/hubbl-ui/store/notifications/actions.js';
import { getDisplayError } from '@hubblai/hubbl-ui/lib/api.js';
import { useFunction } from '~/store/functions/hooks';
import PanelBody from '../Components/PanelBody';

type Props = {
  kitId: string,
  functionId: string,
  selectNode: (nodeId?: string) => void,
} & ComponentProps;

const FunctionConfigPanel: React.FC<Props> = ({ kitId, functionId, selectNode }) => {
  const dispatch = useAppDispatch();
  const organizationId = useCurrentOrganizationId();
  const fn = useFunction(functionId);

  const onClickDeleteConfirmed = async () => {
    try {
      await dispatch(removeFunction(organizationId!, kitId, functionId));
      selectNode();
      dispatch(addToast('Done!', 'Function was removed from the kit', 'success'));
    }
    catch (err) {
      dispatch(addToast('Oops!', `Something went wrong: ${getDisplayError(err)}`));
    }
  }

  const buttons = [{
    label: 'Remove',
    onClick: () => {
      confirm({
        message: <div>Are you sure you want to remove this function from the kit? </div>,
        header: 'Remove Function',
        icon: 'pi pi-exclamation-triangle',
        accept: onClickDeleteConfirmed,
      })
    },
  }];

  return (
    <div className="flex flex-col flex-1 h-full w-full">
      {fn && <PanelHeader title={`${fn?.name}`} buttons={buttons} />}
      {fn && <PanelBody><div>{fn.description}</div></PanelBody>}
    </div>
  )
}

export default FunctionConfigPanel;
