import update from 'immutability-helper';
import { ArgumentValues } from "@hubblai/hubbl-core/lib/code.js";
import { Parameter, ParameterGroups, getInitialGroupValues, getParameterByName, getInitialPrimitiveValue } from "@hubblai/hubbl-core/lib/parameters.js";
import { deepClone, mergeDeep } from "@hubblai/hubbl-core/lib/object.js";
import { Button, Input, Checkbox, Select, Label, SecretInput } from '@hubblai/hubbl-ui/components/index.js';
import React, { useCallback, useEffect, useImperativeHandle, useState } from "react";
import { ClassName, ComponentProps, FormErrors } from "@hubblai/hubbl-ui/components/types.js";
import { InputSwitchChangeEvent } from "primereact/inputswitch";
import { SelectChangeEvent } from '@hubblai/hubbl-ui/components/Select/Select.js';

type ParameterItemProps = {
  path: string,
  parameter: Parameter,
  value: any,
  showLabel?: boolean,
  showSublabel?: boolean,
  onChange: (name: string, value: any, parent?: Parameter) => void,
  parent?: Parameter,
  error?: string,
} & ComponentProps;

type Props = {
  groupClassName?: ClassName,
  labelClassName?: ClassName,
  inputClassName?: ClassName,
  groups: ParameterGroups,
  values?: ArgumentValues,
} & ComponentProps;

type OnChanged = (name: string, value: any, parent?: Parameter, index?: number) => void;
type OnAdded = (name: string, parent?: Parameter) => void;
type OnRemoved = (name: string, parent?: Parameter) => void;


const ParameterItem: React.FC<ParameterItemProps> = ({ className, showLabel = true, showSublabel = true, parameter, value, onChange, parent, error }) => {
  const onInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(parameter.name, e.target.value, parent);
  }
  const onSelectChanged = (e: SelectChangeEvent) => {
    onChange(parameter.name, e.target.value, parent);
  }
  const onCheckboxChanged = (e: InputSwitchChangeEvent) => {
    onChange(parameter.name, e.target.value, parent);
  }

  const displayName = showLabel ? (parameter.display_name || parameter.name) : undefined;
  const sublabel = showSublabel ? parameter.description : undefined;

  if (parameter.values) {
    return (
      <Select value={value} onChange={onSelectChanged} items={parameter.values} itemFieldLabel="name" itemFieldValue="id" label={displayName} sublabel={sublabel} error={error} className={className} />
    );
  }

  if (parameter.type === 'boolean') {
    return (
      <Checkbox className={className} title={displayName} value={value === true} onChange={onCheckboxChanged} titlePosition="right" error={error} sublabel={sublabel} />
    );
  }

  if (parameter.secret) {
    return <SecretInput label={displayName} value={value} onChange={onInputChanged} sublabel={sublabel} error={error} className={className} />
  }

  return (
    <Input label={displayName} value={value} onChange={onInputChanged} sublabel={sublabel} error={error} className={className} multiline={parameter.multiline} />
  )
}

type ParameterMultipleMenuProps = {
  name: string,
  canMinus: boolean,
  canPlus: boolean,
  parent?: Parameter,
  onClickPlus: (name: string, parent?: Parameter) => void,
  onClickMinus: (name: string, parent?: Parameter) => void,
} & ComponentProps;

const ParameterMultipleMenu: React.FC<ParameterMultipleMenuProps> = ({ name, canMinus, canPlus, onClickPlus, onClickMinus, parent, className }) => {
  return (
    <div className={className}>
      <Button icon='minus' onClick={() => onClickMinus(name, parent)} isDisabled={!canMinus} className="glass-button" />
      <Button icon='plus' onClick={() => onClickPlus(name, parent)} isDisabled={!canPlus} className="glass-button mx-2" />
    </div>
  )
}

type ParametersTreeProps = {
  parameters: Parameter[],
  values: ArgumentValues,
  onChanged: OnChanged,
  onAdded: OnAdded,
  onRemoved: OnRemoved,
  inputClassName?: ClassName,
  parent?: Parameter,
  errors: FormErrors,
  path: string,
}

const ParametersTree: React.FC<ParametersTreeProps> = ({ parameters, values, onChanged, onAdded, onRemoved, inputClassName, parent, errors, path = '' }) => {
  return (
    <div>
      {parameters.map(parameter => {
        const paramName = parameter.name;
        const key = path ? `${path}.${paramName}` : paramName;
        const value = values[paramName];

        if (parameter.parameters) {
          if (parameter.multiple) {
            const components = [];
            const count = value.length;
            for (let i = 0; i < count; i++) {
              components.push(
                <ParametersTree key={`${key}-${i}`} path={`${key}.${i}`} parameters={parameter.parameters} values={values[paramName][i]} errors={errors} onChanged={(name: string, value: any, parent?: Parameter) => onChanged(name, value, parent, i)} onAdded={onAdded} onRemoved={onRemoved} inputClassName={inputClassName} parent={parameter} />
              )
              components.push(<div key={`${key}-${i}-div`} className="glass-border mb-4 mt-3"></div>)
            }
            return (
              <div key={key}>
                {parameter.display_name || parameter.name}
                <div className="glass-border my-2"></div>
                <div className="px-2">
                  {components}
                </div>
                <ParameterMultipleMenu key={`${key}-menu`} name={paramName} canMinus={values[paramName].length > 1} canPlus={true} onClickPlus={onAdded} onClickMinus={onRemoved} parent={parent} className={inputClassName} />
              </div>
            )
          }
          return <ParametersTree parameters={parameter.parameters} path={key} values={values[paramName]} onChanged={onChanged} onAdded={onAdded} onRemoved={onRemoved} inputClassName={inputClassName} parent={parameter} errors={errors} />
        }

        if (Array.isArray(value)) {
          const components = [];
          const count = value.length;
          for (let i = 0; i < count; i++) {
            const name = `${key}.${i}`;
            components.push(
              <ParameterItem key={name} path={name} showLabel={i === 0} showSublabel={i === count - 1} parameter={parameter} className={inputClassName} value={value[i]} error={errors[name]} onChange={(name: string, value: any, parent?: Parameter) => onChanged(name, value, parent, i)} />
            )
          }
          components.push(
            <ParameterMultipleMenu key={`${key}-menu`} name={paramName} canMinus={count > 1} canPlus={true} onClickPlus={onAdded} onClickMinus={onRemoved} parent={parent} className={inputClassName} />
          )
          return components;
        }

        return (
          <ParameterItem key={key} path={key} parameter={parameter} className={inputClassName} value={value} onChange={onChanged} parent={parent} error={errors[key]} />
        )
      })}
    </div>
  )
}

export type ParametersFormRef = {
  validate: () => FormErrors,
  getData: () => any,
}

const ParametersForm = React.forwardRef<ParametersFormRef, Props>(({ groups, values: initialValues, className, inputClassName = 'mb-3', labelClassName, groupClassName }, ref) => {
  const [values, setValues] = useState<ArgumentValues>(getInitialGroupValues(groups));
  // TODO:P1 display errors
  const [errors, setErrors] = useState<FormErrors>({});

  useEffect(() => {
    if (initialValues) {
      const mergedValues = mergeDeep(getInitialGroupValues(groups), initialValues);
      setValues(mergedValues);
    }
  }, [initialValues, groups]);

  const onParameterChanged: OnChanged = (name: string, value: any, parent?: Parameter, index?: number) => {
    if (parent) {
      if (index !== undefined) {
        setValues(vals => update(vals, {
          [parent.name]: {
            [index]: {
              [name]: { $set: value }
            }
          },
        }));
      } else {
        setValues(vals => update(vals, {
          [parent.name]: {
            [name]: { $set: value },
          },
        }));
      }
    } else {
      if (index !== undefined) {
        setValues(vals => update(vals, {
          [name]: {
            [index]: { $set: value }
          },
        }));
      } else {
        setValues(vals => update(vals, {
          [name]: { $set: value },
        }));
      }
    }
  }

  const onParameterAdded: OnAdded = (name: string, _parent?: Parameter) => {
    const parameter = getParameterByName(groups, name);
    if (parameter) {
      setValues(vals => update(vals, {
        [name]: { $push: [getInitialPrimitiveValue(parameter)] }
      }));
    }
  }

  const onParameterRemoved: OnRemoved = (name: string, _parent?: Parameter) => {
    const arr = [...values[name]];
    arr.pop()
    setValues(currentValues => ({
      ...currentValues,
      [name]: arr,
    }));
  }

  // const validateOne = (paramName: string, validation: Validation): string | undefined => {
  //   // TODO:P1 get value by param name...
  //   if (validation.type === 'oneOf') {

  //   } else if (validation.type === 'required') {

  //   } else if (validation.type === 'url') {

  //   }
  //   return undefined;
  // }

  const validate = useCallback((): FormErrors => {
    const errors: FormErrors = {};
    // TODO:P1 display errors
    setErrors(errors);
    return errors;
  }, []);

  const getData = useCallback(() => {
    return deepClone(values);
  }, [values])

  useImperativeHandle(ref, () => {
    return {
      validate,
      getData,
    }
  }, [validate, getData]);

  return (
    <div className={className}>
      {groups.map((group, index) => (
        <div key={index} className={groupClassName}>
          {group.label && <Label title={group.label} size='lg' className={labelClassName} />}
          <ParametersTree
            errors={errors}
            parameters={group.parameters}
            values={values}
            onChanged={onParameterChanged}
            onAdded={onParameterAdded}
            onRemoved={onParameterRemoved}
            inputClassName={inputClassName}
            path=''
            />
        </div>
      ))}

    </div>
  )
});

export default ParametersForm;
