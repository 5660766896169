import OrganizationsReducer from './organizations/reducer';
import FunctionsReducer from './functions/reducer';
import KitsReducer from './kits/reducer';
import AgentsReducer from './agents/reducer';
import ModelsReducer from './models/reducer';
import AppsReducer from './apps/reducer';

export default {
  organizations: OrganizationsReducer,
  functions: FunctionsReducer,
  kits: KitsReducer,
  agents: AgentsReducer,
  models: ModelsReducer,
  apps: AppsReducer,
};
