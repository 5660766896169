import { Dispatch } from 'redux';
import { perform } from '@hubblai/hubbl-ui/store/actions.js'
import * as types from './types';
import { Agent, Kit } from '../models';
import { HTTP_METHOD } from '@hubblai/hubbl-ui/lib/api.js';
import { GetStoreState } from '../types';

export const createAgent = (organizationId: string, data: any) => async (dispatch: Dispatch) => {
  return perform<Agent>(
    dispatch,
    types.CREATE_AGENT_REQUEST,
    {
      method: HTTP_METHOD.POST,
      url: `/organizations/${organizationId}/agents`,
      data,
    },
    Agent.fromDTO
  );
}

export const updateAgent = (organizationId: string, agentId: string, data: any) => async (dispatch: Dispatch) => {
  return perform<Agent>(
    dispatch,
    types.UPDATE_AGENT_REQUEST,
    {
      method: HTTP_METHOD.PATCH,
      url: `/organizations/${organizationId}/agents/${agentId}`,
      data,
    },
    Agent.fromDTO
  );
}

export const updateAgentIcon = (organizationId: string, agentId: string, file: File) => async (dispatch: Dispatch) => {
  return perform<Agent>(
    dispatch,
    types.UPDATE_AGENT_REQUEST,
    {
      method: HTTP_METHOD.PUT,
      url: `/organizations/${organizationId}/agents/${agentId}/icon`,
      file,
    },
    Agent.fromDTO
  );
}

export const fetchAgents = (organizationId: string) => async (dispatch: Dispatch) => {
  return perform<Agent[]>(
    dispatch,
    types.FETCH_AGENTS_REQUEST,
    {
      method: HTTP_METHOD.GET,
      url: `/organizations/${organizationId}/agents`,
    },
    Agent.fromDTOs
  );
}

export const addKit = (organizationId: string, agentId: string, kitId: string) => async (dispatch: Dispatch, getState: GetStoreState) => {
  const agent = getState().agents.agentMap[agentId];
  const existingKit = agent?.kits?.find(kit => kit.id === kitId);
  if (existingKit) {
    return existingKit;
  }
  return perform<Kit>(
    dispatch,
    types.AGENT_ADD_KIT_REQUEST,
    {
      method: HTTP_METHOD.PUT,
      url: `/organizations/${organizationId}/agents/${agentId}/kits/${kitId}`,
      data: {},
    },
    Kit.fromDTO,
    {
      agentId,
    }
  );
}

export const removeKit = (organizationId: string, agentId: string, kitId: string) => async (dispatch: Dispatch) => {
  return perform(
    dispatch,
    types.AGENT_REMOVE_KIT_REQUEST,
    {
      method: HTTP_METHOD.DELETE,
      url: `/organizations/${organizationId}/agents/${agentId}/kits/${kitId}`,
    },
    undefined,
    {
      kitId,
      agentId,
    }
  );
}

export const updateKit = (organizationId: string, agentId: string, kitId: string, data: any) => async (dispatch: Dispatch) => {
  return perform(
    dispatch,
    types.AGENT_UPDATE_KIT_REQUEST,
    {
      method: HTTP_METHOD.PATCH,
      url: `/organizations/${organizationId}/agents/${agentId}/kits/${kitId}`,
      data,
    },
    Kit.fromDTO,
    {
      agentId,
      kitId,
    }
  );
}

export const fetchAgent = (organizationId: string, agentId: string) => async (dispatch: Dispatch) => {
  return perform<Agent>(
    dispatch,
    types.FETCH_AGENT_REQUEST,
    {
      method: HTTP_METHOD.GET,
      url: `/organizations/${organizationId}/agents/${agentId}`,
    },
    Agent.fromDTO
  );
}

export const deleteAgent = (organizationId: string, agentId: string) => async (dispatch: Dispatch) => {
  return perform<Agent>(
    dispatch,
    types.DELETE_AGENT_REQUEST,
    {
      method: HTTP_METHOD.DELETE,
      url: `/organizations/${organizationId}/agents/${agentId}`,
    },
    undefined,
    {
      id: agentId,
    }
  );
}
