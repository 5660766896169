import React, { useEffect } from 'react';
import { stratify, tree } from 'd3-hierarchy';
import ReactFlow, {
  Controls,
  Background,
  BackgroundVariant,
  Node,
  Edge,
  FitViewOptions,
  DefaultEdgeOptions,
  useNodesState,
  useEdgesState,
  useReactFlow,
} from 'reactflow';
import 'reactflow/dist/base.css';
import KitNode from './Nodes/KitNode';
import Panel from './Panels/Panel';
import AddNode from './Nodes/AddNode';
import FunctionNode from './Nodes/FunctionNode';
import { CanvasNode, createNode } from '~/lib/canvas';
import AgentNode from './Nodes/AgentNode';
import AppNode from './Nodes/AppNode';

const g = tree<Node>();

const fitViewOptions: FitViewOptions = {
  padding: 0.2,
  minZoom: 0.8
};

const defaultEdgeOptions: DefaultEdgeOptions = {
  animated: false,
};

type Props = {
  root: CanvasNode,
}

// These have to match the css .canvas-node
const NODE_HEIGHT = 190;
const NODE_WIDTH = 500;

const getLayoutedElements = (nodes: Node[], edges: Edge[]) => {
  if (nodes.length === 0) {
    return { nodes, edges };
  }

  const hierarchy = stratify<Node>()
    .id(node => node.id)
    .parentId(node => edges.find((edge) => edge.target === node.id)?.source);

  const root = hierarchy(nodes);
  const layout = g.nodeSize([NODE_WIDTH + 15, NODE_HEIGHT + 100]).separation(() => 1)(root);

  return {
    edges,
    nodes: layout
      .descendants()
      .map((node) => ({ ...node.data, position: { x: node.x, y: node.y } })),
  };
};

const createNodes = (objects: CanvasNode[], level: number = 1, x: number = 0): Node[] => {
  // With d3 hierarchy layouting, I can ignore the calculations
  const nodes: Node[] = [];

  const xStart = x - (NODE_WIDTH / 2 * (objects.length - 1));
  for (let i = 0; i < objects.length; i++) {
    const o = objects[i];
    const nodeStart = xStart + (NODE_WIDTH * i);
    nodes.push(createNode(o, nodeStart, NODE_HEIGHT * (level - 1)));
    if (o.nodes) {
      nodes.push(...createNodes(o.nodes, level + 1, nodeStart));
    }
  }
  return nodes;
}

const createEdges = (objects: CanvasNode[]): Edge[] => {
  const edges: Edge[] = [];
  for (let i = 0; i < objects.length; i++) {
    const o = objects[i];
    if (o.nodes) {
      for (let j = 0; j < o.nodes.length; j++) {
        edges.push({
          id: `${o.nodeId}-${o.nodes[j].nodeId}`,
          source: o.nodeId,
          target: o.nodes[j].nodeId,
          type: 'smoothstep',
        });
      }
      edges.push(...createEdges(o.nodes));
    }
  }
  return edges;
}

const nodeTypes = {
  kit: KitNode,
  'add-function': AddNode,
  'add-kit': AddNode,
  'function': FunctionNode,
  'agent': AgentNode,
  'add-agent': AddNode,
  'app': AppNode,
}

type CanvasNodeTreeProps = {
  root: CanvasNode,
  setNodes: (node: Node[]) => void,
  setEdges: (edge: Edge[]) => void,
}

const CanvasNodeTree: React.FC<CanvasNodeTreeProps> = ({ root, setNodes, setEdges }) => {
  const { fitView } = useReactFlow();

  useEffect(() => {
    const layouted = getLayoutedElements(createNodes([root]), createEdges([root]));

    setNodes(layouted.nodes);
    setEdges(layouted.edges);

    window.requestAnimationFrame(() => {
      fitView();
    });
  }, [root, fitView, setEdges, setNodes]);

  return null;
}

const Canvas: React.FC<Props> = ({ root }) => {

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  return (
    <ReactFlow
      className='relative'
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      fitView
      nodeTypes={nodeTypes}
      fitViewOptions={fitViewOptions}
      defaultEdgeOptions={defaultEdgeOptions}>
      <Controls />
      <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
      <CanvasNodeTree root={root} setNodes={setNodes} setEdges={setEdges} />
      <Panel />
    </ReactFlow>
  )
}

export default Canvas;
