import { Outlet, useNavigate, useParams } from "react-router-dom"
import styles from './layout.module.css';
import { useKits, useIsFetchingAll } from "~/store/kits/hooks";
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { Button, Input } from "@hubblai/hubbl-ui/components/index.js";
import KitListItem from "~/components/KitListItem/index.js";
import { useCurrentOrganizationId } from "~/store/organizations/hooks";
import { useEffect } from "react";

const SIDE_PANEL_SIZE = 30;

const KitsLayout: React.FC = () => {
  const navigate = useNavigate();
  const kits = useKits();
  const { kitId } = useParams();
  const isFetching = useIsFetchingAll();
  const organizationId = useCurrentOrganizationId();

  useEffect(() => {
    if (organizationId) {
      navigate('/kits' + (kitId ? `/${kitId}` : ''));
    }
  }, [navigate, organizationId, kitId]);

  const onClickNewKit = () => {
    navigate('/kits/new');
  }

  return (
    <Splitter className="flex flex-1 overflow-hidden">
      <SplitterPanel size={SIDE_PANEL_SIZE} minSize={10} className="flex flex-col flex-grow-1 overflow-hidden">
        <div className="flex flex-row border-b-2 p-2">
          <Input placeholder="Search kits..." className="flex-1" icon='search' />
          <Button icon='plus' onClick={onClickNewKit} className="ml-1" />
        </div>
        {!isFetching && kits.length > 0 && (
          <div className={styles.list}>
            {kits.map(kit => (
              <KitListItem key={kit.id} kit={kit} isActive={kitId === kit.id} />
            ))}
          </div>
        )}
      </SplitterPanel>
      <SplitterPanel size={100 - SIDE_PANEL_SIZE}>
        <Outlet />
      </SplitterPanel>
    </Splitter>
  )
}

export default KitsLayout;
