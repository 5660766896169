import React from 'react';
import { useAppDispatch } from "@hubblai/hubbl-ui/store/index.js";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useCurrentOrganizationId } from "~/store/organizations/hooks";
import { useAgentAsNode } from "~/store/agents/hooks";
import Canvas from '~/components/Canvas/Canvas';
import { fetchAgent } from '~/store/agents/actions';

const AgentEditPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const agentId = params.agentId!;
  const organizationId = useCurrentOrganizationId();
  const agent = useAgentAsNode(agentId, organizationId!);

  useEffect(() => {
    if (organizationId) {
      dispatch(fetchAgent(organizationId, agentId));
    }
  }, [organizationId, dispatch, agentId])

  return (
    <div className="flex flex-col flex-1 w-full h-full">
      {agent && <Canvas root={agent} />}
    </div>
  )
}

export default AgentEditPage;
