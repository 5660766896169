import React from 'react';
import { useAppDispatch } from "@hubblai/hubbl-ui/store/index.js";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useKitAsNode } from "~/store/kits/hooks";
import { fetchKit } from "~/store/kits/actions";
import { useCurrentOrganizationId } from "~/store/organizations/hooks";
import Canvas from '~/components/Canvas/Canvas';
// import styles from './edit.module.css';

const KitEditPage: React.FC = () => {
  // const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();
  const kitId = params.kitId!;
  const organizationId = useCurrentOrganizationId();
  const kit = useKitAsNode(kitId, organizationId!);

  useEffect(() => {
    if (organizationId) {
      dispatch(fetchKit(organizationId, kitId));
    }
  }, [organizationId, dispatch, kitId])

  return (
    <div className="flex flex-col flex-1 w-full h-full">
      {kit && <Canvas root={kit} />}
    </div>
  )
}

export default KitEditPage;
