import * as types from './types';

export const setCurrentOrganization = (organizationId: string) => {
  return {
    type: types.SET_CURRENT_ORGANIZATION,
    payload: {
      id: organizationId,
    }
  }
}
