
import { ComponentProps } from '@hubblai/hubbl-ui/components/types.js';
import React from 'react';
import PanelHeader from '../Components/PanelHeader';
import { confirm } from '@hubblai/hubbl-ui/components/index.js';
import { useAppDispatch } from '@hubblai/hubbl-ui/store/index.js';
import { useCurrentOrganizationId } from '~/store/organizations/hooks';
import { addToast } from '@hubblai/hubbl-ui/store/notifications/actions.js';
import { getDisplayError } from '@hubblai/hubbl-ui/lib/api.js';
import PanelBody from '../Components/PanelBody';
import { useAgent } from '~/store/agents/hooks';
import { removeAgent } from '~/store/apps/actions';

type Props = {
  appId: string,
  agentId: string,
  selectNode: (nodeId?: string) => void,
} & ComponentProps;

const AgentConfigPanel: React.FC<Props> = ({ appId, agentId, selectNode }) => {
  const dispatch = useAppDispatch();
  const organizationId = useCurrentOrganizationId();
  const agent = useAgent(agentId);

  const onClickDeleteConfirmed = async () => {
    try {
      await dispatch(removeAgent(organizationId!, appId, agentId));
      selectNode();
      dispatch(addToast('Done!', 'Agent was removed from the app', 'success'));
    }
    catch (err) {
      dispatch(addToast('Oops!', `Something went wrong: ${getDisplayError(err)}`));
    }
  }

  const buttons = [{
    label: 'Remove',
    onClick: () => {
      confirm({
        message: <div>Are you sure you want to remove this agent from the app? </div>,
        header: 'Remove Agent',
        icon: 'pi pi-exclamation-triangle',
        accept: onClickDeleteConfirmed,
      })
    },
  }];

  return (
    <div className="flex flex-col flex-1 h-full w-full">
      {agent && <PanelHeader title={`${agent?.getDisplayName()}`} buttons={buttons} />}
      {agent && <PanelBody><div>{agent.description || 'No description'}</div></PanelBody>}
    </div>
  )
}

export default AgentConfigPanel;
