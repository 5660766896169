import { Button, Spinner } from "@hubblai/hubbl-ui/components/index.js";
import { getDisplayError } from "@hubblai/hubbl-ui/lib/api.js";
import { useAppDispatch } from "@hubblai/hubbl-ui/store/index.js";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createKit } from "~/store/kits/actions";
import { useCurrentOrganizationId } from "~/store/organizations/hooks";

const KitsNewPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentOrganizationId = useCurrentOrganizationId();
  const [errorMessage, setErrorMessage] = useState('');

  const createNewKit = useCallback(async () => {
    setErrorMessage('');
    if (currentOrganizationId) {
      try {
        const kit = await dispatch(createKit(currentOrganizationId, {
          title: 'New Kit',
          description: 'This kit does nothing',
        }));
        if (kit) {
          navigate(`/kits/${kit.id}`, { replace: true });
        }
      }
      catch (err) {
        setErrorMessage(getDisplayError(err));
      }
    }
  }, [currentOrganizationId, dispatch, navigate]);

  useEffect(() => {
    createNewKit()
  }, [createNewKit])

  return (
    <div className="flex flex-1 justify-center items-center">
      {errorMessage.length === 0 && (
        <div className="text-center">
          <div>Creating...</div>
          <Spinner />
        </div>
      )}
      {errorMessage.length > 0 &&
        <div>
          <div>There was an error creating a kit: {errorMessage}</div>
          <Button icon='retry' title="Retry" onClick={createNewKit} />
        </div>
      }
    </div>
  )
}

export default KitsNewPage;
