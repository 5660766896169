import { Argument, JS, TEMPLATE_NAME } from "@hubblai/hubbl-core/lib/code.js";
import { deepClone, getDiff, isEmpty, isNil } from "@hubblai/hubbl-core/lib/object.js";
import { FUNCTION_STATUS } from "@hubblai/hubbl-core/models/Function.js";
import { Button, Input, Label, Spinner, confirm } from "@hubblai/hubbl-ui/components/index.js";
import { ClassName, ComponentProps, FormErrors } from "@hubblai/hubbl-ui/components/types.js";
import { getDisplayError } from "@hubblai/hubbl-ui/lib/api.js";
import { useAppDispatch } from "@hubblai/hubbl-ui/store/index.js";
import { addToast } from "@hubblai/hubbl-ui/store/notifications/actions.js";
import { useEffect, useState } from "react";
import { createDraftFunction, deleteFunction, submitFunctionAction, updateFunction } from "~/store/functions/actions";
import { FunctionModel } from "~/store/models";
import ArgumentsList from "~/components/ArgumentsList";
import ArgumentModal from "~/components/ArgumentModal/ArgumentModal";
import FunctionTestModal from "~/components/FunctionTestModal";
import { useIsUpdating } from "~/store/functions/hooks";
import styles from './FunctionForm.module.css';
import clsx from "clsx";
import AdvancedInput, { Language, LanguageValue, SimpleCode, isSimpleCode } from "~/components/AdvancedInput";
import { isString } from "@hubblai/hubbl-core/lib/string.js";
import { Parameter, ParameterGroups } from "@hubblai/hubbl-core/lib/parameters.js";
import ConfigParametersList from "../ConfigParametersList";
import ParameterModal from "../ParameterModal";

type Props = {
  organizationId: string,
  fn: FunctionModel,
  onCreated: (functionId: string) => void,
  onDeleted: () => void,
  contentClassName?: ClassName,
} & ComponentProps;

type ParameterIndex = {
  group: number,
  index: number,
}

const DEFAULT_PARAMETER_GROUPS: ParameterGroups = [{
  parameters: [],
}];

const FunctionForm: React.FC<Props> = ({ fn, className, organizationId, onCreated, onDeleted, contentClassName }) => {

  const dispatch = useAppDispatch();
  const isUpdating = useIsUpdating();

  const [name, setName] = useState('');

  const [description, setDescription] = useState('');
  const [descriptionCode, setDescriptionCode] = useState<SimpleCode | undefined>();

  const [args, setArgs] = useState<Argument[]>([]);
  const [configParameters, setConfigParameters] = useState<ParameterGroups>([...DEFAULT_PARAMETER_GROUPS]);
  const [currentParameter, setCurrentParameter] = useState<Parameter | undefined>(undefined);
  const [currentParameterIndex, setCurrentParameterIndex] = useState<ParameterIndex>({ group: 0, index: -1 });

  const [code, setCode] = useState<SimpleCode | undefined>();

  const [errors, setErrors] = useState<FormErrors>({});

  const [currentArgument, setCurrentArgument] = useState<Argument | undefined>(undefined);
  const [currentArgumentIndex, setCurrentArgumentIndex] = useState(-1);
  const [isFunctionTestModalOpened, setIsFunctionTestModalOpened] = useState(false);

  useEffect(() => {
    if (fn) {
      setName(fn.name);
      setDescription(fn.description);
      setCode({
        content: fn.code?.content || JS.getTemplate(TEMPLATE_NAME.DEFAULT),
        dependencies: fn.code?.dependencies || {},
      });

      if (fn.description_code) {
        setDescriptionCode({
          content: fn.description_code.content,
          dependencies: fn.description_code.dependencies,
        });
      }
      if (fn.config_args) {
        setArgs(Object.values(fn.config_args));
      }
      if (fn.config_parameters && fn.config_parameters.length > 0) {
        setConfigParameters(fn.config_parameters);
      }
    }
  }, [fn]);

  const validate = () => {
    const errors: FormErrors = {};
    if (name.length === 0) {
      errors.name = "Name is required";
    }
    if ((descriptionCode && descriptionCode.content.length === 0) || description.length === 0) {
      errors.description = "Description is missing";
    }
    if (code?.content.length === 0) {
      errors.code = "Code is missing";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  }

  const getData = () => {
    return {
      name,
      description,
      code,
      description_code: descriptionCode || null,
      args,
      config_parameters: configParameters,
    }
  }

  const getChangedData = () => {
    return getDiff(fn!, getData());
  }

  const submit = async () => {
    if (validate()) {
      const data: any = getChangedData();
      try {
        if (!isEmpty(data)) {
          await dispatch(updateFunction(organizationId, fn.id, data));
        }
        return true;
      }
      catch (err) {
        dispatch(addToast('Oops!', `Something went wrong: ${getDisplayError(err)}`));
      }

    }
    return false;
  }

  const onClickSave = async () => {
    const result = await submit();
    if (result) {
      dispatch(addToast('Nice!', 'Your function was saved.', 'success'));
    }
  }

  const publish = async () => {
    const wasSubmitted = await submit();
    if (wasSubmitted) {
      if (isDraft) {
        try {
          await dispatch(submitFunctionAction(organizationId, fn!.id, 'publish'));
        }
        catch (err) {
          dispatch(addToast('Oops!', `Something went wrong during publishing: ${getDisplayError(err)}`));
          return;
        }
      }
      dispatch(addToast('And done!', 'Your function was saved and published. All associated workflows will now call this version.', 'success'));
    }
  }

  const onClickPublish = async () => {
    confirm({
      message: <div>
        Are you sure you want to publish? <br />
        After publish all workflows using this function will execute this new version. <br/>
        Before you publish make sure that your change is backwards compatible.
      </div>,
      header: isDraft ? 'Publish Draft' : 'Overwrite Latest version',
      icon: 'pi pi-exclamation-triangle',
      accept: publish,
    })
  }

  const onClickSaveAsDraft = async () => {
    if (validate()) {
      try {
        const draftFn = await dispatch(createDraftFunction(organizationId!, fn.id, getData()));
        dispatch(addToast('Nice!', `Your changes were saved!`, 'success'));
        if (draftFn) {
          onCreated(draftFn.id);
        }
      }
      catch (err) {
        const errorMessage = getDisplayError(err);
        dispatch(addToast('Oops!', `Something went wrong: ${errorMessage}`));
      }
    }
  }

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value);

  const onArgumentModalClosed = () => {
    setCurrentArgument(undefined);
    setCurrentArgumentIndex(-1);
  }

  const onClickArgument = (arg: Argument, index: number) => {
    setCurrentArgument({...arg});
    setCurrentArgumentIndex(index);
  }

  const onClickAddArgument = () => {
    setCurrentArgumentIndex(-1);
    setCurrentArgument({
      name: '',
      type: 'string',
      description: '',
      validations: [],
    });
  }

  const onArgumentSaved = () => {
    setArgs(args => {
      const newArgs = [...args];
      if (currentArgument) {
        if (currentArgumentIndex === -1) {
          newArgs.push(currentArgument);
        } else {
          newArgs[currentArgumentIndex] = {...currentArgument};
        }
      }
      return newArgs;
    });
    setCurrentArgument(undefined);
    setCurrentArgumentIndex(-1);
  }

  const onArgumentChanged = (key: string, value: any) => {
    setCurrentArgument(currentValue => {
      if (currentValue) {
        return {
          ...currentValue,
          [key]: value,
        }
      }
      return undefined;
    });
  }

  const onClickDeleteArgument = (index: number) => {
    confirm({
      message: <div>Are you sure you want to remove this argument?</div>,
      header: `Remove Argument: ${args[index].name}`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        setArgs(args => args.filter((_, i) => i !== index));
      },
    });
  }

  const onClickDeleteFunctionConfirmed = () => {
    dispatch(deleteFunction(organizationId!, fn!.id));
    onDeleted();
  }

  const onClickDeleteFunction = () => {
    confirm({
      message: <div>Are you sure you want to remove this function? This step is irreversible. All associated objects will have this function removed from their functionality.</div>,
      header: `Remove Function`,
      icon: 'pi pi-exclamation-triangle',
      accept: onClickDeleteFunctionConfirmed,
    });
  }

  const onCodeChanged = (language: Language, value: LanguageValue) => {
    if (language === 'js' && isSimpleCode(value)) {
      setCode({
        content: value.content,
        dependencies: value.dependencies,
      })
    }
  }

  const onDescriptionChanged = (language: Language, value: LanguageValue) => {
    if (language === 'plain' && isString(value)) {
      setDescription(value);
      setDescriptionCode(undefined);
    } else if (language === 'js' && isSimpleCode(value)) {
      setDescriptionCode({
        content: value.content,
        dependencies: value.dependencies,
      })
    }
  }

  const onClickTest = () => {
    setIsFunctionTestModalOpened(true);
  }

  const onFunctionTestModalClosed = () => {
    setIsFunctionTestModalOpened(false);
  }

  const onClickParameter = (parameter: Parameter, groupIndex: number, index: number) => {
    setCurrentParameter({...parameter});
    setCurrentParameterIndex({ group: groupIndex, index });
  }

  const onClickAddParameter = () => {
    setCurrentParameterIndex({ group: 0, index: -1 });
    setCurrentParameter({
      name: '',
      display_name: '',
      type: 'string',
      description: '',
      validations: [],
    });
  }

  const onParameterSaved = () => {
    setConfigParameters(groups => {
      const newGroups = deepClone(groups);
      const group = newGroups[currentParameterIndex.group];
      if (currentParameter) {
        if (currentParameterIndex.index === -1) {
          group.parameters.push(currentParameter);
        } else {
          group.parameters[currentParameterIndex.index] = {...currentParameter};
        }
      }
      return newGroups;
    });
    setCurrentParameter(undefined);
    setCurrentParameterIndex({ group: 0, index: -1 });
  }

  const onParameterChanged = (key: string, value: any) => {
    setCurrentParameter(currentValue => {
      if (currentValue) {
        return {
          ...currentValue,
          [key]: value,
        }
      }
      return undefined;
    });
  }

  const onClickDeleteParameter = (groupIndex: number, index: number) => {
    setConfigParameters(groups => {
      const newGroups = deepClone(groups) as ParameterGroups;
      const group = newGroups[groupIndex];
      group.parameters = group.parameters.filter((_, i) => i !== index)
      return newGroups;
    });
  }

  const onParameterModalClosed = () => {
    setCurrentParameter(undefined);
    setCurrentParameterIndex({ group: 0, index: -1 });
  }

  if (!fn) {
    return <Spinner />;
  }

  const isDraft = fn.status === FUNCTION_STATUS.DRAFT;
  const hasCodeChanged = fn.code?.content !== code?.content;
  const canDelete = isDraft;

  return (
    <div className={className}>
      <div className={clsx(styles.content, contentClassName)}>
        <div>
          <Input className="mb-3 flex-1" id="function_name" value={name} onChange={onChangeName} label="Function Name" error={errors.name} />
          <AdvancedInput
            id="description"
            label="Description"
            language={isNil(descriptionCode) ? 'plain' : 'js'}
            onChange={onDescriptionChanged}
            error={errors.description}
            defaultValues={{
              'js': JS.getTemplate(TEMPLATE_NAME.DESCRIPTION),
            }}
            values={{
              'plain': description,
              'js': descriptionCode,
            }}
          />

          <Label title="Parameters" buttons={[{ label: "Add Parameter", onClick: onClickAddParameter }]} />
          <ConfigParametersList groups={configParameters} onClick={onClickParameter} onClickDelete={onClickDeleteParameter} />
          <div className="mb-3 text-sm">Parameters are configured when this function is added to a Kit.</div>

          <Label title="Arguments" buttons={[{ label: "Add Argument", onClick: onClickAddArgument }]} />
          <ArgumentsList args={args} onClick={onClickArgument} onClickDelete={onClickDeleteArgument} />
          <div className="mb-3 text-sm">Arguments are provided by the Agent based on the information it was given by the user.</div>

          <AdvancedInput
            label="Code"
            error={errors.code}
            defaultValues={{
              'js': JS.getTemplate(TEMPLATE_NAME.DEFAULT),
            }}
            values={{
              'js': code,
            }}
            onChange={onCodeChanged}
          />
        </div>
      </div>
      {fn &&
        <div className="border-t-2 flex flex-row justify-between p-2">
          <div>
            <Button icon='test' title="Test" variant="success" onClick={onClickTest} className="mr-1" />
            {canDelete && <Button icon="remove" title='Delete' variant="danger" onClick={onClickDeleteFunction} isDisabled={fn.isReadOnly()} />}
          </div>
          <div>
            {isDraft && <Button icon="save" title='Save' onClick={onClickSave} isLoading={isUpdating} isDisabled={fn.isReadOnly()} />}
            {!isDraft && <Button icon="save" title='Save as Draft' onClick={onClickSaveAsDraft} isLoading={isUpdating} isDisabled={fn.isReadOnly()} />}
            <Button icon="save" title='Save & Publish' className="ml-1" onClick={onClickPublish} isLoading={isUpdating} isDisabled={fn.isReadOnly()} />
          </div>
        </div>
      }
      {currentArgument && <ArgumentModal arg={currentArgument} index={currentArgumentIndex} onClose={onArgumentModalClosed} onSave={onArgumentSaved} onChange={onArgumentChanged} />}
      {fn && <FunctionTestModal fn={fn} isOpened={isFunctionTestModalOpened} hasCodeChanged={hasCodeChanged} onClose={onFunctionTestModalClosed} args={args} />}
      {currentParameter && <ParameterModal parameter={currentParameter} index={currentParameterIndex.index} onClose={onParameterModalClosed} onSave={onParameterSaved} onChange={onParameterChanged} />}
    </div>
  )
}

export default FunctionForm;
