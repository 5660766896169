import { Parameter } from "@hubblai/hubbl-core/lib/parameters.js";
import { Button, Tag } from "@hubblai/hubbl-ui/components/index.js";

type Props = {
  parameter: Parameter,
  onClick: () => void,
  onClickDelete: () => void,
}

const ConfigParameterListItem: React.FC<Props> = ({ parameter, onClick, onClickDelete }) => {
  const isRequired = parameter.validations ? parameter.validations.findIndex(v => v.type === 'required') > -1 : false;

  return (
    <div className="bg-white rounded-sm px-3 py-2 border flex flex-row justify-between items-center">
      <div className="flex flex-row items-center flex-1">
        <div>
          <div>
            <Tag title={parameter.type} />
            <span className="ml-2">{parameter.display_name}</span>
            <code className="inline-code inline-block ml-2 my-1">{parameter.name}</code>
          </div>
          <div className="text-sm opacity-75">
            {parameter.description}
          </div>
          <div>
            {parameter.multiple && <span className="opacity-50 mr-2">[list]</span>}
            {parameter.secret && <span className="opacity-50 mr-2">[secret]</span>}
            {isRequired && <span className="opacity-50">[required]</span>}
          </div>
        </div>
      </div>
      <div>
        <Button tooltip="Edit" onClick={onClick} size="xs" className="mx-2" variant="info" icon='edit' />
        <Button tooltip="Delete" onClick={onClickDelete} size="xs" variant="danger" icon='remove' />
      </div>
    </div>
  )
}

export default ConfigParameterListItem;
