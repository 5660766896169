export const FETCH_MODELS_REQUEST = {
  START: "FETCH_MODELS_REQUEST.START",
  SUCCESS: "FETCH_MODELS_REQUEST.SUCCESS",
  FAILURE: "FETCH_MODELS_REQUEST.FAILURE",
}

export const FETCH_PROMPTS_REQUEST = {
  START: "FETCH_PROMPTS_REQUEST.START",
  SUCCESS: "FETCH_PROMPTS_REQUEST.SUCCESS",
  FAILURE: "FETCH_PROMPTS_REQUEST.FAILURE",
}
