import { Icon } from '@hubblai/hubbl-ui/components/index.js';
import clsx from 'clsx';
import React from 'react';
import { Handle, Position } from 'reactflow';

type Props = {
  data: {
    title: string,
  },
  selected: boolean,
};

const AddNode: React.FC<Props> = ({ data, selected }) => {
  return (
    <div className={clsx("canvas-node p-6 rounded-md border-2 border-gray-300 flex flex-1 items-center justify-center relative w-128 bg-opacity-5 bg-black", { "border-solid": selected, "border-dashed": !selected })}>
      <div className="font-semibold color-gray-300 text-lg">
        <Icon name="plus" className='mr-5' />
        {data.title}
      </div>
      <Handle type="target" position={Position.Top} className="!bg-gray-300 rounded-md" style={{ width: '16px', height: '16px', top: -8 }} />
    </div>
  )
}

export default AddNode;
