
import { Spinner } from '@hubblai/hubbl-ui/components/index.js';
import { ComponentProps } from '@hubblai/hubbl-ui/components/types.js';
import API, { getDisplayError } from '@hubblai/hubbl-ui/lib/api.js';
import { useAppDispatch } from '@hubblai/hubbl-ui/store/index.js';
import { addToast } from '@hubblai/hubbl-ui/store/notifications/actions.js';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Search from '~/components/Search';
import { Agent } from '~/store/models';
import { TabView, TabPanel } from 'primereact/tabview';
import { useCurrentOrganizationId } from '~/store/organizations/hooks';
import { useOrganization } from '@hubblai/hubbl-ui/store/auth/hooks.js';
// import { useIsUpdating } from '~/store/kits/hooks';
import PanelHeader from '../Components/PanelHeader';
import PanelBody from '../Components/PanelBody';
import { useAgents } from '~/store/agents/hooks';
import AgentListItem from '~/components/AgentListItem';
import { addAgent } from '~/store/apps/actions';

type Props = {
  appId: string,
  selectNode: (nodeId?: string) => void,
} & ComponentProps;

const AddAgentPanel: React.FC<Props> = ({ appId, selectNode }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [agents, setAgents] = useState<Agent[]>([]);
  const orgAgents = useAgents();
  const organizationId = useCurrentOrganizationId();
  const organization = useOrganization(organizationId || '');
  // const _isUpdating = useIsUpdating();

  // TODO:P1 make search work again

  const fetchAgents = useCallback(async () => {
    setIsFetching(true);
    try {
      const { data } = await API.get(`/agents`, { query });
      const agents = Agent.fromDTOs(data);
      setAgents(agents);
    }
    catch (err) {
      const errorMessage = getDisplayError(err);
      dispatch(addToast('Ooops', `Seems like there was a problem fetching functions: ${errorMessage}`));
    }
    setIsFetching(false);
  }, [query, dispatch]);

  useEffect(() => {
    fetchAgents();
  }, [fetchAgents]);

  const onClickAgent = async (agent: Agent) => {
    try {
      await dispatch(addAgent(organizationId!, appId, agent.id));
      // selectNode(getAgentNodeId(agent.id));
      selectNode(undefined);
      dispatch(addToast('Done!', 'Agent was added to the App', 'success'));
    }
    catch (err) {
      dispatch(addToast('Ooops', `Seems like there was a problem adding function: ${getDisplayError(err)}`));
    }
  }

  const onSearchChanged = (query: string) => setQuery(query);

  const matchingOrgAgents = query.length > 0 ? orgAgents.filter(agent => agent.display_name.includes(query) ) : orgAgents;

  const buttons = [{
    label: 'Create New Agent',
    onClick: () => navigate('/agents/new'),
  }]

  return (
    <div className="flex flex-col flex-1 h-full w-full">
      <PanelHeader title='Add Agent' buttons={buttons}>
        <Search placeholder='Search for agents...' onChange={onSearchChanged} />
      </PanelHeader>
      <PanelBody>
        <TabView panelContainerClassName='p-0'>
          <TabPanel header={organization?.display_name}>
            {matchingOrgAgents.map(agent => <AgentListItem key={`org-${agent.id}`} agent={agent} onClick={() => onClickAgent(agent)} showStatus={false} />)}
            {matchingOrgAgents.length === 0 && query.length === 0 && <div>Your organization does not have any agents.</div>}
            {matchingOrgAgents.length === 0 && query.length > 0 && <div>No agents were found matching "{query}".</div>}
          </TabPanel>
          <TabPanel header="Public">
            {agents.map(agent => <AgentListItem key={agent.id} agent={agent} onClick={() => onClickAgent(agent)} showStatus={false} showOrganization />)}
            {isFetching && <Spinner />}
            {!isFetching && query.length > 0 && agents.length === 0 && <div className='flex-1 justify-center items-center'>No agents were found matching "{query}".</div>}
          </TabPanel>
        </TabView>

      </PanelBody>
    </div>
  )
}

export default AddAgentPanel;
