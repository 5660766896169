
import { ComponentProps, FormErrors } from '@hubblai/hubbl-ui/components/types.js';
import React, { useEffect, useRef, useState } from 'react';
import PanelHeader from '../Components/PanelHeader';
import { Button, Label, confirm } from '@hubblai/hubbl-ui/components/index.js';
import { useAppDispatch } from '@hubblai/hubbl-ui/store/index.js';
import { removeKit } from '~/store/agents/actions';
import { useCurrentOrganizationId } from '~/store/organizations/hooks';
import { addToast } from '@hubblai/hubbl-ui/store/notifications/actions.js';
import { getDisplayError } from '@hubblai/hubbl-ui/lib/api.js';
import PanelBody from '../Components/PanelBody';
import { useIsUpdating, useKit } from '~/store/kits/hooks';
import PanelFooter from '../Components/PanelFooter';
import { isEmpty } from '@hubblai/hubbl-core/lib/object.js';
import ParametersForm, { ParametersFormRef } from '~/components/ParametersForm';
import { ArgumentValues } from '@hubblai/hubbl-core/lib/code.js';
import { updateKit } from '~/store/agents/actions';
import { useAgent } from '~/store/agents/hooks';

type Props = {
  agentId: string,
  kitId: string,
  selectNode: (nodeId?: string) => void,
} & ComponentProps;

const KitConfigPanel: React.FC<Props> = ({ agentId, kitId, selectNode }) => {
  const parametersFormRef = useRef<ParametersFormRef | null>(null);
  const [initialParametersValues, setInitialParametersValues] = useState<ArgumentValues>({});
  const [_errors, setErrors] = useState<FormErrors>({});
  const dispatch = useAppDispatch();
  const organizationId = useCurrentOrganizationId();
  const kit = useKit(kitId);
  const agent = useAgent(agentId);
  const isUpdating = useIsUpdating();

  const agentKit = agent?.kits?.find(kit => kit.id === kitId);
  const isConfigurable = agent && agent.organization_id === organizationId;

  useEffect(() => {
    if (agentKit) {
      if (agentKit.parameters) {
        setInitialParametersValues(agentKit.parameters);
      }
    }
  }, [agentKit]);

  const onClickDeleteConfirmed = async () => {
    try {
      await dispatch(removeKit(organizationId!, agentId, kitId));
      selectNode();
      dispatch(addToast('Done!', 'Kit was removed from the agent', 'success'));
    }
    catch (err) {
      dispatch(addToast('Oops!', `Something went wrong: ${getDisplayError(err)}`));
    }
  }

  const buttons = [{
    label: 'Remove',
    onClick: () => {
      confirm({
        message: <div>Are you sure you want to remove this kit from the agent? </div>,
        header: 'Remove Kit',
        icon: 'pi pi-exclamation-triangle',
        accept: onClickDeleteConfirmed,
      })
    },
  }];

  const validate = () => {
    const errors = parametersFormRef.current?.validate() || {};
    setErrors(errors);
    return Object.keys(errors).length === 0;
  }

  const onClickSave = async () => {
    if (validate()) {
      const data: any = {
        parameters: parametersFormRef?.current?.getData(),
      }
      try {
        if (!isEmpty(data)) {
          await dispatch(updateKit(organizationId!, agentId, kitId, data));
        }
        dispatch(addToast('Nice!', `Your changes were saved!`, 'success'));
      }
      catch (err) {
        dispatch(addToast('Oops!', `Something went wrong: ${getDisplayError(err)}`));
      }
    }
  }

  const hasParameters = kit && kit.config_parameters.length > 0 && isConfigurable;

  return (
    <div className="flex flex-col flex-1 h-full w-full">
      {kit &&
        <>
          <PanelHeader title={`${kit?.title}`} buttons={buttons} />
          <PanelBody>
            <div className='mb-3'>{kit.description}</div>
            {hasParameters &&
              <div className='border-t-2 pt-3'>
                <Label title="Parameters" size="lg" />
                <ParametersForm ref={parametersFormRef} groups={kit.config_parameters} values={initialParametersValues} />
              </div>
            }
          </PanelBody>
          {hasParameters &&
            <PanelFooter>
              <div></div>
              <Button variant="success" title="Save Changes" size="sm" icon="save" onClick={onClickSave} isLoading={isUpdating} />
            </PanelFooter>
          }
        </>
      }
    </div>
  )
}

export default KitConfigPanel;
