import { ComponentProps } from '@hubblai/hubbl-ui/components/types.js';
import React from 'react';
import clsx from 'clsx';

type PanelBodyProps = ComponentProps;

const PanelBody: React.FC<PanelBodyProps> = ({ children, className }) => {
  return (
    <div className={clsx("overflow-auto flex flex-1 flex-col", className)}>
      {children}
    </div>
  )
}

export default PanelBody;
