import React, { useEffect, useState } from 'react';
import { Decoration, EditorView, useCodeMirror } from '@uiw/react-codemirror';
import { RangeSetBuilder} from "@codemirror/state"


import { vscodeDark } from '@uiw/codemirror-theme-vscode';
import { javascript } from '@codemirror/lang-javascript';
import { isEmpty } from '@hubblai/hubbl-core/lib/object.js';
import { Dependencies } from '@hubblai/hubbl-core/lib/code.js';
import { Label, Message } from '@hubblai/hubbl-ui/components/index.js';
import { parseModule } from 'esprima';
import styles from './Code.module.css';

type DependencyListProps = {
  dependencies: Dependencies,
}

const DependencyList: React.FC<DependencyListProps> = ({ dependencies }) => {
  if (isEmpty(dependencies)) {
    return <></>
  }
  return (
    <div className="mb-3">
      <Label title="Dependencies" />
      {Object.entries(dependencies).map(([name, version]) => (
        <div key={name}>
          {name}: {version}
        </div>
      ))}
    </div>
  );
}

type Props = {
  label?: string,
  value: string,
  dependencies: Dependencies,
  error?: string,
  onChange: (value: string) => void,
}

type CodeError = {
  message: string,
  line: number,
  column: number,
}

const underlineDeco = Decoration.mark({ class: 'underline !decoration-red-500 !decoration-wavy' });

const Code: React.FC<Props> = ({ label, value, onChange, dependencies }) => {
  const [error, setError] = useState<CodeError | undefined>();

  const validateJSCode = (code: string) => {
    try {
      parseModule(code);
      setError(undefined);
    } catch (error: any) {
      setError({
        message: error.message,
        line: error.lineNumber,
        column: error.column
      });
    }
  }

  const { setContainer, view } = useCodeMirror({
    lang: 'js',
    basicSetup: {
      foldGutter: false,
    },
    theme: vscodeDark,
    value: value,
    extensions: [
      javascript(),
      EditorView.decorations.compute([], (state: any) => {
        const builder = new RangeSetBuilder();
        if (error) {
          const line = state.doc.line(error.line);
          const pos = line.from + error.column - 1;
          const length = Math.max(line.text.substring(pos).length - 1, 1);
          builder.add(pos, pos + length, underlineDeco);
        }
        return builder.finish() as any;
      }),
    ],
    onChange: (value) => {
      validateJSCode(value);
      onChange(value);
    },
  });

  useEffect(() => {
    if (view) {
      view.dispatch();
    }
  }, [view, error]);

  return (
    <div className="mb-3">
      <Label title={label} />
      <div className={styles.editorPanel}>
        <div className={styles.editor} ref={setContainer} />
      </div>
      {error && <Message text={`${error.line}:${error.column} ${error.message}`} severity='error' />}
      <DependencyList dependencies={dependencies} />
  </div>
  )
}

export default Code;
