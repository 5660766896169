import { Button, ButtonItem, Icon, Input, Message, Modal } from "@hubblai/hubbl-ui/components/index.js";
import { ComponentProps, FormErrors } from "@hubblai/hubbl-ui/components/types.js";
import API, { getDisplayError } from "@hubblai/hubbl-ui/lib/api.js";
import clsx from "clsx";
import { TabPanel, TabView } from "primereact/tabview";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from 'react-dropzone';
import config from "~/config";

type Props = {
  isOpened: boolean,
  onClose: () => void,
  onChange: (file?: File, imageUrl?: string) => void,
} & ComponentProps;

type PromptImage = {
  url: string,
  prompt: string,
}

const ImageChangeModal: React.FC<Props> = ({ isOpened, onClose, onChange }) => {
  const agentId = config.AGENTS.AVATAR_GENERATE_AGENT_ID;

  const [prompt, setPrompt] = useState('');
  const [errors, setErrors] = useState<FormErrors>({});
  const [imageIndex, setImageIndex] = useState(-1);
  const [filePreview, setFilePreview] = useState('');
  const [promptImages, setPromptImages] = useState<PromptImage[]>([]);

  const [errorMessage, setErrorMessage] = useState('');
  const [file, setFile] = useState<File | undefined>();
  const [isGenerating, setIsGenerating] = useState(false);

  const onClickChange = () => {
    onChange(file, promptImages ? promptImages[imageIndex]?.url : undefined);
    onClose();
  }

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const selectedFile = acceptedFiles[0];
    if (selectedFile.size > config.MAX_IMAGE_SIZE) {
      setErrorMessage(`Image is too large. Maximum image size is ${config.MAX_IMAGE_SIZE / 1000}kB.`);
    } else {
      setErrorMessage('');
      setFile(selectedFile);
    }
  }, []);

  const onClickCancelFile = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setFile(undefined);
  }

  useEffect(() => {
    if (file) {
      setFilePreview(URL.createObjectURL(file));
      setImageIndex(-1);
    } else {
      setFilePreview('');
    }
  }, [file]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'image/*': [] }
  });

  const onClickGenerateImage = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (isGenerating) {
      return;
    }
    setErrorMessage('');
    if (validate()) {
      setIsGenerating(true);
      try {
        const result = await API.quickinvoke(agentId, prompt);
        if (result?.reply?.user?.files?.length) {
          const url = result.reply?.user?.files[0].url;
          setPromptImages(oldPromptImages => [{
            url,
            prompt,
          }].concat(...oldPromptImages));
          setImageIndex(0);
        } else if (result?.reply?.user?.content) {
          setErrorMessage(result.reply?.user?.content);
        } else {
          setErrorMessage('No images were generated, please, try again');
        }
      }
      catch (err) {
        setErrorMessage(getDisplayError(err));
      }
      setIsGenerating(false);
    }
  }

  const validate = () => {
    const errors: any = {};
    if (prompt.length === 0) {
      errors.prompt = 'Prompt required';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  }

  const onPromptChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrompt(e.target.value);
  }

  const onClickPromptImage = (index: number) => {
    setImageIndex(index);
  }

  const buttons: ButtonItem[] = [{
    title: "Close",
    action: 'close',
    variant: 'info',
    position: 'left',
    outline: true,
  }, {
    isDisabled: file === undefined && imageIndex === -1,
    title: "Upload Image",
    variant: 'success',
    onClick: onClickChange,
  }];

  return (
    <Modal isOpened={isOpened} usesNavigation={false} onClose={onClose} size="mid" title={"Change Image"} buttons={buttons}>
      <TabView panelContainerClassName="px-0">
        <TabPanel header="Upload Image">
          <div className="border-2 rounded-lg border-dashed flex flex-col items-center justify-center h-80" {...getRootProps()}>
            <input {...getInputProps()} />
            {!file && (
              <>
                <div className='py-3'>
                  <Icon name="image" className="" />
                </div>
                <p>Drag 'n' drop some image here, or click to select one</p>
              </>
            )}
            {file && filePreview.length > 0 && (
              <>
                <div className="">
                  <img src={filePreview} className="w-40" />
                </div>
                <div className="flex flex-row items-center">
                  <p>{file.name}</p>
                  <Button className="ml-3" variant='danger' outline icon="cancel" onClick={onClickCancelFile} />
                </div>
              </>
            )}

          </div>
        </TabPanel>
        <TabPanel header="Generate Image">
          <form>
            <div className="flex flex-row items-start">
              <Input className='flex-1' placeholder="Describe the Image to generate" value={prompt} onChange={onPromptChanged} error={errors.prompt} />
              <Button icon="generate" type="submit" className='ml-3' title="Generate" onClick={onClickGenerateImage} isLoading={isGenerating} />
            </div>
          </form>
          {promptImages.length > 0 &&
            <div className="mt-3 flex flex-row flex-wrap">
              {promptImages.map((img, index) => (
                <div onClick={() => onClickPromptImage(index)} key={img.url} className={clsx("rounded-md border-2 mx-0.5 my-0.5 cursor-pointer", { "border-success": imageIndex === index})}>
                  <img src={img.url} className="rounded w-40" alt={img.prompt} />
                </div>
              ))}
            </div>
          }
        </TabPanel>
      </TabView>
      {errorMessage && <Message className='mb-3 w-full' severity="error" text={errorMessage} />}
    </Modal>
  )
}

export default ImageChangeModal;
