import update from 'immutability-helper';
import { RESET, Action } from '@hubblai/hubbl-ui/store/types.js';
import * as orgTypes from '../organizations/types';
import * as types from './types';
import { App } from '../models';
import { toMap } from '~/lib/object';

export type AppsState = {
  isCreating: boolean,
  isUpdating: boolean,
  isFetchingAll: boolean,
  isFetchingOne: boolean,
  appMap: {
    [id: string]: App,
  },
  appIds: string[],
}

const INITIAL_STATE: AppsState = {
  isCreating: false,
  isUpdating: false,
  isFetchingAll: false,
  isFetchingOne: false,
  appMap: {},
  appIds: [],
}

const reducer = (state = { ...INITIAL_STATE }, { type, payload }: Action) => {
  switch (type) {

    case types.FETCH_APPS_REQUEST.START: {
      return update(state, {
        isFetchingAll: {$set: true},
      });
    }
    case types.FETCH_APPS_REQUEST.SUCCESS: {
      const { data } = payload;
      const agentIds = data.map((item: any) => item.id);
      const agentMap = toMap(data);
      return update(state, {
        isFetchingAll: {$set: false},
        appMap: {$merge: agentMap},
        appIds: {$set: agentIds},
      });
    }
    case types.FETCH_APPS_REQUEST.FAILURE: {
      return update(state, {
        isFetchingAll: {$set: false},
      });
    }

    case types.FETCH_APP_REQUEST.START: {
      return update(state, {
        isFetchingOne: {$set: true},
      });
    }
    case types.FETCH_APP_REQUEST.SUCCESS: {
      const { data } = payload;
      return update(state, {
        isFetchingOne: {$set: false},
        appMap: {$merge: {
          [data.id]: data,
        }}
      });
    }
    case types.FETCH_APP_REQUEST.FAILURE: {
      return update(state, {
        isFetchingOne: {$set: false},
      });
    }

    case types.CREATE_APP_REQUEST.START: {
      return update(state, {
        isCreating: {$set: true},
      });
    }
    case types.CREATE_APP_REQUEST.SUCCESS: {
      const { data } = payload;
      return update(state, {
        isCreating: {$set: false},
        appIds: {$unshift: [data.id]},
        appMap: {$merge: {
          [data.id]: data,
        }}
      });
    }
    case types.CREATE_APP_REQUEST.FAILURE: {
      return update(state, {
        isCreating: {$set: false},
      });
    }

    case types.APP_REMOVE_AGENT_REQUEST.START:
    case types.APP_ADD_AGENT_REQUEST.START:
    case types.UPDATE_APP_REQUEST.START: {
      return update(state, {
        isUpdating: {$set: true}
      });
    }

    case types.UPDATE_APP_REQUEST.SUCCESS: {
      const { data } = payload;
      return update(state, {
        isUpdating: {$set: false},
        appMap: {$merge: {
          [data.id]: data,
        }}
      });
    }
    case types.APP_ADD_AGENT_REQUEST.FAILURE:
    case types.APP_REMOVE_AGENT_REQUEST.FAILURE:
    case types.UPDATE_APP_REQUEST.FAILURE: {
      return update(state, {
        isUpdating: {$set: false}
      });
    }

    case types.APP_REMOVE_AGENT_REQUEST.SUCCESS: {
      const { appId, agentId } = payload;
      const app = state.appMap[appId];
      if (app && app.agents) {
        const agentIndex = app.agents.findIndex(agent => agent.id === agentId);
        if (agentIndex > -1) {
          const clonedApp = app.clone();
          clonedApp.agents!.splice(agentIndex, 1);
          return update(state, {
            appMap: {
              [appId]: {$set: clonedApp},
            }
          });
        }
      }
      return state;
    }

    case types.APP_ADD_AGENT_REQUEST.SUCCESS: {
      const { data, appId } = payload;
      const app = state.appMap[appId];
      if (app) {
        const clonedApp = app.clone();
        if (clonedApp.agents) {
          clonedApp.agents.push(data);
        } else {
          clonedApp.agents = [data];
        }
        return update(state, {
          appMap: {
            [appId]: {$set: clonedApp},
          }
        });
      }
      return state;
    }

    case types.DELETE_APP_REQUEST.START: {
      const { id } = payload;
      const existingIndex = state.appIds.findIndex(aId => aId === id);
      if (existingIndex > -1) {
        return update(state, {
          appIds: { $splice: [[existingIndex, 1]] },
        });
      }
      return state;
    }

    case orgTypes.SET_CURRENT_ORGANIZATION:
    case RESET:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};


export default reducer;
