import { JS, TEMPLATE_NAME } from "@hubblai/hubbl-core/lib/code.js";
import { Button, Spinner } from "@hubblai/hubbl-ui/components/index.js";
import { getDisplayError } from "@hubblai/hubbl-ui/lib/api.js";
import { useAppDispatch } from "@hubblai/hubbl-ui/store/index.js";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createFunction } from "~/store/functions/actions";
import { useCurrentOrganizationId } from "~/store/organizations/hooks";

const FunctionsNewPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentOrganizationId = useCurrentOrganizationId();
  const [errorMessage, setErrorMessage] = useState('');

  const createNewFunction = useCallback(async () => {
    setErrorMessage('');
    if (currentOrganizationId) {
      try {
        const fn = await dispatch(createFunction(currentOrganizationId, {
          name: 'New Function',
          description: 'This function does nothing',
          code: {
            content: JS.getTemplate(TEMPLATE_NAME.DEFAULT),
            dependencies: {},
          },
        }));
        if (fn) {
          navigate(`/functions/${fn.id}`, { replace: true });
        }
      }
      catch (err) {
        setErrorMessage(getDisplayError(err));
      }
    }
  }, [currentOrganizationId, dispatch, navigate]);

  useEffect(() => {
    createNewFunction()
  }, [createNewFunction])

  return (
    <div className="flex flex-1 justify-center items-center">
      {errorMessage.length === 0 && (
        <div className="text-center">
          <div>Creating...</div>
          <Spinner />
        </div>
      )}
      {errorMessage.length > 0 &&
        <div>
          <div>There was an error creating a message: {errorMessage}</div>
          <Button icon='retry' title="Retry" onClick={createNewFunction} />
        </div>
      }
    </div>
  )
}

export default FunctionsNewPage;
