import styles from './App.module.css';
import { useToasts } from '@hubblai/hubbl-ui/store/notifications/hooks.js';
import { Toaster, Confirm } from '@hubblai/hubbl-ui/components/index.js';
import { Outlet } from 'react-router-dom';
import TopBar from './components/TopBar';
import { useCurrentOrganizationId } from './store/organizations/hooks';
import { useEffect } from 'react';
import { useAppDispatch } from '@hubblai/hubbl-ui/store/index.js';
import { fetchFunctions } from './store/functions/actions';
import { fetchKits } from './store/kits/actions';
import { fetchAgents } from './store/agents/actions';
import { fetchModels } from './store/models/actions';
import { fetchApps } from './store/apps/actions';

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const toasts = useToasts();
  const currentOrganizationId = useCurrentOrganizationId();

  useEffect(() => {
    if (currentOrganizationId) {
      dispatch(fetchFunctions(currentOrganizationId));
      dispatch(fetchKits(currentOrganizationId));
      dispatch(fetchAgents(currentOrganizationId));
      dispatch(fetchModels());
      dispatch(fetchApps(currentOrganizationId));
    }
  }, [currentOrganizationId, dispatch]);

  return (
    <div className={styles.App}>
      <TopBar />
      <Outlet />
      <Toaster toasts={toasts} />
      <Confirm />
    </div>
  );
}

export default App;
